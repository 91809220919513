const pricingResponse = {
    status: true,
    data: {
        origen: {
            regionCodigo: 13,
            regionNombre: "Metropolitana de Santiago",
            comunaCodigo: 13101,
            comunaNombre: "SANTIAGO",
            localidadCodigo: "SCL",
            localidadNombre: "SANTIAGO"
        },
        destino: {
            regionCodigo: 13,
            regionNombre: "Metropolitana de Santiago",
            comunaCodigo: 13101,
            comunaNombre: "SANTIAGO",
            localidadCodigo: "SCL",
            localidadNombre: "SANTIAGO"
        },
        tarifa: 0.0,
        flete: 0.0,
        servicioAdicional: 0.0,
        fechaEstimadaEntrega: "16-03-2021",
        total: 0.0
    }
};
export default pricingResponse;