const emitResponse ={
    status: true,
    data: {
        trackingNumber: {
            type: "BigInteger",
            description: "Number of tracking shipment."
        },
        labels: [
            {
                contenido: {
                    type: "String",
                    description: "Content of label."
                }
            }
        ]
    }
};
export default emitResponse;