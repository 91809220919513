import React from 'react';
import './Header.css';

const Header = () => (
  <div className="header container-fluid" data-testid="Header">
    <div className="row">
      <div className="col-12 d-flex justify-content-start align-items-center">
        <div className="avatar d-flex justify-content-center align-items-center">BX</div>
        <div className="header-title">API Rest Doc</div>
      </div>
    </div>
  </div>
);


export default Header;
