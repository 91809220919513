import React from 'react';
import PropTypes from 'prop-types';
import styles from './Bx-geo.module.css';
import baseResponse from './../../jsons/baseResponse';
import geoResponse from './../../jsons/geoResponse';
import geoStateResponse from './../../jsons/geoStateResponse';
import BxHeaders from './../BxHeaders/BxHeaders';
import exceptionSchema from '../../jsons/exceptionSchema';
import zoneResponse from './../../jsons/zoneResponse';

const BxGeo = () => (
  <div className="process-detail-bx-geo">
    <div className="process-detail-description">API Bluex Geolocation allows you to get all countries, states, cities and districts used by Bluex Platform.</div>
    <BxHeaders />
    <div className="documentation-container">
      <div className="process-detail-subtitle">BX-Geolocation</div>
      <div className="snippet">
        <div className="process-detail-description">Allows you to get a list of all countries, states, cities and districts.</div>
        <div className="endpoint-url">GET - https://bx-tracking.bluex.cl/bx-geo/state/all</div>
        <div className="process-detail-subtitle">Response</div>
        <div><pre className="json">{JSON.stringify(geoResponse, null, 2)}</pre></div>
        <div className="process-detail-description">Here you can find the exception schema especification.</div>
        <div><pre className="json">{JSON.stringify(exceptionSchema, null, 2)}</pre></div>
      </div>
      <div className="process-detail-subtitle">Cities and districts.</div>
      <div className="snippet">
        <div className="process-detail-description">Allows you to get a list of all cities and districts filtered by state.</div>
        <div className="endpoint-url">GET -https://bx-tracking.bluex.cl/bx-geo/state/cl/8</div>
        <div className="process-detail-subtitle">Response</div>
        <div><pre className="json">{JSON.stringify(geoStateResponse, null, 2)}</pre></div>
        <div className="process-detail-description">Here you can find the exception schema especification.</div>
        <div><pre className="json">{JSON.stringify(exceptionSchema, null, 2)}</pre></div>
      </div>
      <div className="process-detail-subtitle">Distribution Bases.</div>
      <div className="snippet">
        <div className="process-detail-description">Allows you to get a list of all bluex distribution bases.</div>
        <div className="endpoint-url">GET -https://bx-tracking.bluex.cl/bx-geo/base/all</div>
        <div className="process-detail-subtitle">Response</div>
        <div><pre className="json">{JSON.stringify(baseResponse, null, 2)}</pre></div>
        <div className="process-detail-description">Here you can find the exception schema especification.</div>
        <div><pre className="json">{JSON.stringify(exceptionSchema, null, 2)}</pre></div>
      </div>
      <div className="process-detail-subtitle">Bluex Zones.</div>
      <div className="snippet">
        <div className="process-detail-description">Allows you to get a list of all bluex distribution zones.</div>
        <div className="endpoint-url">GET -https://bx-tracking.bluex.cl/bx-geo/zone/all</div>
        <div className="process-detail-subtitle">Response</div>
        <div><pre className="json">{JSON.stringify(zoneResponse, null, 2)}</pre></div>
        <div className="process-detail-description">Here you can find the exception schema especification.</div>
        <div><pre className="json">{JSON.stringify(exceptionSchema, null, 2)}</pre></div>
      </div>
    </div>
  </div>

);

BxGeo.propTypes = { };

BxGeo.defaultProps = { };

export default BxGeo;
