import React from 'react';
import PropTypes from 'prop-types';
import styles from './BxLabel.module.css';
import BxHeaders from './../BxHeaders/BxHeaders';
import exceptionSchema from '../../jsons/exceptionSchema';
import exceptionSample from '../../jsons/exceptionSample';
import labelSchemaResponse from '../../jsons/labelSchemaResponse';
import labelResponse from '../../jsons/labelResponse';

const BxLabel = () => (
  <div className="process-detail-bx-label">
    <div className="process-detail-description">API Bluex Label allows you to get the label in PDF base64 format.</div>
    <BxHeaders />
    <div className="documentation-container">
      <div className="process-detail-subtitle">BX-Label</div>
      <div className="snippet">
        <div className="process-detail-description">Allows you to get the label of a OS in PDF base64.</div>
        <div className="endpoint-url">GET - https://bx-tracking.bluex.cl/bx-label/v1/<em><strong>trackingNumber</strong></em></div>
        <div className="process-detail-description"><em><strong>trackingNumber</strong></em> is the OS number to get from Bluex platform.</div>
        <div className="process-detail-subtitle">Schema Response</div>
        <div className="process-detail-description">Here you can find the response schema especification.</div>
        <div><pre className="json">{JSON.stringify(labelSchemaResponse, null, 2)}</pre></div>
        <div className="process-detail-subtitle">Schema Exception</div>
        <div className="process-detail-description">Here you can find the exception schema especification.</div>
        <div><pre className="json">{JSON.stringify(exceptionSchema, null, 2)}</pre></div>
        <div className="process-detail-subtitle">Samples</div>
        <div className="process-detail-description">Here is a request sample.</div>
        <div className="endpoint-url">POST - https://bx-tracking.bluex.cl/bx-label/v1/6863902706</div>
        <div className="process-detail-description">Here is a response sample.</div>
        <div><pre className="json">{JSON.stringify(labelResponse, null, 2)}</pre></div>
        <div className="process-detail-description">Here is a exception sample.</div>
        <div><pre className="json">{JSON.stringify(exceptionSample, null, 2)}</pre></div>
      </div>
    </div>
  </div>

);

BxLabel.propTypes = {};

BxLabel.defaultProps = {};

export default BxLabel;
