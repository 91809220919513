const geoStateResponse = {
    status: true,
    data: {
        id: "CL",
        name: "CHILE",
        states: [
            {
                name: "Bío - Bío",
                code: 8,
                cities: [
                    {
                        name: "ALTO BIOBIO",
                        code: 8314,
                        districts: [
                            {
                                name: "ALTO BIO BIO",
                                code: "AOO"
                            },
                            {
                                name: "PANGUE",
                                code: "ZLL"
                            },
                            {
                                name: "RALCO",
                                code: "RCO"
                            },
                            {
                                name: "RALCO LEPOY",
                                code: "ZLN"
                            },
                            {
                                name: "TERMAS DEL AVELLANO",
                                code: "ZTV"
                            },
                            {
                                name: "COMUNIDAD CANICU",
                                code: "ZRI"
                            },
                            {
                                name: "CASA LOLCO",
                                code: "ZOT"
                            }
                        ]
                    },
                    {
                        name: "ANTUCO",
                        code: 8302,
                        districts: [
                            {
                                name: "ANTUCO",
                                code: "ANT"
                            },
                            {
                                name: "LOS BARROS",
                                code: "LLB"
                            },
                            {
                                name: "EL TORO",
                                code: "LTW"
                            },
                            {
                                name: "EL ABANICO",
                                code: "LEA"
                            }
                        ]
                    },
                    {
                        name: "ARAUCO",
                        code: 8202,
                        districts: [
                            {
                                name: "ARAUCO",
                                code: "ARA"
                            },
                            {
                                name: "RAQUI",
                                code: "ZUQ"
                            },
                            {
                                name: "QUIDICO - CONCEPCION",
                                code: "ZQR"
                            },
                            {
                                name: "RAMADILLA",
                                code: "ZMD"
                            },
                            {
                                name: "LLICO - CONCEPCION",
                                code: "ZCF"
                            },
                            {
                                name: "EL BOLDO",
                                code: "ZBC"
                            },
                            {
                                name: "VILLA ALEGRE - CONCE",
                                code: "VAG"
                            },
                            {
                                name: "CARAMPANGUE",
                                code: "PAN"
                            },
                            {
                                name: "LARAQUETE",
                                code: "LAQ"
                            }
                        ]
                    },
                    {
                        name: "CABRERO",
                        code: 8303,
                        districts: [
                            {
                                name: "SANTA CLARA",
                                code: "CLA"
                            },
                            {
                                name: "PASO HONDO",
                                code: "PHY"
                            },
                            {
                                name: "LIUCURA",
                                code: "YCU"
                            },
                            {
                                name: "PASO HONDO - CHILLAN",
                                code: "YCR"
                            },
                            {
                                name: "TOMECO",
                                code: "TMK"
                            },
                            {
                                name: "CABRERO",
                                code: "CRO"
                            }
                        ]
                    },
                    {
                        name: "CANETE",
                        code: 8203,
                        districts: [
                            {
                                name: "SAN ALFONSO - CONCEP",
                                code: "CFF"
                            },
                            {
                                name: "CAYUCUPIL",
                                code: "ZZW"
                            },
                            {
                                name: "CANETE",
                                code: "CTE"
                            }
                        ]
                    },
                    {
                        name: "CHIGUAYANTE",
                        code: 8103,
                        districts: [
                            {
                                name: "CHIGUAYANTE",
                                code: "CYE"
                            }
                        ]
                    },
                    {
                        name: "CONCEPCION",
                        code: 8101,
                        districts: [
                            {
                                name: "CONCEPCION",
                                code: "CCP"
                            },
                            {
                                name: "RANGUELMO",
                                code: "GUO"
                            }
                        ]
                    },
                    {
                        name: "CONTULMO",
                        code: 8204,
                        districts: [
                            {
                                name: "CONTULMO",
                                code: "CTU"
                            },
                            {
                                name: "RINCONADA - CONCEPCI",
                                code: "ZWE"
                            },
                            {
                                name: "ANTIQUINA",
                                code: "ZQG"
                            }
                        ]
                    },
                    {
                        name: "CORONEL",
                        code: 8102,
                        districts: [
                            {
                                name: "CORONEL",
                                code: "CRN"
                            }
                        ]
                    },
                    {
                        name: "CURANILAHUE",
                        code: 8205,
                        districts: [
                            {
                                name: "SAN JOSE DE COLICO",
                                code: "SJL"
                            },
                            {
                                name: "CURANILAHUE",
                                code: "ZHE"
                            }
                        ]
                    },
                    {
                        name: "FLORIDA",
                        code: 8104,
                        districts: [
                            {
                                name: "FLORIDA",
                                code: "FLO"
                            },
                            {
                                name: "AGUAS DE LA GLORIA",
                                code: "ZGG"
                            },
                            {
                                name: "COPIULEMU",
                                code: "ZUM"
                            }
                        ]
                    },
                    {
                        name: "HUALPEN",
                        code: 8112,
                        districts: [
                            {
                                name: "HUALPEN",
                                code: "HLP"
                            },
                            {
                                name: "LA BOCA - CONCEPCION",
                                code: "ZBB"
                            }
                        ]
                    },
                    {
                        name: "HUALQUI",
                        code: 8105,
                        districts: [
                            {
                                name: "TALCAMAVIDA",
                                code: "CTV"
                            },
                            {
                                name: "QUILACOYA",
                                code: "ZIQ"
                            },
                            {
                                name: "HUALQUI",
                                code: "HLQ"
                            }
                        ]
                    },
                    {
                        name: "LAJA",
                        code: 8304,
                        districts: [
                            {
                                name: "COLONIA",
                                code: "LCI"
                            },
                            {
                                name: "LAJA",
                                code: "LLJ"
                            }
                        ]
                    },
                    {
                        name: "LEBU",
                        code: 8201,
                        districts: [
                            {
                                name: "CURACO - CONCEPCION",
                                code: "MLC"
                            },
                            {
                                name: "SARA DE LEBU",
                                code: "ZBL"
                            },
                            {
                                name: "LEBU",
                                code: "ZLB"
                            },
                            {
                                name: "MILLONHUE",
                                code: "ZNG"
                            },
                            {
                                name: "YENECO",
                                code: "ZYY"
                            },
                            {
                                name: "RUCARAQUIL",
                                code: "ZYR"
                            },
                            {
                                name: "RANQUILCO",
                                code: "ZRQ"
                            },
                            {
                                name: "QUINAHUE - CONCEPCIO",
                                code: "ZQA"
                            },
                            {
                                name: "PEHUEN",
                                code: "ZPQ"
                            }
                        ]
                    },
                    {
                        name: "LOS ALAMOS",
                        code: 8206,
                        districts: [
                            {
                                name: "LOS ALAMOS",
                                code: "LAL"
                            },
                            {
                                name: "ANTIGUALA",
                                code: "ZNT"
                            },
                            {
                                name: "TRES PINOS",
                                code: "ZTP"
                            },
                            {
                                name: "PILPILCO",
                                code: "ZWP"
                            }
                        ]
                    },
                    {
                        name: "LOS ANGELES",
                        code: 8301,
                        districts: [
                            {
                                name: "EL ALAMO",
                                code: "LEL"
                            },
                            {
                                name: "SANTA CLARA",
                                code: "LST"
                            },
                            {
                                name: "SAN CARLOS PUREN - L",
                                code: "SCP"
                            },
                            {
                                name: "SAN CARLOS PUREN - L",
                                code: "SCW"
                            },
                            {
                                name: "LOS ANGELES",
                                code: "LSQ"
                            }
                        ]
                    },
                    {
                        name: "LOTA",
                        code: 8106,
                        districts: [
                            {
                                name: "LOTA",
                                code: "LOT"
                            }
                        ]
                    },
                    {
                        name: "MULCHEN",
                        code: 8305,
                        districts: [
                            {
                                name: "EL AVELLANO",
                                code: "LEV"
                            },
                            {
                                name: "MAITENES",
                                code: "LLM"
                            },
                            {
                                name: "EL MORRO",
                                code: "LMR"
                            },
                            {
                                name: "LOS MAICAS",
                                code: "LZX"
                            },
                            {
                                name: "MULCHEN",
                                code: "MUL"
                            },
                            {
                                name: "MELICA",
                                code: "MEA"
                            },
                            {
                                name: "SAN MIGUEL",
                                code: "LSM"
                            }
                        ]
                    },
                    {
                        name: "NACIMIENTO",
                        code: 8306,
                        districts: [
                            {
                                name: "CHOROICO",
                                code: "CIO"
                            },
                            {
                                name: "COIHUE",
                                code: "LCE"
                            },
                            {
                                name: "DIUQUIN",
                                code: "LDQ"
                            },
                            {
                                name: "PROGRESO",
                                code: "PGS"
                            },
                            {
                                name: "NACIMIENTO",
                                code: "NAC"
                            },
                            {
                                name: "SANTA FE",
                                code: "LSF"
                            }
                        ]
                    },
                    {
                        name: "NEGRETE",
                        code: 8307,
                        districts: [
                            {
                                name: "NEGRETE",
                                code: "NRE"
                            },
                            {
                                name: "RIHUE",
                                code: "RIE"
                            }
                        ]
                    },
                    {
                        name: "PENCO",
                        code: 8107,
                        districts: [
                            {
                                name: "LIRQUEN",
                                code: "LIR"
                            },
                            {
                                name: "PENCO",
                                code: "PCO"
                            },
                            {
                                name: "ROA",
                                code: "ZRO"
                            }
                        ]
                    },
                    {
                        name: "QUILACO",
                        code: 8308,
                        districts: [
                            {
                                name: "ALTO CALEDONIA",
                                code: "ANI"
                            },
                            {
                                name: "QUILACO",
                                code: "QCO"
                            },
                            {
                                name: "LONCOPANGUE",
                                code: "LGE"
                            },
                            {
                                name: "CERRO DEL PADRE",
                                code: "LCP"
                            },
                            {
                                name: "RUCALHUE",
                                code: "LRH"
                            }
                        ]
                    },
                    {
                        name: "QUILLECO",
                        code: 8309,
                        districts: [
                            {
                                name: "CANTERAS",
                                code: "CNT"
                            },
                            {
                                name: "QUILLECO",
                                code: "QLO"
                            },
                            {
                                name: "CANICURA",
                                code: "CRA"
                            },
                            {
                                name: "VILLA MERCEDES",
                                code: "LVM"
                            }
                        ]
                    },
                    {
                        name: "SAN PEDRO DE LA PAZ",
                        code: 8108,
                        districts: [
                            {
                                name: "SAN PEDRO DE LA PAZ",
                                code: "SPP"
                            }
                        ]
                    },
                    {
                        name: "SAN ROSENDO",
                        code: 8310,
                        districts: [
                            {
                                name: "BUENURAQUI",
                                code: "LBQ"
                            },
                            {
                                name: "SAN ROSENDO",
                                code: "SRO"
                            }
                        ]
                    },
                    {
                        name: "SANTA BARBARA",
                        code: 8311,
                        districts: [
                            {
                                name: "LOLCO",
                                code: "COC"
                            },
                            {
                                name: "SANTA BARBARA",
                                code: "SBB"
                            },
                            {
                                name: "VILLUCURA",
                                code: "LVU"
                            },
                            {
                                name: "LAS NIEVES",
                                code: "LNV"
                            },
                            {
                                name: "EL GUACHI",
                                code: "LGH"
                            },
                            {
                                name: "LOS BRUJOS",
                                code: "LBS"
                            },
                            {
                                name: "LOS PLACERES",
                                code: "LBO"
                            }
                        ]
                    },
                    {
                        name: "SANTA JUANA",
                        code: 8109,
                        districts: [
                            {
                                name: "SANTA JUANA",
                                code: "SJN"
                            }
                        ]
                    },
                    {
                        name: "TALCAHUANO",
                        code: 8110,
                        districts: [
                            {
                                name: "ISLA QUIRIQUINA",
                                code: "IQR"
                            },
                            {
                                name: "TALCAHUANO",
                                code: "ZTO"
                            },
                            {
                                name: "SAN VICENTE",
                                code: "SVC"
                            },
                            {
                                name: "RANCHO TALCAHUANO",
                                code: "RTH"
                            }
                        ]
                    },
                    {
                        name: "TIRUA",
                        code: 8207,
                        districts: [
                            {
                                name: "PAILACO",
                                code: "PLO"
                            },
                            {
                                name: "TIRUA",
                                code: "TUA"
                            },
                            {
                                name: "QUIDICO - TEMUCO",
                                code: "ZDQ"
                            }
                        ]
                    },
                    {
                        name: "TOME",
                        code: 8111,
                        districts: [
                            {
                                name: "DICHATO",
                                code: "DTO"
                            },
                            {
                                name: "TOME",
                                code: "TMC"
                            },
                            {
                                name: "MENQUE",
                                code: "ZQW"
                            },
                            {
                                name: "VEGAS DE ITATA",
                                code: "ZTT"
                            },
                            {
                                name: "RAFAEL",
                                code: "ZRA"
                            }
                        ]
                    },
                    {
                        name: "TUCAPEL",
                        code: 8312,
                        districts: [
                            {
                                name: "HUEPIL",
                                code: "HUP"
                            },
                            {
                                name: "TRUPAN",
                                code: "LTP"
                            },
                            {
                                name: "TUCAPEL",
                                code: "TCP"
                            },
                            {
                                name: "POLCURA",
                                code: "LPO"
                            }
                        ]
                    },
                    {
                        name: "YUMBEL",
                        code: 8313,
                        districts: [
                            {
                                name: "ESTACION YUMBEL",
                                code: "EYY"
                            },
                            {
                                name: "MONTE AGUILA",
                                code: "ZLA"
                            },
                            {
                                name: "YUMBEL",
                                code: "ZYU"
                            },
                            {
                                name: "RIO CLARO - LOS ANGE",
                                code: "LRC"
                            }
                        ]
                    }
                ]
            }
        ]
    }
};
export default geoStateResponse;