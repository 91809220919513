import React from 'react';
import PropTypes from 'prop-types';
import styles from './BxEmitRequestTableParams.module.css';

const BxEmitRequestTableParams = () => (
  <div className="snippet" data-testid="BxHeaders">
  <div className="process-detail-description">Following you will find all optional object that you can omit in the before request.</div>
  <table class="table table-atributes">
    <thead>
      <tr>
        <th scope="col"><strong>Object Name</strong></th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <th scope="row">
          <div><strong><em>dangerousPackages</em></strong></div>
        </th>
      </tr>
      <tr>
        <th scope="row">
          <div><strong><em>returnDocuments</em></strong></div>
        </th>
      </tr>
      <tr>
        <th scope="row">
          <div><strong><em>collectionsOnDelivery</em></strong></div>
        </th>
      </tr>
      <tr>
        <th scope="row">
          <div><strong><em>notificationContacts</em></strong></div>
        </th>
      </tr>
      <tr>
        <th scope="row">
          <div><strong><em>extras</em></strong></div>
        </th>
      </tr>
    </tbody>
  </table>
</div>
);

BxEmitRequestTableParams.propTypes = {};

BxEmitRequestTableParams.defaultProps = {};

export default BxEmitRequestTableParams;
