const pricingRequest = {
    from: {
        country: "CL",
        district: "SCL"
    },
    to: {
        country: "CL",
        state: 13,
        district: "SCL"
    },
    serviceType: "EX",
    serviciosComplementarios: null,
    datosProducto: {
        producto: "P",
        familiaProducto: "PAQU",
        largo: "10.0",
        ancho: "5.0",
        alto: "7.5",
        pesoFisico: "1.0",
        cantidadPiezas: 1,
        unidades: 1
    }
};
export default pricingRequest;