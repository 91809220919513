const emitRequestSample = {
    printFormatCode: 2,
    orderNumber: "1234567890",
    references: [
        "123456"
    ],
    serviceType: "EX",
    productType: "P",
    productCategory: "PAQU",
    currency: "CLP",
    shipmentCost: 0,
    extendedClaim: true,
    companyId: 2000,
    userName: "@userName",
    comments: "my very important comment.",
    pickup: {
        location: {
            stateId: 13,
            districtId: "SCL",
            address: "null",
            name: "null"
        },
        contact: {
            fullname: "null",
            phone: "null"
        }
    },
    dropoff: {
        contact: {
            fullname: "null",
            phone: "null"
        },
        location: {
            stateId: 8,
            districtId: "QLO",
            address: "null",
            name: "null"
        }
    },
    packages: [
        {
            weightUnit: "KG",
            lengthUnit: "CM",
            weight: 0.1,
            length: 0.1,
            width: 0.9,
            height: 0.5,
            quantity: 1,
            extras: [
                {
                    name: "some name",
                    value: "some value"
                }
            ]
        }
    ],
    dangerousPackages: null,
    returnDocuments: null,
    collectionsOnDelivery: null,
    notificationContacts: [
        {
            contactType: "D",
            contactChannel: 2,
            value: "null",
            events: [
                {
                    code: null
                }
            ]
        }
    ],
    extras: null
};
export default emitRequestSample;