const emitExceptionSample = {
    status: false,
    data: [
        "body.productCategory must not be null. Bluex's Product Category Types are: PAQU: Paquete, SOBR: Sobre, SYGT: Supermercado y Grandes Tiendas.",
        "body.printFormatCode must not be null. Print code options are: 1: Thermical Printer; 2: ZPL Printer; 3: JSON Format; 4: PDF Format.",
        "body.productType must not be null. Bluex's Product Types are: P: Paquete, SOB: Sobre, SYG: Supermercado y Grandes Tiendas.",
        "body.userName must not be null. Ask for it to Bluex's integration team.",
        "body.serviceType must not be null. Bluex's Service Types are: EX: Express, PY: Priority, MD: Sameday.",
        "body.references must not be null.",
        "body.shipmentCost must not be null."
    ]
};
export default emitExceptionSample;