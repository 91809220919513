import React from 'react';
import PropTypes from 'prop-types';
import styles from './BxTrackingPush.module.css';
import trackingPushSubscribeRequest from './../../jsons/trackingPushSubscribeRequest';
import trackingPushSubscribeResponse from './../../jsons/trackingPushSubscribeResponse';
import exceptionSchema from './../../jsons/exceptionSchema';
import trackingPushRequestSubscribeSample from './../../jsons/trackingPushRequestSubscribeSample';
import trackingPushResponseSubscribeSample from './../../jsons/trackingPushResponseSubscribeSample';
import trackingPushPayloadBody from './../../jsons/trackingPushPayloadBody';
import BxHeaders from './../BxHeaders/BxHeaders';
import exceptionSample from '../../jsons/exceptionSample';
import trackingPushRequestSample from '../../jsons/trackingPushRequestSample';
const BxTrackingPush = () => (
  <div className="process-detail-bx-tracking-push">
    <div className="process-detail-description">Bluex Tracking Push is a service that sends shipment's movement to our client's platform. It sends a JSON object to a restful endpoint that Bluex's clients must have available for it. Our clients can subscribe to tracking push calling the following endpoint:</div>
    <BxHeaders />
    <div className="documentation-container">
      <div className="process-detail-subtitle">BX-Tracking Push Subscription</div>
      <div className="snippet">
        <div className="process-detail-description">Allows you to subscribe to receive a tracking push notification.</div>
        <div className="endpoint-url">POST - https://bx-tracking.bluex.cl/bx-tracking/v1/tracking-push/subscription</div>
        <div className="process-detail-subtitle">Schema Request</div>
        <div className="process-detail-description">Here you can find the request schema especification.</div>
        <div><pre className="json">{JSON.stringify(trackingPushSubscribeRequest, null, 2)}</pre></div>
        <div className="process-detail-subtitle">Sample Request</div>
        <div className="process-detail-description">Here you can find a sample request of subscription.</div>
        <div><pre className="json">{JSON.stringify(trackingPushRequestSubscribeSample, null, 2)}</pre></div>
        <div className="process-detail-subtitle">Schema Exception</div>
        <div className="process-detail-description">Here you can find the exception schema especification.</div>
        <div><pre className="json">{JSON.stringify(exceptionSchema, null, 2)}</pre></div>
        <div className="process-detail-subtitle">Samples</div>
        <div className="process-detail-description">Here is a exception sample.</div>
        <div><pre className="json">{JSON.stringify(exceptionSample, null, 2)}</pre></div>
      </div>
      <div className="process-detail-subtitle">BX-Tracking Push Endpoint Receiver</div>
      <div className="snippet">
        <div className="process-detail-description">The endpoint that will going to receive the tracking push notification must have the following schema.</div>
        <div><pre className="json">{JSON.stringify(trackingPushPayloadBody, null, 2)}</pre></div>
        <div className="process-detail-subtitle">Samples</div>
        <div className="process-detail-description">Here is a request sample.</div>
        <div><pre className="json">{JSON.stringify(trackingPushRequestSample, null, 2)}</pre></div>
      </div>
    </div>
  </div>
);

BxTrackingPush.propTypes = {};

BxTrackingPush.defaultProps = {};

export default BxTrackingPush;
