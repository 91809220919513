import React from 'react';
import PropTypes from 'prop-types';
import styles from './BxEmission.module.css';
import BxHeaders from './../BxHeaders/BxHeaders';
import exceptionSchema from '../../jsons/exceptionSchema';
import BxEmitRequestTableParams from '../BxEmitRequestTableParams/BxEmitRequestTableParams';
import emitRequestSample from './../../jsons/emitRequestSample';
import emitResponseSample from './../../jsons/emitResponseSample';
import emitExceptionSample from './../../jsons/emitExceptionSample';
import emitRequest from './../../jsons/emitRequest';
import emitResponse from './../../jsons/emitResponse';

const BxEmission = () => (
  <div className="process-detail-bx-emission">
  <div className="process-detail-description">API Bluex Emission allows you to emit a service order (OS: orden de servicio). OS is a electronic document that basically allow Bluex to know what they have to move, where they have to pickup the shipment and where they have to deliver it.</div>
  <BxHeaders />
  <div className="documentation-container">
      <div className="process-detail-subtitle">BX-Emission</div>
      <div className="snippet">
          <div className="process-detail-description">Allows you to emit a OS.</div>
          <div className="endpoint-url">POST -https://bx-tracking.bluex.cl/bx-emission/v1</div>
          <div className="process-detail-subtitle">Schema Request</div>
          <div className="process-detail-description">Here you can find the request schema especification.</div>
          <div><pre className="json">{JSON.stringify(emitRequest, null, 2)}</pre></div>
          <BxEmitRequestTableParams/>
          <div className="process-detail-subtitle">Schema Response</div>
          <div className="process-detail-description">Here you can find the response schema especification.</div>
          <div><pre className="json">{JSON.stringify(emitResponse, null, 2)}</pre></div>
          <div className="process-detail-subtitle">Schema Exception</div>
          <div className="process-detail-description">Here you can find the exception schema especification.</div>
          <div><pre className="json">{JSON.stringify(exceptionSchema, null, 2)}</pre></div>
          <div className="process-detail-subtitle">Samples</div>
          <div className="process-detail-description">Here is a request sample. You must replace the @userName for yours.</div>
          <div><pre className="json">{JSON.stringify(emitRequestSample, null, 2)}</pre></div>
          <div className="process-detail-description">Here is a response sample.</div>
          <div><pre className="json">{JSON.stringify(emitResponseSample, null, 2)}</pre></div>
          <div className="process-detail-description">Here is a exception sample.</div>
          <div><pre className="json">{JSON.stringify(emitExceptionSample, null, 2)}</pre></div>
      </div>
     </div>
</div>

);

BxEmission.propTypes = {};

BxEmission.defaultProps = {};

export default BxEmission;
