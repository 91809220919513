const trackingPushRequestSubscribeSample = {
    endpoint: {
        lookUpToken: {
            url: "https://yourdomain/your/api/tracking/push/token/authentication",
            headers: [
                {
                    key: "some-header",
                    value: "some-value"
                }
            ]
        },
        postTracking: {
            url: "https://yourdomain/your/api/tracking/push",
            headers: [
                {
                    key: "some-header",
                    value: "some-value"
                }
            ]
        }
    }
};
export default trackingPushRequestSubscribeSample;