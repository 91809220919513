var pricingRangeRequest =
{
    serviceType: "EX",
    from: {
        country: "CL",
        district: "SCL"
    },
    to: {
        country: "CL",
        state: 8
    },
    shipmentDimensions: {
        productType: "P",
        familyProduct: "PAQU",
        lenght: 50,
        width: 52.5,
        heigh: 50,
        weight: 2.5,
        howManyPieces: 5,
        howManyUnits: 1
    },
    aditionalServices: null
  };
export default pricingRangeRequest;