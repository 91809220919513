import React from 'react';
import PropTypes from 'prop-types';
import styles from './BxPricing.module.css';
import pricingRangeRequest from './../../jsons/pricingRangeRequest';
import pricingRangeResponse from './../../jsons/pricingRangeResponse';
import pricingRequest from './../../jsons/pricingRequest';
import pricingResponse from './../../jsons/pricingResponse';
import BxHeaders from './../BxHeaders/BxHeaders';
import exceptionSchema from '../../jsons/exceptionSchema';
const BxPricing = () => (
  <div className="process-detail-bx-pricing">
    <div className="process-detail-description">API Bluex Pricing allows you to get all prices of shipment asociated to a <em>clientAccount</em>.</div>
    <BxHeaders />
    <div className="documentation-container">
      <div className="process-detail-subtitle">BX-Pricing</div>
      <div className="snippet">
        <div className="process-detail-description">Allows you to get the pricing of shipment from a some origin to some destination.</div>
        <div className="endpoint-url">POST - https://bx-tracking.bluex.cl/bx-pricing/v1</div>
        <div className="process-detail-subtitle">Request</div>
        <div><pre className="json">{JSON.stringify(pricingRequest, null, 2)}</pre></div>
        <div className="process-detail-subtitle">Response</div>
        <div><pre className="json">{JSON.stringify(pricingResponse, null, 2)}</pre></div>
        <div className="process-detail-description">Here you can find the exception schema especification.</div>
        <div><pre className="json">{JSON.stringify(exceptionSchema, null, 2)}</pre></div>
      </div>
      <div className="process-detail-subtitle">Range Pricing</div>
      <div className="snippet">
        <div className="process-detail-description">Allows you to get a list of pricing from a especific district to all districts of state given as a body param.</div>
        <div className="endpoint-url">POST - https://bx-tracking.bluex.cl/bx-pricing/range</div>
        <div className="process-detail-subtitle">Request</div>
        <div><pre className="json">{JSON.stringify(pricingRangeRequest, null, 2)}</pre></div>
        <div className="process-detail-subtitle">Response</div>
        <div><pre className="json">{JSON.stringify(pricingRangeResponse, null, 2)}</pre></div>
        <div className="process-detail-description">Here you can find the exception schema especification.</div>
        <div><pre className="json">{JSON.stringify(exceptionSchema, null, 2)}</pre></div>
      </div>
    </div>
  </div>

);

BxPricing.propTypes = {};

BxPricing.defaultProps = {};

export default BxPricing;
