const zoneResponse = {
    status: true,
    data: [
        {
            localidad: {
                code: "ARI",
                name: "ARICA"
            },
            posta: "ARICA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "RAR",
                name: "RANCHO ARICA"
            },
            posta: "ARICA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ARZ",
                name: "ALTO RAMIREZ"
            },
            posta: "ARICA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "APR",
                name: "AUSIPAR"
            },
            posta: "ARICA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "AZA",
                name: "AZAPA"
            },
            posta: "ARICA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "POC",
                name: "POCONCHILE"
            },
            posta: "ARICA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "AYA",
                name: "SOBRAYA"
            },
            posta: "ARICA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "AVF",
                name: "VILLA FRONTERA"
            },
            posta: "ARICA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "CAM",
                name: "CAMARONES"
            },
            posta: "ARICA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZYA",
                name: "CUYA"
            },
            posta: "ARICA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "CZZ",
                name: "CUZ CUZ"
            },
            posta: "ARICA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "AMO",
                name: "MOLINOS"
            },
            posta: "ARICA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ATS",
                name: "AGUAS CALIENTES"
            },
            posta: "ARICA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "AZT",
                name: "AZUFRERA TACORA"
            },
            posta: "ARICA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "BNN",
                name: "BELEN"
            },
            posta: "ARICA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "CTM",
                name: "CALETA CAMARONES"
            },
            posta: "ARICA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ACQ",
                name: "CAQUENA"
            },
            posta: "ARICA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ACC",
                name: "CHACUYO"
            },
            posta: "ARICA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ACP",
                name: "CHAPIQUIÑA"
            },
            posta: "ARICA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "CYH",
                name: "CHICAYA"
            },
            posta: "ARICA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ACL",
                name: "CHOQUELIMPIE"
            },
            posta: "ARICA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ACZ",
                name: "CODPA"
            },
            posta: "ARICA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "CQY",
                name: "COPAQUILLA"
            },
            posta: "ARICA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ACN",
                name: "CORONEL ALCERRECA"
            },
            posta: "ARICA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ACI",
                name: "COSAPILLA"
            },
            posta: "ARICA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "AQE",
                name: "ESQUINA"
            },
            posta: "ARICA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "LGR",
                name: "GENERAL LAGOS"
            },
            posta: "ARICA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "GLL",
                name: "GUACOLLO"
            },
            posta: "ARICA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ALL",
                name: "GUALLATIRI"
            },
            posta: "ARICA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "AGN",
                name: "GUANACAHUA"
            },
            posta: "ARICA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ITT",
                name: "ITISA"
            },
            posta: "ARICA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "JRI",
                name: "JURASI"
            },
            posta: "ARICA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "CGA",
                name: "LAGO CHUNGARA"
            },
            posta: "ARICA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "MKU",
                name: "MAILLKU"
            },
            posta: "ARICA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "MMN",
                name: "MINIMINE"
            },
            posta: "ARICA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "MIY",
                name: "MINITA"
            },
            posta: "ARICA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "NSH",
                name: "NASAHUENTO"
            },
            posta: "ARICA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "APH",
                name: "PACHAMA"
            },
            posta: "ARICA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "API",
                name: "PARINACOTA"
            },
            posta: "ARICA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "PQS",
                name: "PUQUISA"
            },
            posta: "ARICA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "PTR",
                name: "PUTRE"
            },
            posta: "ARICA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "QPT",
                name: "QUIPINTA"
            },
            posta: "ARICA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ASO",
                name: "SOCOROMA"
            },
            posta: "ARICA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "SOR",
                name: "SORA"
            },
            posta: "ARICA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "UCA",
                name: "SUCA"
            },
            posta: "ARICA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "TRG",
                name: "TARUGUIRE"
            },
            posta: "ARICA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ATQ",
                name: "TERMAS DE CHITUNE"
            },
            posta: "ARICA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ATG",
                name: "TIGNAMAR"
            },
            posta: "ARICA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "TCA",
                name: "TIMALCHACA"
            },
            posta: "ARICA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ATI",
                name: "TIMAR"
            },
            posta: "ARICA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "VIU",
                name: "VILLA INDUSTRIAL"
            },
            posta: "ARICA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "VIV",
                name: "VISVIRI"
            },
            posta: "ARICA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "IQQ",
                name: "IQUIQUE"
            },
            posta: "IQUIQUE",
            radio: "BASE"
        },
        {
            localidad: {
                code: "AHP",
                name: "ALTO HOSPICIO"
            },
            posta: "IQUIQUE",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ICB",
                name: "CALETA BUENA"
            },
            posta: "IQUIQUE",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZPL",
                name: "PLAYA BLANCA"
            },
            posta: "IQUIQUE",
            radio: "BASE"
        },
        {
            localidad: {
                code: "PAM",
                name: "POZO ALMONTE"
            },
            posta: "IQUIQUE",
            radio: "BASE"
        },
        {
            localidad: {
                code: "CMN",
                name: "CAMINA"
            },
            posta: "IQUIQUE",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "IFB",
                name: "FUERTE BAQUEDANO"
            },
            posta: "IQUIQUE",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "HRA",
                name: "HUARA"
            },
            posta: "IQUIQUE",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "LGC",
                name: "LA HUAICA"
            },
            posta: "IQUIQUE",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "LTI",
                name: "LA TIRANA"
            },
            posta: "IQUIQUE",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "MAN",
                name: "MAMIÑA"
            },
            posta: "IQUIQUE",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "MTA",
                name: "MATILLA"
            },
            posta: "IQUIQUE",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "OPC",
                name: "PICA"
            },
            posta: "IQUIQUE",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "PIS",
                name: "PISAGUA"
            },
            posta: "IQUIQUE",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "IPP",
                name: "PUERTO PATILLOS"
            },
            posta: "IQUIQUE",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "QLB",
                name: "PUNTA LOBOS"
            },
            posta: "IQUIQUE",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "PJR",
                name: "ALPAJERES"
            },
            posta: "IQUIQUE",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "IAL",
                name: "ALTUZA"
            },
            posta: "IQUIQUE",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ANG",
                name: "ANAGUANI"
            },
            posta: "IQUIQUE",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "CVT",
                name: "ANCOVINTO"
            },
            posta: "IQUIQUE",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ANQ",
                name: "ANCUAQUE"
            },
            posta: "IQUIQUE",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "CTA",
                name: "CALATAMBO"
            },
            posta: "IQUIQUE",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ICC",
                name: "CALETA CHICA"
            },
            posta: "IQUIQUE",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ICO",
                name: "CANCOSA"
            },
            posta: "IQUIQUE",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ICQ",
                name: "CARIQUIMA"
            },
            posta: "IQUIQUE",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "RTA",
                name: "CARITAYA"
            },
            posta: "IQUIQUE",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ICY",
                name: "CHAPIQUITA"
            },
            posta: "IQUIQUE",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ICI",
                name: "CHIAPA"
            },
            posta: "IQUIQUE",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "PQA",
                name: "CHIPANA"
            },
            posta: "IQUIQUE",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ICA",
                name: "CHUSMISA"
            },
            posta: "IQUIQUE",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "COE",
                name: "COLCHANE"
            },
            posta: "IQUIQUE",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "QLG",
                name: "COLLACAGUA"
            },
            posta: "IQUIQUE",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "CLH",
                name: "COLLAHUASI"
            },
            posta: "IQUIQUE",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ICP",
                name: "COLONIA PINTADOS"
            },
            posta: "IQUIQUE",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "QCR",
                name: "CORSA"
            },
            posta: "IQUIQUE",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "QEP",
                name: "ENQUELGA"
            },
            posta: "IQUIQUE",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZRC",
                name: "GUATACONDO"
            },
            posta: "IQUIQUE",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "IIS",
                name: "ISLUGA"
            },
            posta: "IQUIQUE",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ILI",
                name: "LIRIMA"
            },
            posta: "IQUIQUE",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "IMA",
                name: "MACAYA"
            },
            posta: "IQUIQUE",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "IMF",
                name: "MINA FAKIR"
            },
            posta: "IQUIQUE",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "IML",
                name: "MINA LOBOS"
            },
            posta: "IQUIQUE",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "BQM",
                name: "MINERA QUEBRADA BLANCA"
            },
            posta: "IQUIQUE",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "IMO",
                name: "MOCHA"
            },
            posta: "IQUIQUE",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "MCL",
                name: "MOCOMUCANE"
            },
            posta: "IQUIQUE",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "NMA",
                name: "NAMA"
            },
            posta: "IQUIQUE",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "IOV",
                name: "OFICINA VICTORIA"
            },
            posta: "IQUIQUE",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "IPH",
                name: "PACHICA"
            },
            posta: "IQUIQUE",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "PKA",
                name: "PALCA"
            },
            posta: "IQUIQUE",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "QPR",
                name: "POROMA"
            },
            posta: "IQUIQUE",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "PQU",
                name: "PUQUIOS"
            },
            posta: "IQUIQUE",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "RSC",
                name: "RIO SECO"
            },
            posta: "IQUIQUE",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "RFO",
                name: "SAN MARCOS"
            },
            posta: "IQUIQUE",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "STA",
                name: "SOTOCA"
            },
            posta: "IQUIQUE",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "IUJ",
                name: "TAMBILLO"
            },
            posta: "IQUIQUE",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ITP",
                name: "TARAPACA"
            },
            posta: "IQUIQUE",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "IVV",
                name: "VILAVILA"
            },
            posta: "IQUIQUE",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "CJC",
                name: "CALAMA"
            },
            posta: "CALAMA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "QUI",
                name: "CHUQUICAMATA"
            },
            posta: "CALAMA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "MRT",
                name: "MINA RADOMIRO TOMIC"
            },
            posta: "CALAMA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "SPM",
                name: "MINERA SPENCER"
            },
            posta: "CALAMA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "CCI",
                name: "CONCHI"
            },
            posta: "CALAMA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "CEY",
                name: "CUPO"
            },
            posta: "CALAMA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "MCD",
                name: "MINA CERRO DOMINADOR"
            },
            posta: "CALAMA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "MFD",
                name: "MINA FARIDE"
            },
            posta: "CALAMA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "MGY",
                name: "MINA GABY"
            },
            posta: "CALAMA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "SJE",
                name: "SAN JOSE"
            },
            posta: "CALAMA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "SPX",
                name: "SAN PEDRO DE ATACAMA"
            },
            posta: "CALAMA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "TCO",
                name: "TOCONAO"
            },
            posta: "CALAMA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "CTN",
                name: "TUINA"
            },
            posta: "CALAMA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "CAA",
                name: "AGUAS BLANCAS"
            },
            posta: "CALAMA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "CTZ",
                name: "ALITAR"
            },
            posta: "CALAMA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "AMC",
                name: "AMINCHA"
            },
            posta: "CALAMA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ASN",
                name: "ASCOTAN"
            },
            posta: "CALAMA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "QCA",
                name: "AUCANQUILCHA"
            },
            posta: "CALAMA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "CAY",
                name: "AYQUINA"
            },
            posta: "CALAMA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "CBT",
                name: "BANOS DE TURI"
            },
            posta: "CALAMA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "CCZ",
                name: "CALACHUZ"
            },
            posta: "CALAMA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "CMR",
                name: "CAMAR"
            },
            posta: "CALAMA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "CCR",
                name: "CARACOLES"
            },
            posta: "CALAMA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "CAP",
                name: "CASPANA"
            },
            posta: "CALAMA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "CDQ",
                name: "CENTINELA"
            },
            posta: "CALAMA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "CHU",
                name: "CHIUCHIU"
            },
            posta: "CALAMA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "CCV",
                name: "CONCHI VIEJO"
            },
            posta: "CALAMA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ETC",
                name: "ESTACION CARCOTE"
            },
            posta: "CALAMA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "CEB",
                name: "ESTACION CEBOLLAR"
            },
            posta: "CALAMA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "CCB",
                name: "ESTACION CERRITOS BAYOS"
            },
            posta: "CALAMA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "FDT",
                name: "FLOR DEL DESIERTO"
            },
            posta: "CALAMA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "CJI",
                name: "INCACALIRI"
            },
            posta: "CALAMA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "CXL",
                name: "LASANA"
            },
            posta: "CALAMA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "CLQ",
                name: "LEQUENA"
            },
            posta: "CALAMA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "LZR",
                name: "LINZOR"
            },
            posta: "CALAMA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "MLZ",
                name: "MELLIZOS"
            },
            posta: "CALAMA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ELA",
                name: "MINA EL ABRA"
            },
            posta: "CALAMA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "MLT",
                name: "MINA EL LITIO"
            },
            posta: "CALAMA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "OLL",
                name: "OLLAGUE"
            },
            posta: "CALAMA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "PNI",
                name: "PEINE"
            },
            posta: "CALAMA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "CXP",
                name: "POLAN"
            },
            posta: "CALAMA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "RMA",
                name: "PURITAMA"
            },
            posta: "CALAMA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "RGS",
                name: "RIO GRANDE"
            },
            posta: "CALAMA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "CSB",
                name: "SAN BARTOLO"
            },
            posta: "CALAMA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "SPD",
                name: "SAN PEDRO"
            },
            posta: "CALAMA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "CSR",
                name: "SANTA ROSA"
            },
            posta: "CALAMA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "CSE",
                name: "SOCAIRE"
            },
            posta: "CALAMA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "CTB",
                name: "TALABRE"
            },
            posta: "CALAMA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "CTP",
                name: "TILO POZO"
            },
            posta: "CALAMA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "CIL",
                name: "TILOMONTE"
            },
            posta: "CALAMA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "COV",
                name: "TOCONCE"
            },
            posta: "CALAMA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "TTR",
                name: "TURI"
            },
            posta: "CALAMA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ANF",
                name: "ANTOFAGASTA"
            },
            posta: "ANTOFAGASTA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "RAN",
                name: "RANCHO ANTOFAGASTA"
            },
            posta: "ANTOFAGASTA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "BQO",
                name: "BAQUEDANO"
            },
            posta: "ANTOFAGASTA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ACM",
                name: "CERRO MORENO"
            },
            posta: "ANTOFAGASTA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "AJL",
                name: "JUAN LOPEZ"
            },
            posta: "ANTOFAGASTA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "LNA",
                name: "LA NEGRA"
            },
            posta: "ANTOFAGASTA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "MJS",
                name: "MEJILLONES"
            },
            posta: "ANTOFAGASTA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "APC",
                name: "PUERTO COLOSO"
            },
            posta: "ANTOFAGASTA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "AEJ",
                name: "ESTACION LA RIOJA"
            },
            posta: "ANTOFAGASTA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "AHO",
                name: "HORNITOS"
            },
            posta: "ANTOFAGASTA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "MTB",
                name: "MANTOS BLANCOS"
            },
            posta: "ANTOFAGASTA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "MAE",
                name: "MARIA ELENA"
            },
            posta: "ANTOFAGASTA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "MEQ",
                name: "MARIA ELENA SOQUIMICH"
            },
            posta: "ANTOFAGASTA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "MIN",
                name: "MINA LOMAS BAYAS"
            },
            posta: "ANTOFAGASTA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "MIC",
                name: "MINA MICHILLA"
            },
            posta: "ANTOFAGASTA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "SGD",
                name: "SIERRA GORDA"
            },
            posta: "ANTOFAGASTA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "TOC",
                name: "TOCOPILLA"
            },
            posta: "ANTOFAGASTA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "AAV",
                name: "AGUA VERDE"
            },
            posta: "ANTOFAGASTA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "AZF",
                name: "AZUFRERA"
            },
            posta: "ANTOFAGASTA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ATM",
                name: "AZUFRERA PLATO DE SOPA"
            },
            posta: "ANTOFAGASTA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ABL",
                name: "BLANCO ENCALADA"
            },
            posta: "ANTOFAGASTA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ABT",
                name: "BRITANIA"
            },
            posta: "ANTOFAGASTA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "AJJ",
                name: "CALETA BOTIJA"
            },
            posta: "ANTOFAGASTA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ACB",
                name: "CALETA BOY"
            },
            posta: "ANTOFAGASTA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ACE",
                name: "CALETA BUENA"
            },
            posta: "ANTOFAGASTA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "AEE",
                name: "CALETA EL COBRE"
            },
            posta: "ANTOFAGASTA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "CIN",
                name: "CARMEN ALTO"
            },
            posta: "ANTOFAGASTA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "CPL",
                name: "CERRO PARANAL"
            },
            posta: "ANTOFAGASTA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ACJ",
                name: "COBIJA"
            },
            posta: "ANTOFAGASTA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "AEG",
                name: "EL GUANACO"
            },
            posta: "ANTOFAGASTA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "AEM",
                name: "EL MEDANO"
            },
            posta: "ANTOFAGASTA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "AEW",
                name: "EL WAY"
            },
            posta: "ANTOFAGASTA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "AEO",
                name: "ENSUENO"
            },
            posta: "ANTOFAGASTA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "AEB",
                name: "ESTACION AGUA BUENA"
            },
            posta: "ANTOFAGASTA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "AGC",
                name: "ESTACION AGUAS BLANCAS"
            },
            posta: "ANTOFAGASTA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "AEV",
                name: "ESTACION AUGUSTA VICTORIA"
            },
            posta: "ANTOFAGASTA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "AEC",
                name: "ESTACION CATALINA"
            },
            posta: "ANTOFAGASTA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "AEL",
                name: "ESTACION LOS VIENTOS"
            },
            posta: "ANTOFAGASTA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "AET",
                name: "ESTACION MIRAJE"
            },
            posta: "ANTOFAGASTA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "AMQ",
                name: "ESTACION MONTURAQUI"
            },
            posta: "ANTOFAGASTA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "AEH",
                name: "ESTACION O`HIGGINS"
            },
            posta: "ANTOFAGASTA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "APE",
                name: "ESTACION PALESTINA"
            },
            posta: "ANTOFAGASTA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "APZ",
                name: "ESTACION PAN DE AZUCAR"
            },
            posta: "ANTOFAGASTA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ESA",
                name: "ESTACION SOCOMPA"
            },
            posta: "ANTOFAGASTA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "AVE",
                name: "ESTACION VARILLA"
            },
            posta: "ANTOFAGASTA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "AXA",
                name: "EX OFICINA ALEMANIA"
            },
            posta: "ANTOFAGASTA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "AXC",
                name: "EX OFICINA CHILE"
            },
            posta: "ANTOFAGASTA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "AXF",
                name: "EX OFICINA FLOR DE CHILE"
            },
            posta: "ANTOFAGASTA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZSM",
                name: "ISLA SANTA MARIA"
            },
            posta: "ANTOFAGASTA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "AMI",
                name: "MICHILLA"
            },
            posta: "ANTOFAGASTA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "MZL",
                name: "MINERA ZALDIVAR"
            },
            posta: "ANTOFAGASTA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ALQ",
                name: "OFICINA PEDRO DE VALDIVIA"
            },
            posta: "ANTOFAGASTA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "OVR",
                name: "OFICINA VERGARA"
            },
            posta: "ANTOFAGASTA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "APP",
                name: "PAPOSO"
            },
            posta: "ANTOFAGASTA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "PRL",
                name: "PARANAL"
            },
            posta: "ANTOFAGASTA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "CQG",
                name: "QUILLAGUA"
            },
            posta: "ANTOFAGASTA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ASC",
                name: "SAN CRISTOBAL"
            },
            posta: "ANTOFAGASTA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "CPO",
                name: "COPIAPO"
            },
            posta: "COPIAPO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "CLR",
                name: "CALDERA"
            },
            posta: "COPIAPO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "EBL",
                name: "EL BORATILLO"
            },
            posta: "COPIAPO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "CBD",
                name: "ELISA DE BORDO"
            },
            posta: "COPIAPO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "MCR",
                name: "MINA CANDELARIA"
            },
            posta: "COPIAPO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "CNN",
                name: "NANTOCO"
            },
            posta: "COPIAPO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "PPE",
                name: "PAIPOTE"
            },
            posta: "COPIAPO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "CPV",
                name: "PUERTO VIEJO"
            },
            posta: "COPIAPO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "RCD",
                name: "RANCHO CALDERA"
            },
            posta: "COPIAPO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "TRM",
                name: "TIERRA AMARILLA"
            },
            posta: "COPIAPO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "RQM",
                name: "TOTORALILLO"
            },
            posta: "COPIAPO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "VZV",
                name: "ALGARROBAL"
            },
            posta: "COPIAPO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "CBH",
                name: "BAHIA INGLESA"
            },
            posta: "COPIAPO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "CHN",
                name: "CHANARAL"
            },
            posta: "COPIAPO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "QCI",
                name: "COLINA"
            },
            posta: "COPIAPO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "DAG",
                name: "DIEGO DE ALMAGRO"
            },
            posta: "COPIAPO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "VOB",
                name: "EL DONKEY"
            },
            posta: "COPIAPO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ESL",
                name: "EL SALADO"
            },
            posta: "COPIAPO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ESR",
                name: "EL SALVADOR"
            },
            posta: "COPIAPO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "QHN",
                name: "HORNITOS"
            },
            posta: "COPIAPO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "IRO",
                name: "INCA DE ORO"
            },
            posta: "COPIAPO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "VHY",
                name: "LA HOYADA"
            },
            posta: "COPIAPO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "VLO",
                name: "LOS COLORADOS"
            },
            posta: "COPIAPO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "QPQ",
                name: "PUQUIOS"
            },
            posta: "COPIAPO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "TTL",
                name: "TALTAL"
            },
            posta: "COPIAPO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "AMR",
                name: "ALTAMIRA"
            },
            posta: "COPIAPO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "AMN",
                name: "AMOLANAS"
            },
            posta: "COPIAPO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "BQQ",
                name: "BARRANQUILLAS"
            },
            posta: "COPIAPO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "QBQ",
                name: "BOCAMINA"
            },
            posta: "COPIAPO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "QCP",
                name: "CALETA PAJONAL"
            },
            posta: "COPIAPO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "CFI",
                name: "CIFUNCHO"
            },
            posta: "COPIAPO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "QPN",
                name: "EL PINO"
            },
            posta: "COPIAPO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "CVO",
                name: "EL VOLCAN"
            },
            posta: "COPIAPO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "SDA",
                name: "ESMERALDA"
            },
            posta: "COPIAPO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "CFC",
                name: "FINCA DE CHANARAL"
            },
            posta: "COPIAPO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "CFL",
                name: "FLAMENCO"
            },
            posta: "COPIAPO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "CHX",
                name: "HACIENDA CASTILLA"
            },
            posta: "COPIAPO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "VLA",
                name: "LA ARENA"
            },
            posta: "COPIAPO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "CGD",
                name: "LA GUARDIA"
            },
            posta: "COPIAPO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "QLA",
                name: "LA OLA"
            },
            posta: "COPIAPO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "LPV",
                name: "LA POLVORA"
            },
            posta: "COPIAPO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "CXA",
                name: "LA PUERTA"
            },
            posta: "COPIAPO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "CLJ",
                name: "LAS JUNTAS"
            },
            posta: "COPIAPO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "LLT",
                name: "LLANTA"
            },
            posta: "COPIAPO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "CLZ",
                name: "LOS AZULES"
            },
            posta: "COPIAPO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "CLW",
                name: "LOS LOROS"
            },
            posta: "COPIAPO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "CLC",
                name: "MINA CHIVATO"
            },
            posta: "COPIAPO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "QMD",
                name: "MINA DICHOSA"
            },
            posta: "COPIAPO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "CMQ",
                name: "MINA LA COIPA"
            },
            posta: "COPIAPO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "QME",
                name: "MINA LA ESTRELLA"
            },
            posta: "COPIAPO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "MTE",
                name: "MINA MARTE"
            },
            posta: "COPIAPO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "RSO",
                name: "MINA ROSARIO"
            },
            posta: "COPIAPO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "MNN",
                name: "MONTANDON"
            },
            posta: "COPIAPO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "OBT",
                name: "OBISPITO"
            },
            posta: "COPIAPO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "CPZ",
                name: "PAN DE AZUCAR"
            },
            posta: "COPIAPO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "QPL",
                name: "PASTOS LARGOS"
            },
            posta: "COPIAPO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "QPO",
                name: "PLAYA REFUGIO"
            },
            posta: "COPIAPO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "PTS",
                name: "POTRERILLOS"
            },
            posta: "COPIAPO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "CPF",
                name: "PUERTO FINO"
            },
            posta: "COPIAPO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "SAI",
                name: "SAN ANTONIO"
            },
            posta: "COPIAPO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "QTR",
                name: "TERMAS DE RIO NEGRO"
            },
            posta: "COPIAPO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "CTX",
                name: "TOTORAL"
            },
            posta: "COPIAPO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "CVX",
                name: "VALLE HERMOSO"
            },
            posta: "COPIAPO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "QVC",
                name: "VEGAS DE CHANARAL ALTO"
            },
            posta: "COPIAPO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "COQ",
                name: "COQUIMBO"
            },
            posta: "LA SERENA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "LRR",
                name: "LA HERRADURA"
            },
            posta: "LA SERENA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "LSC",
                name: "LA SERENA"
            },
            posta: "LA SERENA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "LTB",
                name: "TIERRAS BLANCAS"
            },
            posta: "LA SERENA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "RRG",
                name: "ALGARROBITO"
            },
            posta: "LA SERENA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ADC",
                name: "ALTO DEL CARMEN"
            },
            posta: "LA SERENA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "AVS",
                name: "ALTOVALSOL"
            },
            posta: "LA SERENA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "BRR",
                name: "BARRANCAS"
            },
            posta: "LA SERENA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "RRB",
                name: "BARRAZA"
            },
            posta: "LA SERENA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "RQV",
                name: "CAMARICO VIEJO"
            },
            posta: "LA SERENA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "CTY",
                name: "CERRILLOS DE TAMAYA"
            },
            posta: "LA SERENA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "AGA",
                name: "CHALINGA"
            },
            posta: "LA SERENA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "OCT",
                name: "CHILECITO"
            },
            posta: "LA SERENA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "LGY",
                name: "CHUNGUNGO"
            },
            posta: "LA SERENA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "LCQ",
                name: "COQUIMBITO"
            },
            posta: "LA SERENA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "RQO",
                name: "CORRAL QUEMADO"
            },
            posta: "LA SERENA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "DYK",
                name: "DOMEYKO"
            },
            posta: "LA SERENA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "REA",
                name: "EL ALTAR"
            },
            posta: "LA SERENA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "OEC",
                name: "EL CARRIZAL"
            },
            posta: "LA SERENA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "LEI",
                name: "EL ISLON"
            },
            posta: "LA SERENA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "REM",
                name: "EL MAITEN"
            },
            posta: "LA SERENA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "RMQ",
                name: "EL MAQUI"
            },
            posta: "LA SERENA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "PON",
                name: "EL PENON"
            },
            posta: "LA SERENA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "TMO",
                name: "EL RETAMO"
            },
            posta: "LA SERENA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "LER",
                name: "EL ROMERAL"
            },
            posta: "LA SERENA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "TFO",
                name: "EL TOFO"
            },
            posta: "LA SERENA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "FRN",
                name: "FREIRINA"
            },
            posta: "LA SERENA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "GHC",
                name: "GUACHALALUME"
            },
            posta: "LA SERENA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "OGT",
                name: "GUAMALATA"
            },
            posta: "LA SERENA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "RGU",
                name: "GUAMPULLA"
            },
            posta: "LA SERENA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "GQS",
                name: "GUANAQUEROS"
            },
            posta: "LA SERENA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "RHG",
                name: "HIGUERITAS"
            },
            posta: "LA SERENA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "HCO",
                name: "HUASCO"
            },
            posta: "LA SERENA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "HCB",
                name: "HUASCO BAJO"
            },
            posta: "LA SERENA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "LIH",
                name: "INCAGUASI"
            },
            posta: "LA SERENA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "RLG",
                name: "LA AGUADA"
            },
            posta: "LA SERENA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "OLB",
                name: "LA CHIMBA"
            },
            posta: "LA SERENA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "GOS",
                name: "LA COMPAÑÍA"
            },
            posta: "LA SERENA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "RLF",
                name: "LA FUNDINA"
            },
            posta: "LA SERENA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "LHC",
                name: "LA HIGUERA"
            },
            posta: "LA SERENA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "OLP",
                name: "LA PLACILLA"
            },
            posta: "LA SERENA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "LAT",
                name: "LA TORRE"
            },
            posta: "LA SERENA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "LBT",
                name: "LAMBERT"
            },
            posta: "LA SERENA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "LLS",
                name: "LAS CARDAS"
            },
            posta: "LA SERENA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "RLJ",
                name: "LAS JUNTAS"
            },
            posta: "LA SERENA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "RSE",
                name: "LAS ROJAS"
            },
            posta: "LA SERENA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "LTC",
                name: "LAS TACAS"
            },
            posta: "LA SERENA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "RLI",
                name: "LIMARI"
            },
            posta: "LA SERENA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "LHB",
                name: "LOS HORNOS"
            },
            posta: "LA SERENA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "OTR",
                name: "OTAROLA"
            },
            posta: "LA SERENA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "OVL",
                name: "OVALLE"
            },
            posta: "LA SERENA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "PIC",
                name: "PACHINGO"
            },
            posta: "LA SERENA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "LPZ",
                name: "PAN DE AZUCAR"
            },
            posta: "LA SERENA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "RPJ",
                name: "PEJERREYES"
            },
            posta: "LA SERENA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "OPB",
                name: "PENA BLANCA"
            },
            posta: "LA SERENA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "PLB",
                name: "PENUELAS"
            },
            posta: "LA SERENA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "RPI",
                name: "PICHASCA"
            },
            posta: "LA SERENA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "PVO",
                name: "PUERTO VELERO"
            },
            posta: "LA SERENA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "PTQ",
                name: "PUNITAQUI"
            },
            posta: "LA SERENA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "QWR",
                name: "QUEBRADA SECA"
            },
            posta: "LA SERENA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "RQW",
                name: "RAPEL"
            },
            posta: "LA SERENA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "RTG",
                name: "RECOLETA"
            },
            posta: "LA SERENA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "RTO",
                name: "RETIRO"
            },
            posta: "LA SERENA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "RVG",
                name: "SAMO ALTO"
            },
            posta: "LA SERENA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "RVF",
                name: "SAMO BAJO"
            },
            posta: "LA SERENA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "XPB",
                name: "SAN PABLO"
            },
            posta: "LA SERENA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "SOZ",
                name: "SOTAQUI"
            },
            posta: "LA SERENA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "TNS",
                name: "TEATINOS"
            },
            posta: "LA SERENA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "SQT",
                name: "TERMAS DE SOCO"
            },
            posta: "LA SERENA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "TGY",
                name: "TONGOY"
            },
            posta: "LA SERENA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "TOT",
                name: "TOTORALILLO"
            },
            posta: "LA SERENA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "TCS",
                name: "TRES CRUCES"
            },
            posta: "LA SERENA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "VAL",
                name: "VALLENAR"
            },
            posta: "LA SERENA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "VCA",
                name: "VICUNA"
            },
            posta: "LA SERENA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "AGG",
                name: "AGUA GRANDE"
            },
            posta: "LA SERENA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ALT",
                name: "ALMIRANTE LATORRE"
            },
            posta: "LA SERENA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ACO",
                name: "ANDACOLLO"
            },
            posta: "LA SERENA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "AVU",
                name: "ANDACOLLO HOLDING"
            },
            posta: "LA SERENA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "LBW",
                name: "BALALA"
            },
            posta: "LA SERENA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "VCQ",
                name: "CACHIYUYO"
            },
            posta: "LA SERENA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "CRI",
                name: "CAMARICO"
            },
            posta: "LA SERENA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "TAG",
                name: "CANTO DE AGUA"
            },
            posta: "LA SERENA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "KEN",
                name: "CAREN"
            },
            posta: "LA SERENA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "LCR",
                name: "CASERONES"
            },
            posta: "LA SERENA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "RCM",
                name: "CENTRAL LOS MOLLES"
            },
            posta: "LA SERENA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "LKK",
                name: "CHAPILCA"
            },
            posta: "LA SERENA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "LKM",
                name: "CHOROS BAJOS"
            },
            posta: "LA SERENA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "LCO",
                name: "CONDORIACO"
            },
            posta: "LA SERENA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "CEA",
                name: "CORTADERA"
            },
            posta: "LA SERENA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "DTS",
                name: "DIAGUITAS"
            },
            posta: "LA SERENA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "HAY",
                name: "EL CHACAY"
            },
            posta: "LA SERENA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "EOV",
                name: "EL OLIVO"
            },
            posta: "LA SERENA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "LEM",
                name: "EL ROMERAL"
            },
            posta: "LA SERENA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "TBE",
                name: "EL TAMBO"
            },
            posta: "LA SERENA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "VET",
                name: "EL TRANSITO"
            },
            posta: "LA SERENA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "RHT",
                name: "HURTADO"
            },
            posta: "LA SERENA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "VFR",
                name: "LA FRAGUITA"
            },
            posta: "LA SERENA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "VLH",
                name: "LA HIGUERITA"
            },
            posta: "LA SERENA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "YCV",
                name: "LA LAJA"
            },
            posta: "LA SERENA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "RLU",
                name: "LA LIGUA BAJO"
            },
            posta: "LA SERENA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "VLB",
                name: "LABRAR"
            },
            posta: "LA SERENA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "LMS",
                name: "LAS MOLLACAS"
            },
            posta: "LA SERENA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "RLL",
                name: "LITIPAMPA"
            },
            posta: "LA SERENA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "LLG",
                name: "LLANOS DE GUANTA"
            },
            posta: "LA SERENA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "CXQ",
                name: "LOS TOYOS"
            },
            posta: "LA SERENA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "VMT",
                name: "MAITENCILLO"
            },
            posta: "LA SERENA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "RMH",
                name: "MANTOS DE HORNILLOS"
            },
            posta: "LA SERENA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "VMI",
                name: "MINA ALGARROBO"
            },
            posta: "LA SERENA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "VMR",
                name: "MIRAFLORES"
            },
            posta: "LA SERENA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "MPC",
                name: "MONTE PATRIA"
            },
            posta: "LA SERENA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "LOO",
                name: "OBSERVATORIO EL TOLOLO"
            },
            posta: "LA SERENA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "LSX",
                name: "OBSERVATORIO LA SILLA"
            },
            posta: "LA SERENA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "VOS",
                name: "OBSERVATORIO LAS CAMPANAS"
            },
            posta: "LA SERENA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "RGR",
                name: "PEDREGAL"
            },
            posta: "LA SERENA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "PLV",
                name: "PERALILLO"
            },
            posta: "LA SERENA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ELQ",
                name: "PISCO ELQUI"
            },
            posta: "LA SERENA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "PLM",
                name: "PLACILLA"
            },
            posta: "LA SERENA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "PHS",
                name: "PUNTA CHOROS"
            },
            posta: "LA SERENA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "QWQ",
                name: "QUILITAPIA"
            },
            posta: "LA SERENA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "RHU",
                name: "RIO HURTADO"
            },
            posta: "LA SERENA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "LRV",
                name: "RIVADAVIA"
            },
            posta: "LA SERENA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "VSA",
                name: "SAN FELIX"
            },
            posta: "LA SERENA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "SMC",
                name: "SAN MARCOS"
            },
            posta: "LA SERENA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "LTH",
                name: "TRAPICHE"
            },
            posta: "LA SERENA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "TNH",
                name: "TULAHUEN"
            },
            posta: "LA SERENA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "LVR",
                name: "VARILLAR"
            },
            posta: "LA SERENA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "LVT",
                name: "VINITA BAJA"
            },
            posta: "LA SERENA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "MRR",
                name: "ALCOHUAS"
            },
            posta: "LA SERENA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "OAY",
                name: "ALTO BUEY"
            },
            posta: "LA SERENA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "OBG",
                name: "BANOS DEL GORDITO"
            },
            posta: "LA SERENA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "LBJ",
                name: "BANOS DEL TORO"
            },
            posta: "LA SERENA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "XBC",
                name: "BOCATOMA"
            },
            posta: "LA SERENA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "VCL",
                name: "CALETA CHANARAL"
            },
            posta: "LA SERENA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "CCM",
                name: "CALETA DEL MEDIO"
            },
            posta: "LA SERENA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "RCC",
                name: "CALETA MORRITOS"
            },
            posta: "LA SERENA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "TSC",
                name: "CALETA SARCO"
            },
            posta: "LA SERENA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "VCR",
                name: "CARRIZAL BAJO"
            },
            posta: "LA SERENA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "VCI",
                name: "CARRIZALILLO"
            },
            posta: "LA SERENA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "LHH",
                name: "CHANAR"
            },
            posta: "LA SERENA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "CHZ",
                name: "CHANARAL ALTO"
            },
            posta: "LA SERENA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "CYO",
                name: "CHANARCILLO"
            },
            posta: "LA SERENA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "OCH",
                name: "CHINEO"
            },
            posta: "LA SERENA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ONG",
                name: "CHINGAY"
            },
            posta: "LA SERENA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "CGS",
                name: "COCHIGUAS"
            },
            posta: "LA SERENA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "RCT",
                name: "COGOTI"
            },
            posta: "LA SERENA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "COB",
                name: "COMBARBALA"
            },
            posta: "LA SERENA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "VCO",
                name: "CONAY"
            },
            posta: "LA SERENA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZMJ",
                name: "EL NEVADO"
            },
            posta: "LA SERENA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "EPI",
                name: "EL PALQUI"
            },
            posta: "LA SERENA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "LGU",
                name: "GUANTA"
            },
            posta: "LA SERENA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "GUT",
                name: "GUATULAME"
            },
            posta: "LA SERENA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "XMR",
                name: "HACIENDA LOS ANDES"
            },
            posta: "LA SERENA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "OHY",
                name: "HILARICOS"
            },
            posta: "LA SERENA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "VJU",
                name: "JUNTA VALERIANO"
            },
            posta: "LA SERENA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "LJZ",
                name: "JUNTAS DEL TORO"
            },
            posta: "LA SERENA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZTA",
                name: "LAGUNA GRANDE"
            },
            posta: "LA SERENA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "RLB",
                name: "LAS BREAS"
            },
            posta: "LA SERENA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "LZZ",
                name: "LAS COLORADAS"
            },
            posta: "LA SERENA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "LHD",
                name: "LAS HEDIONDAS"
            },
            posta: "LA SERENA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "RLR",
                name: "LAS RAMADAS"
            },
            posta: "LA SERENA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "OLH",
                name: "LLAHUIN"
            },
            posta: "LA SERENA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "LZP",
                name: "LOS PERALES"
            },
            posta: "LA SERENA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "LMX",
                name: "MINA DEL INDIO"
            },
            posta: "LA SERENA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "LMG",
                name: "MONTE GRANDE"
            },
            posta: "LA SERENA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "NEQ",
                name: "NUEVA ELQUI"
            },
            posta: "LA SERENA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "RPB",
                name: "PABELLON"
            },
            posta: "LA SERENA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "PHO",
                name: "PAIHUANO"
            },
            posta: "LA SERENA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "OPA",
                name: "PARMA"
            },
            posta: "LA SERENA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "RFG",
                name: "SAN LORENZO"
            },
            posta: "LA SERENA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "SSC",
                name: "SANTA CECILIA"
            },
            posta: "LA SERENA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "TAL",
                name: "SOL NACIENTE"
            },
            posta: "LA SERENA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZLC",
                name: "LA CALERA"
            },
            posta: "LA CALERA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "LCZ",
                name: "LA CRUZ"
            },
            posta: "LA CALERA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "QTA",
                name: "QUILLOTA"
            },
            posta: "LA CALERA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZAI",
                name: "ALICAHUE"
            },
            posta: "LA CALERA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZAT",
                name: "ARTIFICIO"
            },
            posta: "LA CALERA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "CDO",
                name: "CABILDO"
            },
            posta: "LA CALERA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "CGU",
                name: "CACHAGUA"
            },
            posta: "LA CALERA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "LNO",
                name: "CALETA HORCON"
            },
            posta: "LA CALERA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "LAR",
                name: "CALETA POLCURA"
            },
            posta: "LA CALERA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZZC",
                name: "CANELA ALTA"
            },
            posta: "LA CALERA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZOQ",
                name: "CASUTO"
            },
            posta: "LA CALERA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "PIL",
                name: "CATAPILCO"
            },
            posta: "LA CALERA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZOA",
                name: "CHIGUALOCO"
            },
            posta: "LA CALERA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZEC",
                name: "EL COBRE"
            },
            posta: "LA CALERA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZEG",
                name: "EL GUAYACAN"
            },
            posta: "LA CALERA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZEI",
                name: "EL INGENIO"
            },
            posta: "LA CALERA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "EML",
                name: "EL MELON"
            },
            posta: "LA CALERA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZQD",
                name: "EL RINCON"
            },
            posta: "LA CALERA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZGR",
                name: "GRANIZO"
            },
            posta: "LA CALERA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZIV",
                name: "GUANGALI"
            },
            posta: "LA CALERA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZHR",
                name: "HIERRO VIEJO"
            },
            posta: "LA CALERA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "HJS",
                name: "HIJUELAS"
            },
            posta: "LA CALERA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZHT",
                name: "HUENTELAUQUEN"
            },
            posta: "LA CALERA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ILL",
                name: "ILLAPEL"
            },
            posta: "LA CALERA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "LGN",
                name: "LA LAGUNA"
            },
            posta: "LA CALERA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "LLC",
                name: "LA LIGUA"
            },
            posta: "LA CALERA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "LAO",
                name: "LA MORA"
            },
            posta: "LA CALERA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZGC",
                name: "LA PATAGUA"
            },
            posta: "LA CALERA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "QZC",
                name: "LA QUEBRADA"
            },
            posta: "LA CALERA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "XLV",
                name: "LA VINA"
            },
            posta: "LA CALERA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "LSA",
                name: "LAS PALMAS"
            },
            posta: "LA CALERA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "LLY",
                name: "LLAILLAY"
            },
            posta: "LA CALERA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "GMA",
                name: "LONGOTOMA"
            },
            posta: "LA CALERA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZKK",
                name: "LOS ERMITANOS"
            },
            posta: "LA CALERA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "LMO",
                name: "LOS MOLLES"
            },
            posta: "LA CALERA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "LVL",
                name: "LOS VILOS"
            },
            posta: "LA CALERA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "MTC",
                name: "MAITENCILLO"
            },
            posta: "LA CALERA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZMU",
                name: "MANUEL MONTT"
            },
            posta: "LA CALERA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZMH",
                name: "MINCHA"
            },
            posta: "LA CALERA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZMM",
                name: "MINCHA SUR"
            },
            posta: "LA CALERA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "NOG",
                name: "NOGALES"
            },
            posta: "LA CALERA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "OLM",
                name: "OLMUE"
            },
            posta: "LA CALERA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "PPO",
                name: "PAPUDO"
            },
            posta: "LA CALERA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "PUA",
                name: "PEDEGUA"
            },
            posta: "LA CALERA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "PZY",
                name: "PICHICUY"
            },
            posta: "LA CALERA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "PCH",
                name: "PICHIDANGUI"
            },
            posta: "LA CALERA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "PCV",
                name: "PUCHUNCAVI"
            },
            posta: "LA CALERA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "LOI",
                name: "PUPIO"
            },
            posta: "LA CALERA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZQB",
                name: "QUEBRADA ALVARADO"
            },
            posta: "LA CALERA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZQM",
                name: "QUILIMARI"
            },
            posta: "LA CALERA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZQQ",
                name: "QUINQUIMO"
            },
            posta: "LA CALERA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZTL",
                name: "RABANALES"
            },
            posta: "LA CALERA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZSL",
                name: "SAN LORENZO"
            },
            posta: "LA CALERA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZDD",
                name: "SAN PEDRO"
            },
            posta: "LA CALERA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZST",
                name: "SANTA MARTA"
            },
            posta: "LA CALERA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZEW",
                name: "TRAPICHE"
            },
            posta: "LA CALERA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "BAG",
                name: "VALLE ALEGRE"
            },
            posta: "LA CALERA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "VTA",
                name: "VENTANAS"
            },
            posta: "LA CALERA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZAR",
                name: "ZAPALLAR"
            },
            posta: "LA CALERA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ABG",
                name: "ARBOLEDA GRANDE"
            },
            posta: "LA CALERA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ANS",
                name: "CAIMANES"
            },
            posta: "LA CALERA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "CNE",
                name: "CANELA"
            },
            posta: "LA CALERA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "CNB",
                name: "CANELA BAJA"
            },
            posta: "LA CALERA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "OLC",
                name: "CHINCOLCO"
            },
            posta: "LA CALERA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "CPA",
                name: "CHOAPA"
            },
            posta: "LA CALERA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "CCY",
                name: "COCUA"
            },
            posta: "LA CALERA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZET",
                name: "EL TAMBO"
            },
            posta: "LA CALERA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZHN",
                name: "HUINTIL"
            },
            posta: "LA CALERA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZJO",
                name: "JORQUERA"
            },
            posta: "LA CALERA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "LZC",
                name: "LAS CANAS"
            },
            posta: "LA CALERA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZKZ",
                name: "LAS PIRCAS"
            },
            posta: "LA CALERA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZVH",
                name: "LIMAHUIDA"
            },
            posta: "LA CALERA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZMC",
                name: "MATANCILLA"
            },
            posta: "LA CALERA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZMS",
                name: "MINA EL ROSARIO"
            },
            posta: "LA CALERA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZDR",
                name: "PEDERNAL"
            },
            posta: "LA CALERA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "PTK",
                name: "PETORCA"
            },
            posta: "LA CALERA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "SCA",
                name: "SALAMANCA"
            },
            posta: "LA CALERA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZTI",
                name: "TILAMA"
            },
            posta: "LA CALERA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "TTZ",
                name: "TOTORALILLO"
            },
            posta: "LA CALERA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZTU",
                name: "TUNGA NORTE"
            },
            posta: "LA CALERA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZTN",
                name: "TUNGA SUR"
            },
            posta: "LA CALERA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZAM",
                name: "ALMENDRALILLO"
            },
            posta: "LA CALERA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZBG",
                name: "BATUCO"
            },
            posta: "LA CALERA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZNK",
                name: "CAREN"
            },
            posta: "LA CALERA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "LOA",
                name: "CHALACO"
            },
            posta: "LA CALERA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "LNI",
                name: "CHELLEPIN"
            },
            posta: "LA CALERA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZNP",
                name: "COIRON"
            },
            posta: "LA CALERA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "AZZ",
                name: "CUZ CUZ"
            },
            posta: "LA CALERA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZFA",
                name: "FARELLON SANCHEZ"
            },
            posta: "LA CALERA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "LLP",
                name: "LLIMPO"
            },
            posta: "LA CALERA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "LSO",
                name: "LOS PELADEROS"
            },
            posta: "LA CALERA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZMR",
                name: "MAURO"
            },
            posta: "LA CALERA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "PZC",
                name: "PALQUIAL"
            },
            posta: "LA CALERA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "XZH",
                name: "POZA HONDA"
            },
            posta: "LA CALERA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "POZ",
                name: "PUERTO OSCURO"
            },
            posta: "LA CALERA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZQO",
                name: "QUELON"
            },
            posta: "LA CALERA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZSG",
                name: "SAN AGUSTIN"
            },
            posta: "LA CALERA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "CON",
                name: "CONCON"
            },
            posta: "VINA DEL MAR",
            radio: "BASE"
        },
        {
            localidad: {
                code: "RCA",
                name: "RENACA"
            },
            posta: "VINA DEL MAR",
            radio: "BASE"
        },
        {
            localidad: {
                code: "VAP",
                name: "VALPARAISO"
            },
            posta: "VINA DEL MAR",
            radio: "BASE"
        },
        {
            localidad: {
                code: "KNA",
                name: "VINA DEL MAR"
            },
            posta: "VINA DEL MAR",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ABO",
                name: "ALGARROBO"
            },
            posta: "VINA DEL MAR",
            radio: "BASE"
        },
        {
            localidad: {
                code: "KCD",
                name: "CALETAS LAS DOCAS"
            },
            posta: "VINA DEL MAR",
            radio: "BASE"
        },
        {
            localidad: {
                code: "CRT",
                name: "CARTAGENA"
            },
            posta: "VINA DEL MAR",
            radio: "BASE"
        },
        {
            localidad: {
                code: "CBC",
                name: "CASABLANCA"
            },
            posta: "VINA DEL MAR",
            radio: "BASE"
        },
        {
            localidad: {
                code: "AZU",
                name: "COSTA AZUL"
            },
            posta: "VINA DEL MAR",
            radio: "BASE"
        },
        {
            localidad: {
                code: "EBT",
                name: "EL BELLOTO"
            },
            posta: "VINA DEL MAR",
            radio: "BASE"
        },
        {
            localidad: {
                code: "EQO",
                name: "EL QUISCO"
            },
            posta: "VINA DEL MAR",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ETB",
                name: "EL TABO"
            },
            posta: "VINA DEL MAR",
            radio: "BASE"
        },
        {
            localidad: {
                code: "INE",
                name: "ISLA NEGRA"
            },
            posta: "VINA DEL MAR",
            radio: "BASE"
        },
        {
            localidad: {
                code: "LAV",
                name: "LAGUNA VERDE"
            },
            posta: "VINA DEL MAR",
            radio: "BASE"
        },
        {
            localidad: {
                code: "KLL",
                name: "LAGUNILLAS"
            },
            posta: "VINA DEL MAR",
            radio: "BASE"
        },
        {
            localidad: {
                code: "LCX",
                name: "LAS CRUCES"
            },
            posta: "VINA DEL MAR",
            radio: "BASE"
        },
        {
            localidad: {
                code: "KHD",
                name: "LAS DICHAS"
            },
            posta: "VINA DEL MAR",
            radio: "BASE"
        },
        {
            localidad: {
                code: "KME",
                name: "LAS MERCEDES"
            },
            posta: "VINA DEL MAR",
            radio: "BASE"
        },
        {
            localidad: {
                code: "KLT",
                name: "LAS TABLAS"
            },
            posta: "VINA DEL MAR",
            radio: "BASE"
        },
        {
            localidad: {
                code: "LIC",
                name: "LIMACHE"
            },
            posta: "VINA DEL MAR",
            radio: "BASE"
        },
        {
            localidad: {
                code: "LLO",
                name: "LLO-LLEO"
            },
            posta: "VINA DEL MAR",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ABA",
                name: "LO ABARCA"
            },
            posta: "VINA DEL MAR",
            radio: "BASE"
        },
        {
            localidad: {
                code: "KLG",
                name: "LO GALLARDO"
            },
            posta: "VINA DEL MAR",
            radio: "BASE"
        },
        {
            localidad: {
                code: "KLO",
                name: "LO OROZCO"
            },
            posta: "VINA DEL MAR",
            radio: "BASE"
        },
        {
            localidad: {
                code: "KLV",
                name: "LO VASQUEZ"
            },
            posta: "VINA DEL MAR",
            radio: "BASE"
        },
        {
            localidad: {
                code: "MRL",
                name: "MIRASOL"
            },
            posta: "VINA DEL MAR",
            radio: "BASE"
        },
        {
            localidad: {
                code: "KPE",
                name: "PENUELAS"
            },
            posta: "VINA DEL MAR",
            radio: "BASE"
        },
        {
            localidad: {
                code: "PBA",
                name: "PEÑABLANCA"
            },
            posta: "VINA DEL MAR",
            radio: "BASE"
        },
        {
            localidad: {
                code: "PLP",
                name: "PLACILLA"
            },
            posta: "VINA DEL MAR",
            radio: "BASE"
        },
        {
            localidad: {
                code: "KPB",
                name: "PUNTA BARRANCA"
            },
            posta: "VINA DEL MAR",
            radio: "BASE"
        },
        {
            localidad: {
                code: "KPC",
                name: "PUNTA CURAUMILLA"
            },
            posta: "VINA DEL MAR",
            radio: "BASE"
        },
        {
            localidad: {
                code: "KPT",
                name: "PUNTA DE TRALCA"
            },
            posta: "VINA DEL MAR",
            radio: "BASE"
        },
        {
            localidad: {
                code: "QPE",
                name: "QUILPUE"
            },
            posta: "VINA DEL MAR",
            radio: "BASE"
        },
        {
            localidad: {
                code: "QTY",
                name: "QUINTAY"
            },
            posta: "VINA DEL MAR",
            radio: "BASE"
        },
        {
            localidad: {
                code: "QTO",
                name: "QUINTERO"
            },
            posta: "VINA DEL MAR",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZRV",
                name: "RITOQUE"
            },
            posta: "VINA DEL MAR",
            radio: "BASE"
        },
        {
            localidad: {
                code: "SNT",
                name: "SAN ANTONIO"
            },
            posta: "VINA DEL MAR",
            radio: "BASE"
        },
        {
            localidad: {
                code: "SSB",
                name: "SAN SEBASTIAN"
            },
            posta: "VINA DEL MAR",
            radio: "BASE"
        },
        {
            localidad: {
                code: "SDC",
                name: "SANTO DOMINGO"
            },
            posta: "VINA DEL MAR",
            radio: "BASE"
        },
        {
            localidad: {
                code: "VIA",
                name: "VILLA ALEMANA"
            },
            posta: "VINA DEL MAR",
            radio: "BASE"
        },
        {
            localidad: {
                code: "KEC",
                name: "EL CARPINTERO"
            },
            posta: "VINA DEL MAR",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "KER",
                name: "EL TURCO"
            },
            posta: "VINA DEL MAR",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "KLR",
                name: "LA RETUCA"
            },
            posta: "VINA DEL MAR",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "KLY",
                name: "LEYDA"
            },
            posta: "VINA DEL MAR",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "RPL",
                name: "RAPEL"
            },
            posta: "VINA DEL MAR",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "KSG",
                name: "SAN GERONIMO"
            },
            posta: "VINA DEL MAR",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "KTH",
                name: "TAPIHUE"
            },
            posta: "VINA DEL MAR",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "BUU",
                name: "BUCALEMU"
            },
            posta: "VINA DEL MAR",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "KRC",
                name: "CORNECHE"
            },
            posta: "VINA DEL MAR",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZRW",
                name: "LA BOCA"
            },
            posta: "VINA DEL MAR",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "LAE",
                name: "LA ESTRELLA"
            },
            posta: "VINA DEL MAR",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "KLD",
                name: "LAS DAMAS"
            },
            posta: "VINA DEL MAR",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "LTU",
                name: "LITUECHE"
            },
            posta: "VINA DEL MAR",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "KMT",
                name: "MATANZAS"
            },
            posta: "VINA DEL MAR",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "NAV",
                name: "NAVIDAD"
            },
            posta: "VINA DEL MAR",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "KPU",
                name: "PUERTECILLO"
            },
            posta: "VINA DEL MAR",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "KPP",
                name: "PUNTA PERRO"
            },
            posta: "VINA DEL MAR",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "KPR",
                name: "PUNTA TORO"
            },
            posta: "VINA DEL MAR",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "KSE",
                name: "SAN ENRIQUE"
            },
            posta: "VINA DEL MAR",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "KVP",
                name: "SAN VICENTE DE PUCALAN"
            },
            posta: "VINA DEL MAR",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "KTL",
                name: "TOPOCALMA"
            },
            posta: "VINA DEL MAR",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "AMB",
                name: "AEROPUERTO ARTURO MERINO BENITEZ"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "RRI",
                name: "CERRILLOS"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "CNV",
                name: "CERRO NAVIA"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "CNH",
                name: "CONCHALI"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "EBO",
                name: "EL BOSQUE"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ECE",
                name: "ESTACION CENTRAL"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "HRB",
                name: "HUECHURABA"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "IDP",
                name: "INDEPENDENCIA"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "LCN",
                name: "LA CISTERNA"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "LFD",
                name: "LA FLORIDA"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "LGJ",
                name: "LA GRANJA"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "LPT",
                name: "LA PINTANA"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "LRN",
                name: "LA REINA"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "LCD",
                name: "LAS CONDES"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "SLZ",
                name: "LO ERRAZURIZ"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "LBR",
                name: "LO BARNECHEA"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "LEP",
                name: "LO ESPEJO"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "LPR",
                name: "LO PRADO"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "MAC",
                name: "MACUL"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "MAI",
                name: "MAIPU"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "NNA",
                name: "NUNOA"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "PAC",
                name: "PEDRO AGUIRRE CERDA"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "PNL",
                name: "PENALOLEN"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "PRO",
                name: "PROVIDENCIA"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "PUD",
                name: "PUDAHUEL"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "PAL",
                name: "PUENTE ALTO"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "QLC",
                name: "QUILICURA"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "QTN",
                name: "QUINTA NORMAL"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "RLT",
                name: "RECOLETA"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "REN",
                name: "RENCA"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "SJQ",
                name: "SAN JOAQUIN"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "SMG",
                name: "SAN MIGUEL"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "SRN",
                name: "SAN RAMON"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "SCL",
                name: "SANTIAGO"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "VTC",
                name: "VITACURA"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "SAJ",
                name: "ALTO JAHUEL"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "SBX",
                name: "BATUCO"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "SBR",
                name: "BOLLENAR"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZBU",
                name: "BUIN"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "CDT",
                name: "CALERA DE TANGO"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "CLG",
                name: "CALLE LARGA"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "CAT",
                name: "CATEMU"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "CSP",
                name: "CERRILLOS DE SAN FELIPE"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "CBO",
                name: "CERRO BLANCO"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "SHW",
                name: "CHAGRES"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "CHP",
                name: "CHAMPA"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "CHW",
                name: "CHICUREO"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "AAN",
                name: "CHOCALAN"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "SCM",
                name: "CHOROMBO"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZCD",
                name: "CODIGUA"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "COL",
                name: "COLINA"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "CLY",
                name: "COLLIGUAY"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "CZA",
                name: "CRUCE LAS ARANAS"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "CLP",
                name: "CULIPRAN"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "CVI",
                name: "CURACAVI"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "CRM",
                name: "CURIMON"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "SEC",
                name: "EL CANELO"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "SWC",
                name: "EL CERRADO"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "SOW",
                name: "EL COBRE"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "EME",
                name: "EL MELOCOTON"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZTE",
                name: "EL MONTE"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "EPC",
                name: "EL PAICO"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "STY",
                name: "EL TARTARO"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "TTO",
                name: "EL TRANSITO"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "TRB",
                name: "EL TREBOL"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ESB",
                name: "ESMERALDA"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "SGY",
                name: "GRANALLA"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "HOS",
                name: "HOSPITAL"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "SHU",
                name: "HUELQUEN"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "IDM",
                name: "ISLA DE MAIPO"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "IPQ",
                name: "ISLA DE PIRQUE"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "JHL",
                name: "JAHUEL"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "SLO",
                name: "LA OBRA"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "LSG",
                name: "LAMPA"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "SLN",
                name: "LAS CANTERAS"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "SLM",
                name: "LAS MARIPOSAS"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "SLV",
                name: "LAS VERTIENTES"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "SLD",
                name: "LINDEROS"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "HAC",
                name: "LO CHACON"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "KET",
                name: "LOLENCO"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "LQN",
                name: "LONQUEN"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "LOB",
                name: "LOS ANDES"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "LYR",
                name: "LOS ARRAYANES"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "SLR",
                name: "LOS RULOS"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZXX",
                name: "LOYCA"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "MIP",
                name: "MAIPO"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "MLL",
                name: "MALLOCO"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "SMD",
                name: "MANDINGA"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "MPO",
                name: "MARIA PINTO"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZMP",
                name: "MELIPILLA"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "SMQ",
                name: "MONTENEGRO"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "SNW",
                name: "NILHUE"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "SOX",
                name: "OCOA"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "SPB",
                name: "PABELLON"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "PHT",
                name: "PADRE HURTADO"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZPN",
                name: "PAINE"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "PNQ",
                name: "PANQUEHUE"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "PFL",
                name: "PENAFLOR"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "SPT",
                name: "PINTUE"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "PIR",
                name: "PIRQUE"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "POL",
                name: "POLPAICO"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "IRE",
                name: "POMAIRE"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "SPH",
                name: "PUEBLO HUNDIDO"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "PUT",
                name: "PUTAENDO"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "SQM",
                name: "QUIMCAHUE"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZUU",
                name: "RANGUE"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "SRP",
                name: "RESGUARDO LOS PATOS"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "RDA",
                name: "RINCONADA"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "SRG",
                name: "RINCONADA DE GUZMAN"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "SRS",
                name: "RINCONADA DE SILVA"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "SRB",
                name: "RIO BLANCO"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "SSD",
                name: "SALTO DEL SOLDADO"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "SBD",
                name: "SAN BERNARDO"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "SEN",
                name: "SAN ESTEBAN"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "SFP",
                name: "SAN FELIPE"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "SMN",
                name: "SAN MANUEL"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "SIS",
                name: "SANTA INES"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "SRI",
                name: "SANTA MARIA"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "SMP",
                name: "SANTA MARTA"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "TNT",
                name: "TALAGANTE"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "TJL",
                name: "TERMAS DE JAHUEL"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "TIL",
                name: "TIL TIL"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "SVH",
                name: "VILLA ALHUE"
            },
            posta: "SANTIAGO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ALH",
                name: "ALHUE"
            },
            posta: "SANTIAGO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "SCY",
                name: "CHACAY"
            },
            posta: "SANTIAGO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "SMI",
                name: "EL MANZANILLO"
            },
            posta: "SANTIAGO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "SVV",
                name: "EL VOLCAN"
            },
            posta: "SANTIAGO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "FAR",
                name: "FARELLONES"
            },
            posta: "SANTIAGO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "SYY",
                name: "GUAYACAN"
            },
            posta: "SANTIAGO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "SJH",
                name: "JUNCAL"
            },
            posta: "SANTIAGO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "SLS",
                name: "LAS MELOSAS"
            },
            posta: "SANTIAGO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "STQ",
                name: "LO VALDES"
            },
            posta: "SANTIAGO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZKU",
                name: "LONCHA"
            },
            posta: "SANTIAGO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "SMT",
                name: "LOS MAITENES"
            },
            posta: "SANTIAGO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "XQT",
                name: "LOS QUELTEHUES"
            },
            posta: "SANTIAGO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "PRT",
                name: "PORTILLO"
            },
            posta: "SANTIAGO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "SER",
                name: "RUNGUE"
            },
            posta: "SANTIAGO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "SAF",
                name: "SAN ALFONSO"
            },
            posta: "SANTIAGO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "SGB",
                name: "SAN GABRIEL"
            },
            posta: "SANTIAGO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "SJS",
                name: "SAN JOSE DE MAIPO"
            },
            posta: "SANTIAGO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "SPO",
                name: "SAN PEDRO"
            },
            posta: "SANTIAGO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "TRE",
                name: "TRES FUERTES"
            },
            posta: "SANTIAGO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "SRV",
                name: "VILLA DEL VALLE"
            },
            posta: "SANTIAGO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "MMZ",
                name: "EL MEMBRILLO"
            },
            posta: "SANTIAGO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "SGZ",
                name: "GUARDIA VIEJA"
            },
            posta: "SANTIAGO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "IPC",
                name: "ISLA DE PASCUA"
            },
            posta: "SANTIAGO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "JFZ",
                name: "ISLA JUAN FERNANDEZ"
            },
            posta: "SANTIAGO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "LZU",
                name: "LA CRUZ"
            },
            posta: "SANTIAGO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "SDO",
                name: "SALADILLO"
            },
            posta: "SANTIAGO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "RCG",
                name: "RANCAGUA"
            },
            posta: "RANCAGUA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "CRE",
                name: "CERRILLOS"
            },
            posta: "RANCAGUA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "RLC",
                name: "LA COMPANÍA"
            },
            posta: "RANCAGUA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "LRW",
                name: "LA LEONERA"
            },
            posta: "RANCAGUA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "RLP",
                name: "LA PUNTA"
            },
            posta: "RANCAGUA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "RLS",
                name: "LAS NIEVES"
            },
            posta: "RANCAGUA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "RLM",
                name: "LOS MAQUIS"
            },
            posta: "RANCAGUA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "OAL",
                name: "OLIVAR ALTO"
            },
            posta: "RANCAGUA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ROL",
                name: "OLIVAR BAJO"
            },
            posta: "RANCAGUA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "PHU",
                name: "PANIAHUE"
            },
            posta: "RANCAGUA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "RMC",
                name: "PIMPINELA"
            },
            posta: "RANCAGUA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "RPO",
                name: "POPETA"
            },
            posta: "RANCAGUA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "PRN",
                name: "PUREN VI"
            },
            posta: "RANCAGUA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "AQB",
                name: "AGUA BUENA"
            },
            posta: "RANCAGUA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "CHV",
                name: "CHAPA VERDE"
            },
            posta: "RANCAGUA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "CHB",
                name: "CHIMBARONGO"
            },
            posta: "RANCAGUA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "CCN",
                name: "COCALAN"
            },
            posta: "RANCAGUA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZDE",
                name: "CODEGUA"
            },
            posta: "RANCAGUA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "CNO",
                name: "COINCO"
            },
            posta: "RANCAGUA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "CLN",
                name: "COLON"
            },
            posta: "RANCAGUA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "CTO",
                name: "COLTAUCO"
            },
            posta: "RANCAGUA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "OCY",
                name: "COYA"
            },
            posta: "RANCAGUA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZCU",
                name: "CUNACO"
            },
            posta: "RANCAGUA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "DNE",
                name: "DOÑIHUE"
            },
            posta: "RANCAGUA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "MZN",
                name: "EL MANZANO"
            },
            posta: "RANCAGUA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "RET",
                name: "EL TOCO"
            },
            posta: "RANCAGUA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "GRA",
                name: "GRANEROS"
            },
            posta: "RANCAGUA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "HRQ",
                name: "HACIENDA LOS LINGUES"
            },
            posta: "RANCAGUA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "RLA",
                name: "LA CEBADA"
            },
            posta: "RANCAGUA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "LQX",
                name: "LARMAHUE"
            },
            posta: "RANCAGUA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "LCB",
                name: "LAS CABRAS"
            },
            posta: "RANCAGUA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "LHX",
                name: "LIHUEIMO"
            },
            posta: "RANCAGUA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "LMI",
                name: "LO MIRANDA"
            },
            posta: "RANCAGUA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "MCH",
                name: "MACHALI"
            },
            posta: "RANCAGUA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZML",
                name: "MALLOA"
            },
            posta: "RANCAGUA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "MLX",
                name: "MILLAHUE"
            },
            posta: "RANCAGUA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "MJT",
                name: "MINA LA JUANITA"
            },
            posta: "RANCAGUA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "NGA",
                name: "NANCAGUA"
            },
            posta: "RANCAGUA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "PLA",
                name: "PALMILLA"
            },
            posta: "RANCAGUA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "PEQ",
                name: "PELEQUEN"
            },
            posta: "RANCAGUA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZPE",
                name: "PERALILLO"
            },
            posta: "RANCAGUA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "PEO",
                name: "PEUMO"
            },
            posta: "RANCAGUA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "PHA",
                name: "PICHIDEGUA"
            },
            posta: "RANCAGUA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "PMU",
                name: "PICHILEMU"
            },
            posta: "RANCAGUA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "PLL",
                name: "PLACILLA"
            },
            posta: "RANCAGUA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "RPT",
                name: "PUENTE NEGRO"
            },
            posta: "RANCAGUA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "RPN",
                name: "PUNTA VERDE"
            },
            posta: "RANCAGUA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "QCC",
                name: "QUINTA DE TILCOCO"
            },
            posta: "RANCAGUA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZRG",
                name: "RENGO"
            },
            posta: "RANCAGUA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "REQ",
                name: "REQUINOA"
            },
            posta: "RANCAGUA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "RQY",
                name: "RINCONADA DE YAQUIL"
            },
            posta: "RANCAGUA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "RMY",
                name: "ROMA"
            },
            posta: "RANCAGUA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "RSS",
                name: "ROSARIO"
            },
            posta: "RANCAGUA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "SFM",
                name: "SAN FCO DE MOSTAZAL"
            },
            posta: "RANCAGUA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "SFR",
                name: "SAN FERNANDO"
            },
            posta: "RANCAGUA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "RSA",
                name: "SAN JOSE DE MARCHIHUE"
            },
            posta: "RANCAGUA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "SVT",
                name: "SAN VICENTE DE T.T"
            },
            posta: "RANCAGUA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZSC",
                name: "SANTA CRUZ"
            },
            posta: "RANCAGUA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "RTE",
                name: "TERMAS DE CAUQUENES"
            },
            posta: "RANCAGUA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "TZT",
                name: "TROMPETILLA"
            },
            posta: "RANCAGUA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "TZV",
                name: "TUNCA ARRIBA"
            },
            posta: "RANCAGUA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "RZU",
                name: "ZUNIGA"
            },
            posta: "RANCAGUA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "LNE",
                name: "ALCONES"
            },
            posta: "RANCAGUA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "RAC",
                name: "ALTO COLORADO"
            },
            posta: "RANCAGUA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "BYY",
                name: "BOYERUCA"
            },
            posta: "RANCAGUA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "PRM",
                name: "BUCALEMU"
            },
            posta: "RANCAGUA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "RHL",
                name: "CAHUIL"
            },
            posta: "RANCAGUA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "CTS",
                name: "CALETONES"
            },
            posta: "RANCAGUA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "RJC",
                name: "CIRUELOS"
            },
            posta: "RANCAGUA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "CAR",
                name: "EL CARMEN"
            },
            posta: "RANCAGUA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "EGQ",
                name: "EL GUAICO"
            },
            posta: "RANCAGUA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "RPS",
                name: "EL PUESTO"
            },
            posta: "RANCAGUA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "RZE",
                name: "ESPERANZA"
            },
            posta: "RANCAGUA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "LLW",
                name: "LA LAJUELA"
            },
            posta: "RANCAGUA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "RQE",
                name: "LA QUEBRADA"
            },
            posta: "RANCAGUA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "LRF",
                name: "LA RUFINA"
            },
            posta: "RANCAGUA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "LPG",
                name: "LAS PATAGUAS"
            },
            posta: "RANCAGUA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "LVQ",
                name: "LO VALDIVIA"
            },
            posta: "RANCAGUA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "LOL",
                name: "LOLOL"
            },
            posta: "RANCAGUA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "RMT",
                name: "MARCHANT"
            },
            posta: "RANCAGUA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "MRH",
                name: "MARCHIHUE"
            },
            posta: "RANCAGUA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "RNH",
                name: "NERQUIHUE"
            },
            posta: "RANCAGUA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "RNI",
                name: "NILAHUE"
            },
            posta: "RANCAGUA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "PDS",
                name: "PAREDONES"
            },
            posta: "RANCAGUA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "RQT",
                name: "POBLACION"
            },
            posta: "RANCAGUA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "PMQ",
                name: "PUMANQUE"
            },
            posta: "RANCAGUA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "TPC",
                name: "PUNTA DE CORTES"
            },
            posta: "RANCAGUA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "RGQ",
                name: "SANTA GRACIELA DE ALCONES"
            },
            posta: "RANCAGUA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "SEW",
                name: "SEWELL"
            },
            posta: "RANCAGUA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZSB",
                name: "SIERRA BELLAVISTA"
            },
            posta: "RANCAGUA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "TZF",
                name: "TERMAS DEL FLACO"
            },
            posta: "RANCAGUA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "TGR",
                name: "TINGUIRIRICA"
            },
            posta: "RANCAGUA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZCA",
                name: "TALCA"
            },
            posta: "TALCA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZAS",
                name: "ASTILLERO"
            },
            posta: "TALCA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZAU",
                name: "AURORA"
            },
            posta: "TALCA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZBA",
                name: "BATUCO"
            },
            posta: "TALCA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "BOB",
                name: "BOBADILLA"
            },
            posta: "TALCA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZBO",
                name: "BOTALCURA"
            },
            posta: "TALCA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "CQE",
                name: "CAUQUENES"
            },
            posta: "TALCA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZCP",
                name: "COIPUE"
            },
            posta: "TALCA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "CLB",
                name: "COLBUN"
            },
            posta: "TALCA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZCL",
                name: "COLIN"
            },
            posta: "TALCA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "CTT",
                name: "CONSTITUCION"
            },
            posta: "TALCA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZNM",
                name: "CORINTO"
            },
            posta: "TALCA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZCS",
                name: "CORRALONES"
            },
            posta: "TALCA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "CUM",
                name: "CUMPEO"
            },
            posta: "TALCA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "CCO",
                name: "CURICO"
            },
            posta: "TALCA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZCE",
                name: "CURTIDURIA"
            },
            posta: "TALCA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZEL",
                name: "EL COLORADO"
            },
            posta: "TALCA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZGU",
                name: "GUALLECO"
            },
            posta: "TALCA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZIK",
                name: "ITAHUE"
            },
            posta: "TALCA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "LNR",
                name: "LINARES"
            },
            posta: "TALCA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "CUT",
                name: "LITU"
            },
            posta: "TALCA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "LGV",
                name: "LONGAVI"
            },
            posta: "TALCA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "LTE",
                name: "LONTUE"
            },
            posta: "TALCA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "LNH",
                name: "LOS NICHES"
            },
            posta: "TALCA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZMA",
                name: "MAULE"
            },
            posta: "TALCA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZMO",
                name: "MOLINA"
            },
            posta: "TALCA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "PNV",
                name: "PANIMAVIDA"
            },
            posta: "TALCA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "PRR",
                name: "PARRAL"
            },
            posta: "TALCA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "PLC",
                name: "PELARCO"
            },
            posta: "TALCA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "PEH",
                name: "PENCAHUE"
            },
            posta: "TALCA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "RAU",
                name: "RAUCO"
            },
            posta: "TALCA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "RCL",
                name: "RIO CLARO"
            },
            posta: "TALCA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "RML",
                name: "ROMERAL"
            },
            posta: "TALCA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "SFA",
                name: "SAGRADA FAMILIA"
            },
            posta: "TALCA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "STE",
                name: "SAN CLEMENTE"
            },
            posta: "TALCA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "SJA",
                name: "SAN JAVIER"
            },
            posta: "TALCA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "SRF",
                name: "SAN RAFAEL"
            },
            posta: "TALCA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZSN",
                name: "SANTA ANA"
            },
            posta: "TALCA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "TEN",
                name: "TENO"
            },
            posta: "TALCA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "VGE",
                name: "VILLA ALEGRE"
            },
            posta: "TALCA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "YBB",
                name: "YERBAS BUENAS"
            },
            posta: "TALCA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "JJZ",
                name: "AJIAL"
            },
            posta: "TALCA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ARB",
                name: "ARBOLILLO"
            },
            posta: "TALCA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "CHE",
                name: "CHEPICA"
            },
            posta: "TALCA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "CVQ",
                name: "CONVENTO VIEJO"
            },
            posta: "TALCA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "PXQ",
                name: "EL PENON"
            },
            posta: "TALCA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "LMN",
                name: "LA MONTANA"
            },
            posta: "TALCA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZLJ",
                name: "LAS CAMPANAS"
            },
            posta: "TALCA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "XMD",
                name: "MELOZAL"
            },
            posta: "TALCA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZNI",
                name: "NIRIVILO"
            },
            posta: "TALCA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "XPE",
                name: "PALMILLA"
            },
            posta: "TALCA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "XPS",
                name: "PASO NEVADO"
            },
            posta: "TALCA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "PQQ",
                name: "PERQUILAUQUEN"
            },
            posta: "TALCA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "QQW",
                name: "QUINAHUE"
            },
            posta: "TALCA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "QPX",
                name: "QUINTA"
            },
            posta: "TALCA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "RTR",
                name: "RETIRO"
            },
            posta: "TALCA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ADW",
                name: "ADUANA"
            },
            posta: "TALCA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ADJ",
                name: "ADUANA PEJERREY"
            },
            posta: "TALCA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "BWP",
                name: "ARMERILLO"
            },
            posta: "TALCA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "AQQ",
                name: "AUQUINCO"
            },
            posta: "TALCA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "BYQ",
                name: "BARRERA"
            },
            posta: "TALCA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "LLZ",
                name: "BULLILLEO"
            },
            posta: "TALCA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "CPQ",
                name: "CAMPAMENTO ANCOA"
            },
            posta: "TALCA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "CNC",
                name: "CHANCO"
            },
            posta: "TALCA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "XQP",
                name: "CULENAR"
            },
            posta: "TALCA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "CNP",
                name: "CURANIPE"
            },
            posta: "TALCA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "CUR",
                name: "CUREPTO"
            },
            posta: "TALCA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "UAO",
                name: "DUAO"
            },
            posta: "TALCA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "XMM",
                name: "EL MANZANO"
            },
            posta: "TALCA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "XPO",
                name: "EL PLANCHON"
            },
            posta: "TALCA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "XSE",
                name: "EL SALTO"
            },
            posta: "TALCA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "XTD",
                name: "EL TRANSITO"
            },
            posta: "TALCA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "EMP",
                name: "EMPEDRADO"
            },
            posta: "TALCA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZED",
                name: "ENDESA"
            },
            posta: "TALCA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "HNE",
                name: "HUALANE"
            },
            posta: "TALCA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "XHR",
                name: "HUALVE"
            },
            posta: "TALCA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "HXO",
                name: "HUEMUL"
            },
            posta: "TALCA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ILO",
                name: "ILOCA"
            },
            posta: "TALCA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZJU",
                name: "JUNQUILLAR"
            },
            posta: "TALCA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "XLA",
                name: "LA LORA"
            },
            posta: "TALCA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZLM",
                name: "LA MINA"
            },
            posta: "TALCA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZLT",
                name: "LA TRINCHERA"
            },
            posta: "TALCA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZLG",
                name: "LAS GARZAS"
            },
            posta: "TALCA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "LCT",
                name: "LICANTEN"
            },
            posta: "TALCA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZVU",
                name: "LIPIMAVIDA"
            },
            posta: "TALCA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "XBL",
                name: "LLEPO"
            },
            posta: "TALCA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZYC",
                name: "LLICO"
            },
            posta: "TALCA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "XLY",
                name: "LOS CRISTALES"
            },
            posta: "TALCA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "XNY",
                name: "LOS NABOS"
            },
            posta: "TALCA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZHH",
                name: "LOS PERALES"
            },
            posta: "TALCA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZQX",
                name: "LOS QUENES"
            },
            posta: "TALCA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "XRX",
                name: "LOS RABONES"
            },
            posta: "TALCA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "XMK",
                name: "MELADO"
            },
            posta: "TALCA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZMG",
                name: "MELAO"
            },
            posta: "TALCA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "XME",
                name: "MESAMAVIDA"
            },
            posta: "TALCA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZMI",
                name: "MIRAFLORES"
            },
            posta: "TALCA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZPA",
                name: "MONTE OSCURO"
            },
            posta: "TALCA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "MOX",
                name: "MORZA"
            },
            posta: "TALCA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "XQB",
                name: "PALQUIBUDA"
            },
            posta: "TALCA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "XEI",
                name: "PASO HONDO"
            },
            posta: "TALCA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "PEL",
                name: "PELLUHUE"
            },
            posta: "TALCA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZKQ",
                name: "PICHAMAN"
            },
            posta: "TALCA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "BCH",
                name: "PICHIBUDI"
            },
            posta: "TALCA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "PTG",
                name: "POTRERO GRANDE"
            },
            posta: "TALCA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "XPC",
                name: "POTRERO GRANDE CHICO"
            },
            posta: "TALCA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "PUU",
                name: "PUTU"
            },
            posta: "TALCA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZQH",
                name: "QUEBRADA HONDA"
            },
            posta: "TALCA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "XQL",
                name: "QUELLA"
            },
            posta: "TALCA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "QQH",
                name: "QUINCHIMAVIDA"
            },
            posta: "TALCA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZQV",
                name: "QUINMAVIDA"
            },
            posta: "TALCA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZQI",
                name: "QUIVOLGO"
            },
            posta: "TALCA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "XXR",
                name: "RADAL"
            },
            posta: "TALCA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "RQQ",
                name: "RANGUIL"
            },
            posta: "TALCA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZRB",
                name: "REBECA"
            },
            posta: "TALCA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "XRI",
                name: "ROBLERIA"
            },
            posta: "TALCA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "SZS",
                name: "SAN PABLO"
            },
            posta: "TALCA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "SPW",
                name: "SAN PEDRO DE ALCANTARA"
            },
            posta: "TALCA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "TQC",
                name: "TERMAS DE CATILLO"
            },
            posta: "TALCA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "SAR",
                name: "TRINCAO"
            },
            posta: "TALCA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZUV",
                name: "UNICAVEN"
            },
            posta: "TALCA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZUO",
                name: "UPEO"
            },
            posta: "TALCA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "VCH",
                name: "VICHUQUEN"
            },
            posta: "TALCA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZVO",
                name: "VILLA PRAT"
            },
            posta: "TALCA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "VZQ",
                name: "VILLA ROSAS"
            },
            posta: "TALCA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZVS",
                name: "VILLA SECA"
            },
            posta: "TALCA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "XYY",
                name: "YACEL"
            },
            posta: "TALCA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "LSQ",
                name: "LOS ANGELES"
            },
            posta: "LOS ANGELES",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZOL",
                name: "ANGOL"
            },
            posta: "LOS ANGELES",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ANT",
                name: "ANTUCO"
            },
            posta: "LOS ANGELES",
            radio: "BASE"
        },
        {
            localidad: {
                code: "CRO",
                name: "CABRERO"
            },
            posta: "LOS ANGELES",
            radio: "BASE"
        },
        {
            localidad: {
                code: "CRA",
                name: "CANICURA"
            },
            posta: "LOS ANGELES",
            radio: "BASE"
        },
        {
            localidad: {
                code: "CNT",
                name: "CANTERAS"
            },
            posta: "LOS ANGELES",
            radio: "BASE"
        },
        {
            localidad: {
                code: "CIO",
                name: "CHOROICO"
            },
            posta: "LOS ANGELES",
            radio: "BASE"
        },
        {
            localidad: {
                code: "LCE",
                name: "COIHUE"
            },
            posta: "LOS ANGELES",
            radio: "BASE"
        },
        {
            localidad: {
                code: "CPI",
                name: "COLLIPULLI"
            },
            posta: "LOS ANGELES",
            radio: "BASE"
        },
        {
            localidad: {
                code: "LCI",
                name: "COLONIA"
            },
            posta: "LOS ANGELES",
            radio: "BASE"
        },
        {
            localidad: {
                code: "LDQ",
                name: "DIUQUIN"
            },
            posta: "LOS ANGELES",
            radio: "BASE"
        },
        {
            localidad: {
                code: "LEL",
                name: "EL ALAMO"
            },
            posta: "LOS ANGELES",
            radio: "BASE"
        },
        {
            localidad: {
                code: "LEV",
                name: "EL AVELLANO"
            },
            posta: "LOS ANGELES",
            radio: "BASE"
        },
        {
            localidad: {
                code: "LGH",
                name: "EL GUACHI"
            },
            posta: "LOS ANGELES",
            radio: "BASE"
        },
        {
            localidad: {
                code: "LMR",
                name: "EL MORRO"
            },
            posta: "LOS ANGELES",
            radio: "BASE"
        },
        {
            localidad: {
                code: "EYY",
                name: "ESTACION YUMBEL"
            },
            posta: "LOS ANGELES",
            radio: "BASE"
        },
        {
            localidad: {
                code: "HUP",
                name: "HUEPIL"
            },
            posta: "LOS ANGELES",
            radio: "BASE"
        },
        {
            localidad: {
                code: "LLJ",
                name: "LAJA"
            },
            posta: "LOS ANGELES",
            radio: "BASE"
        },
        {
            localidad: {
                code: "LNV",
                name: "LAS NIEVES"
            },
            posta: "LOS ANGELES",
            radio: "BASE"
        },
        {
            localidad: {
                code: "LGE",
                name: "LONCOPANGUE"
            },
            posta: "LOS ANGELES",
            radio: "BASE"
        },
        {
            localidad: {
                code: "LBS",
                name: "LOS BRUJOS"
            },
            posta: "LOS ANGELES",
            radio: "BASE"
        },
        {
            localidad: {
                code: "LZX",
                name: "LOS MAICAS"
            },
            posta: "LOS ANGELES",
            radio: "BASE"
        },
        {
            localidad: {
                code: "LLH",
                name: "MAITENREHUE"
            },
            posta: "LOS ANGELES",
            radio: "BASE"
        },
        {
            localidad: {
                code: "MEA",
                name: "MELICA"
            },
            posta: "LOS ANGELES",
            radio: "BASE"
        },
        {
            localidad: {
                code: "MNC",
                name: "MININCO"
            },
            posta: "LOS ANGELES",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZLA",
                name: "MONTE AGUILA"
            },
            posta: "LOS ANGELES",
            radio: "BASE"
        },
        {
            localidad: {
                code: "MUL",
                name: "MULCHEN"
            },
            posta: "LOS ANGELES",
            radio: "BASE"
        },
        {
            localidad: {
                code: "NAC",
                name: "NACIMIENTO"
            },
            posta: "LOS ANGELES",
            radio: "BASE"
        },
        {
            localidad: {
                code: "NRE",
                name: "NEGRETE"
            },
            posta: "LOS ANGELES",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZPP",
                name: "PANGAL"
            },
            posta: "LOS ANGELES",
            radio: "BASE"
        },
        {
            localidad: {
                code: "PHY",
                name: "PASO HONDO"
            },
            posta: "LOS ANGELES",
            radio: "BASE"
        },
        {
            localidad: {
                code: "PGS",
                name: "PROGRESO"
            },
            posta: "LOS ANGELES",
            radio: "BASE"
        },
        {
            localidad: {
                code: "QCO",
                name: "QUILACO"
            },
            posta: "LOS ANGELES",
            radio: "BASE"
        },
        {
            localidad: {
                code: "QLO",
                name: "QUILLECO"
            },
            posta: "LOS ANGELES",
            radio: "BASE"
        },
        {
            localidad: {
                code: "RNA",
                name: "RENAICO"
            },
            posta: "LOS ANGELES",
            radio: "BASE"
        },
        {
            localidad: {
                code: "RIE",
                name: "RIHUE"
            },
            posta: "LOS ANGELES",
            radio: "BASE"
        },
        {
            localidad: {
                code: "LRC",
                name: "RIO CLARO"
            },
            posta: "LOS ANGELES",
            radio: "BASE"
        },
        {
            localidad: {
                code: "LRH",
                name: "RUCALHUE"
            },
            posta: "LOS ANGELES",
            radio: "BASE"
        },
        {
            localidad: {
                code: "SCW",
                name: "SAN CARLOS PUREN"
            },
            posta: "LOS ANGELES",
            radio: "BASE"
        },
        {
            localidad: {
                code: "LSM",
                name: "SAN MIGUEL"
            },
            posta: "LOS ANGELES",
            radio: "BASE"
        },
        {
            localidad: {
                code: "SRO",
                name: "SAN ROSENDO"
            },
            posta: "LOS ANGELES",
            radio: "BASE"
        },
        {
            localidad: {
                code: "SBB",
                name: "SANTA BARBARA"
            },
            posta: "LOS ANGELES",
            radio: "BASE"
        },
        {
            localidad: {
                code: "LSF",
                name: "SANTA FE"
            },
            posta: "LOS ANGELES",
            radio: "BASE"
        },
        {
            localidad: {
                code: "LTJ",
                name: "TIJERAL"
            },
            posta: "LOS ANGELES",
            radio: "BASE"
        },
        {
            localidad: {
                code: "LTP",
                name: "TRUPAN"
            },
            posta: "LOS ANGELES",
            radio: "BASE"
        },
        {
            localidad: {
                code: "TCP",
                name: "TUCAPEL"
            },
            posta: "LOS ANGELES",
            radio: "BASE"
        },
        {
            localidad: {
                code: "LVM",
                name: "VILLA MERCEDES"
            },
            posta: "LOS ANGELES",
            radio: "BASE"
        },
        {
            localidad: {
                code: "LVU",
                name: "VILLUCURA"
            },
            posta: "LOS ANGELES",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZYU",
                name: "YUMBEL"
            },
            posta: "LOS ANGELES",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ANI",
                name: "ALTO CALEDONIA"
            },
            posta: "LOS ANGELES",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "LBQ",
                name: "BUENURAQUI"
            },
            posta: "LOS ANGELES",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "CDA",
                name: "CANADA"
            },
            posta: "LOS ANGELES",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "LCP",
                name: "CERRO DEL PADRE"
            },
            posta: "LOS ANGELES",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "HDF",
                name: "CURACO"
            },
            posta: "LOS ANGELES",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "LEA",
                name: "EL ABANICO"
            },
            posta: "LOS ANGELES",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "LTW",
                name: "EL TORO"
            },
            posta: "LOS ANGELES",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "COC",
                name: "LOLCO"
            },
            posta: "LOS ANGELES",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "LLE",
                name: "LOLENCO"
            },
            posta: "LOS ANGELES",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "LBO",
                name: "LOS PLACERES"
            },
            posta: "LOS ANGELES",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "LYY",
                name: "NANCO"
            },
            posta: "LOS ANGELES",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "PDA",
                name: "PIEDRA DEL AGUILA"
            },
            posta: "LOS ANGELES",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "RCO",
                name: "RALCO"
            },
            posta: "LOS ANGELES",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "LVB",
                name: "VEGAS BLANCAS"
            },
            posta: "LOS ANGELES",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "AOO",
                name: "ALTO BIO BIO"
            },
            posta: "LOS ANGELES",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZOT",
                name: "CASA LOLCO"
            },
            posta: "LOS ANGELES",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZRI",
                name: "COMUNIDAD CANICU"
            },
            posta: "LOS ANGELES",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "EAG",
                name: "EL AMARGO"
            },
            posta: "LOS ANGELES",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "LLB",
                name: "LOS BARROS"
            },
            posta: "LOS ANGELES",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "LLM",
                name: "MAITENES"
            },
            posta: "LOS ANGELES",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZLL",
                name: "PANGUE"
            },
            posta: "LOS ANGELES",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "LPO",
                name: "POLCURA"
            },
            posta: "LOS ANGELES",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZLN",
                name: "RALCO LEPOY"
            },
            posta: "LOS ANGELES",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "LST",
                name: "SANTA CLARA"
            },
            posta: "LOS ANGELES",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "MTW",
                name: "TERMAS DE PEMEHUE"
            },
            posta: "LOS ANGELES",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZTV",
                name: "TERMAS DEL AVELLANO"
            },
            posta: "LOS ANGELES",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "TTE",
                name: "TRINTRE"
            },
            posta: "LOS ANGELES",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "YAI",
                name: "CHILLAN"
            },
            posta: "CHILLAN",
            radio: "BASE"
        },
        {
            localidad: {
                code: "YAV",
                name: "CHILLAN VIEJO"
            },
            posta: "CHILLAN",
            radio: "BASE"
        },
        {
            localidad: {
                code: "YCA",
                name: "CACHAPOAL"
            },
            posta: "CHILLAN",
            radio: "BASE"
        },
        {
            localidad: {
                code: "YCO",
                name: "COIPIN"
            },
            posta: "CHILLAN",
            radio: "BASE"
        },
        {
            localidad: {
                code: "YCX",
                name: "EL CARMEN"
            },
            posta: "CHILLAN",
            radio: "BASE"
        },
        {
            localidad: {
                code: "YES",
                name: "EL SAUCE"
            },
            posta: "CHILLAN",
            radio: "BASE"
        },
        {
            localidad: {
                code: "YGE",
                name: "GENERAL CRUZ"
            },
            posta: "CHILLAN",
            radio: "BASE"
        },
        {
            localidad: {
                code: "LQP",
                name: "LA CAPILLA"
            },
            posta: "CHILLAN",
            radio: "BASE"
        },
        {
            localidad: {
                code: "YLS",
                name: "LAS TRANCAS"
            },
            posta: "CHILLAN",
            radio: "BASE"
        },
        {
            localidad: {
                code: "YCU",
                name: "LIUCURA"
            },
            posta: "CHILLAN",
            radio: "BASE"
        },
        {
            localidad: {
                code: "MPD",
                name: "MINAS DEL PRADO"
            },
            posta: "CHILLAN",
            radio: "BASE"
        },
        {
            localidad: {
                code: "YNA",
                name: "NAHUELTORO"
            },
            posta: "CHILLAN",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZNY",
                name: "NIPAS"
            },
            posta: "CHILLAN",
            radio: "BASE"
        },
        {
            localidad: {
                code: "NYY",
                name: "NIQUEN"
            },
            posta: "CHILLAN",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZNA",
                name: "NUEVA ALDEA"
            },
            posta: "CHILLAN",
            radio: "BASE"
        },
        {
            localidad: {
                code: "YPO",
                name: "POCILLAS"
            },
            posta: "CHILLAN",
            radio: "BASE"
        },
        {
            localidad: {
                code: "YPU",
                name: "PUEBLO SECO"
            },
            posta: "CHILLAN",
            radio: "BASE"
        },
        {
            localidad: {
                code: "QML",
                name: "QUINCHAMALI"
            },
            posta: "CHILLAN",
            radio: "BASE"
        },
        {
            localidad: {
                code: "QNA",
                name: "QUIRIQUINA"
            },
            posta: "CHILLAN",
            radio: "BASE"
        },
        {
            localidad: {
                code: "RNQ",
                name: "RANQUIL"
            },
            posta: "CHILLAN",
            radio: "BASE"
        },
        {
            localidad: {
                code: "YRU",
                name: "RUCAPEQUEN"
            },
            posta: "CHILLAN",
            radio: "BASE"
        },
        {
            localidad: {
                code: "YSN",
                name: "SAN GREGORIO NIQUEN"
            },
            posta: "CHILLAN",
            radio: "BASE"
        },
        {
            localidad: {
                code: "SMW",
                name: "SAN MIGUEL"
            },
            posta: "CHILLAN",
            radio: "BASE"
        },
        {
            localidad: {
                code: "SQZ",
                name: "SAN PEDRO"
            },
            posta: "CHILLAN",
            radio: "BASE"
        },
        {
            localidad: {
                code: "CLA",
                name: "SANTA CLARA"
            },
            posta: "CHILLAN",
            radio: "BASE"
        },
        {
            localidad: {
                code: "YTA",
                name: "TANILVORO"
            },
            posta: "CHILLAN",
            radio: "BASE"
        },
        {
            localidad: {
                code: "YTR",
                name: "TORRECILLA"
            },
            posta: "CHILLAN",
            radio: "BASE"
        },
        {
            localidad: {
                code: "YZE",
                name: "ZEMITA"
            },
            posta: "CHILLAN",
            radio: "BASE"
        },
        {
            localidad: {
                code: "YCM",
                name: "CAMPANARIO"
            },
            posta: "CHILLAN",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "YCN",
                name: "CANCHA ALEGRE"
            },
            posta: "CHILLAN",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "YCH",
                name: "CHOLGUAN"
            },
            posta: "CHILLAN",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "CQU",
                name: "COBQUECURA"
            },
            posta: "CHILLAN",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "YCF",
                name: "CONFLUENCIA"
            },
            posta: "CHILLAN",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "YEL",
                name: "EL PARRON"
            },
            posta: "CHILLAN",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "YEY",
                name: "EL SALTILLO"
            },
            posta: "CHILLAN",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "FRQ",
                name: "FUNDO LOS ROBLES"
            },
            posta: "CHILLAN",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "YLA",
                name: "LA PUNTILLA"
            },
            posta: "CHILLAN",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "YLO",
                name: "LOS CASTANOS"
            },
            posta: "CHILLAN",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "YLP",
                name: "LOS PUQUIOS"
            },
            posta: "CHILLAN",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "YCR",
                name: "PASO HONDO"
            },
            posta: "CHILLAN",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "YRE",
                name: "RECINTO"
            },
            posta: "CHILLAN",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "SFB",
                name: "SAN FABIAN DE ALICO"
            },
            posta: "CHILLAN",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "YTE",
                name: "TERMAS DE CHILLAN"
            },
            posta: "CHILLAN",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "TMK",
                name: "TOMECO"
            },
            posta: "CHILLAN",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "YTU",
                name: "TREGUALEMU"
            },
            posta: "CHILLAN",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "YGY",
                name: "YUNGAY"
            },
            posta: "CHILLAN",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "YBU",
                name: "BUCHUPUREO"
            },
            posta: "CHILLAN",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "BLN",
                name: "BULNES"
            },
            posta: "CHILLAN",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "CUH",
                name: "COIHUECO"
            },
            posta: "CHILLAN",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "YCL",
                name: "COLMUYAO"
            },
            posta: "CHILLAN",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "YME",
                name: "MELA"
            },
            posta: "CHILLAN",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "NIN",
                name: "NINHUE"
            },
            posta: "CHILLAN",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZPC",
                name: "PEMUCO"
            },
            posta: "CHILLAN",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "PNO",
                name: "PINTO"
            },
            posta: "CHILLAN",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "49H",
                name: "PORTEZUELO"
            },
            posta: "CHILLAN",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "YPL",
                name: "PULLAY"
            },
            posta: "CHILLAN",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "QLL",
                name: "QUILLON"
            },
            posta: "CHILLAN",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "QIH",
                name: "QUIRIHUE"
            },
            posta: "CHILLAN",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "SCS",
                name: "SAN CARLOS"
            },
            posta: "CHILLAN",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "SIG",
                name: "SAN IGNACIO"
            },
            posta: "CHILLAN",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "SNL",
                name: "SAN NICOLAS"
            },
            posta: "CHILLAN",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZAY",
                name: "ZAPALLAR"
            },
            posta: "CHILLAN",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "CCP",
                name: "CONCEPCION"
            },
            posta: "CONCEPCION",
            radio: "BASE"
        },
        {
            localidad: {
                code: "RTH",
                name: "RANCHO TALCAHUANO"
            },
            posta: "CONCEPCION",
            radio: "BASE"
        },
        {
            localidad: {
                code: "SPP",
                name: "SAN PEDRO DE LA PAZ"
            },
            posta: "CONCEPCION",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZTO",
                name: "TALCAHUANO"
            },
            posta: "CONCEPCION",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZGG",
                name: "AGUAS DE LA GLORIA"
            },
            posta: "CONCEPCION",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ARA",
                name: "ARAUCO"
            },
            posta: "CONCEPCION",
            radio: "BASE"
        },
        {
            localidad: {
                code: "PAN",
                name: "CARAMPANGUE"
            },
            posta: "CONCEPCION",
            radio: "BASE"
        },
        {
            localidad: {
                code: "CYE",
                name: "CHIGUAYANTE"
            },
            posta: "CONCEPCION",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZOU",
                name: "COELEMU"
            },
            posta: "CONCEPCION",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZUM",
                name: "COPIULEMU"
            },
            posta: "CONCEPCION",
            radio: "BASE"
        },
        {
            localidad: {
                code: "CRN",
                name: "CORONEL"
            },
            posta: "CONCEPCION",
            radio: "BASE"
        },
        {
            localidad: {
                code: "DTO",
                name: "DICHATO"
            },
            posta: "CONCEPCION",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZBC",
                name: "EL BOLDO"
            },
            posta: "CONCEPCION",
            radio: "BASE"
        },
        {
            localidad: {
                code: "HLP",
                name: "HUALPEN"
            },
            posta: "CONCEPCION",
            radio: "BASE"
        },
        {
            localidad: {
                code: "HLQ",
                name: "HUALQUI"
            },
            posta: "CONCEPCION",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZBB",
                name: "LA BOCA"
            },
            posta: "CONCEPCION",
            radio: "BASE"
        },
        {
            localidad: {
                code: "LAQ",
                name: "LARAQUETE"
            },
            posta: "CONCEPCION",
            radio: "BASE"
        },
        {
            localidad: {
                code: "LIR",
                name: "LIRQUEN"
            },
            posta: "CONCEPCION",
            radio: "BASE"
        },
        {
            localidad: {
                code: "LOT",
                name: "LOTA"
            },
            posta: "CONCEPCION",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZQW",
                name: "MENQUE"
            },
            posta: "CONCEPCION",
            radio: "BASE"
        },
        {
            localidad: {
                code: "PCO",
                name: "PENCO"
            },
            posta: "CONCEPCION",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZIQ",
                name: "QUILACOYA"
            },
            posta: "CONCEPCION",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZRA",
                name: "RAFAEL"
            },
            posta: "CONCEPCION",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZMD",
                name: "RAMADILLA"
            },
            posta: "CONCEPCION",
            radio: "BASE"
        },
        {
            localidad: {
                code: "GUO",
                name: "RANGUELMO"
            },
            posta: "CONCEPCION",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZUQ",
                name: "RAQUI"
            },
            posta: "CONCEPCION",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZER",
                name: "RAQUIL"
            },
            posta: "CONCEPCION",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZRO",
                name: "ROA"
            },
            posta: "CONCEPCION",
            radio: "BASE"
        },
        {
            localidad: {
                code: "SVC",
                name: "SAN VICENTE"
            },
            posta: "CONCEPCION",
            radio: "BASE"
        },
        {
            localidad: {
                code: "CTV",
                name: "TALCAMAVIDA"
            },
            posta: "CONCEPCION",
            radio: "BASE"
        },
        {
            localidad: {
                code: "TMC",
                name: "TOME"
            },
            posta: "CONCEPCION",
            radio: "BASE"
        },
        {
            localidad: {
                code: "VAG",
                name: "VILLA ALEGRE"
            },
            posta: "CONCEPCION",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZNT",
                name: "ANTIGUALA"
            },
            posta: "CONCEPCION",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "CTE",
                name: "CANETE"
            },
            posta: "CONCEPCION",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZIR",
                name: "CONAIR"
            },
            posta: "CONCEPCION",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "CTU",
                name: "CONTULMO"
            },
            posta: "CONCEPCION",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZHE",
                name: "CURANILAHUE"
            },
            posta: "CONCEPCION",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "FLO",
                name: "FLORIDA"
            },
            posta: "CONCEPCION",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "IQR",
                name: "ISLA QUIRIQUINA"
            },
            posta: "CONCEPCION",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZLB",
                name: "LEBU"
            },
            posta: "CONCEPCION",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZCF",
                name: "LLICO"
            },
            posta: "CONCEPCION",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "LAL",
                name: "LOS ALAMOS"
            },
            posta: "CONCEPCION",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZWP",
                name: "PILPILCO"
            },
            posta: "CONCEPCION",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZCG",
                name: "SAN IGNACIO"
            },
            posta: "CONCEPCION",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "SJL",
                name: "SAN JOSE DE COLICO"
            },
            posta: "CONCEPCION",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "SJN",
                name: "SANTA JUANA"
            },
            posta: "CONCEPCION",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZTT",
                name: "VEGAS DE ITATA"
            },
            posta: "CONCEPCION",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZZW",
                name: "CAYUCUPIL"
            },
            posta: "CONCEPCION",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "MLC",
                name: "CURACO"
            },
            posta: "CONCEPCION",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZNG",
                name: "MILLONHUE"
            },
            posta: "CONCEPCION",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZPQ",
                name: "PEHUEN"
            },
            posta: "CONCEPCION",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZQR",
                name: "QUIDICO"
            },
            posta: "CONCEPCION",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZQA",
                name: "QUINAHUE"
            },
            posta: "CONCEPCION",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZRQ",
                name: "RANQUILCO"
            },
            posta: "CONCEPCION",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZYR",
                name: "RUCARAQUIL"
            },
            posta: "CONCEPCION",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "CFF",
                name: "SAN ALFONSO"
            },
            posta: "CONCEPCION",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZBL",
                name: "SARA DE LEBU"
            },
            posta: "CONCEPCION",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "YTG",
                name: "TREHUACO"
            },
            posta: "CONCEPCION",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZTP",
                name: "TRES PINOS"
            },
            posta: "CONCEPCION",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZYY",
                name: "YENECO"
            },
            posta: "CONCEPCION",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZCO",
                name: "TEMUCO"
            },
            posta: "TEMUCO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "AST",
                name: "AGUA SANTA"
            },
            posta: "TEMUCO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZGV",
                name: "ALMAGRO"
            },
            posta: "TEMUCO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "BOA",
                name: "BOROA"
            },
            posta: "TEMUCO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "CJN",
                name: "CAJON"
            },
            posta: "TEMUCO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "CRH",
                name: "CARAHUE"
            },
            posta: "TEMUCO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZCC",
                name: "CHERQUENCO"
            },
            posta: "TEMUCO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "CLU",
                name: "COLONIA LAUTARO"
            },
            posta: "TEMUCO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "NCO",
                name: "CUNCO"
            },
            posta: "TEMUCO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZEA",
                name: "EL ALAMBRADO"
            },
            posta: "TEMUCO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ERL",
                name: "ERCILLA"
            },
            posta: "TEMUCO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "FIE",
                name: "FREIRE"
            },
            posta: "TEMUCO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "GAL",
                name: "GALVARINO"
            },
            posta: "TEMUCO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZGE",
                name: "GENERAL LOPEZ"
            },
            posta: "TEMUCO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "GEA",
                name: "GORBEA"
            },
            posta: "TEMUCO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "LZA",
                name: "LABRANZA"
            },
            posta: "TEMUCO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "LNC",
                name: "LANCO"
            },
            posta: "TEMUCO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZLH",
                name: "LAS HORTENCIAS"
            },
            posta: "TEMUCO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZJM",
                name: "LAS MARIPOSAS"
            },
            posta: "TEMUCO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "LTR",
                name: "LAUTARO"
            },
            posta: "TEMUCO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "LOC",
                name: "LONCOCHE"
            },
            posta: "TEMUCO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZLD",
                name: "LOS PRADOS"
            },
            posta: "TEMUCO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "MTR",
                name: "METRENCO"
            },
            posta: "TEMUCO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "MBA",
                name: "MISION BOROA"
            },
            posta: "TEMUCO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "NIP",
                name: "NUEVA IMPERIAL"
            },
            posta: "TEMUCO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "PCS",
                name: "PADRE LAS CASAS"
            },
            posta: "TEMUCO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "PLO",
                name: "PAILACO"
            },
            posta: "TEMUCO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZPD",
                name: "PEDREGOSO"
            },
            posta: "TEMUCO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "PQO",
                name: "PERQUENCO"
            },
            posta: "TEMUCO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZUN",
                name: "PILLANLELBUN"
            },
            posta: "TEMUCO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "PQN",
                name: "PITRUFQUEN"
            },
            posta: "TEMUCO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "CAU",
                name: "PUA"
            },
            posta: "TEMUCO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZPU",
                name: "PUCON"
            },
            posta: "TEMUCO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZPS",
                name: "PUERTO SAAVEDRA"
            },
            posta: "TEMUCO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "QUP",
                name: "QUEPE"
            },
            posta: "TEMUCO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZQN",
                name: "QUINQUEN"
            },
            posta: "TEMUCO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZQT",
                name: "QUITRATUE"
            },
            posta: "TEMUCO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZLK",
                name: "RADAL"
            },
            posta: "TEMUCO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZRT",
                name: "RETEN DOLLINCO"
            },
            posta: "TEMUCO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZSP",
                name: "SAN PATRICIO"
            },
            posta: "TEMUCO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "SSP",
                name: "SAN PEDRO"
            },
            posta: "TEMUCO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "TEO",
                name: "TEODORO SCHMIDT"
            },
            posta: "TEMUCO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "TOL",
                name: "TOLTEN"
            },
            posta: "TEMUCO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZEN",
                name: "TRAIGUEN"
            },
            posta: "TEMUCO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "VIC",
                name: "VICTORIA"
            },
            posta: "TEMUCO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "VIL",
                name: "VILCUN"
            },
            posta: "TEMUCO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "VRR",
                name: "VILLARRICA"
            },
            posta: "TEMUCO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZBR",
                name: "BARROS ARANA"
            },
            posta: "TEMUCO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "BBD",
                name: "BOCA BUDI"
            },
            posta: "TEMUCO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "VAC",
                name: "CAMARONES"
            },
            posta: "TEMUCO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "CPP",
                name: "CAPITAN PASTENE"
            },
            posta: "TEMUCO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "CHL",
                name: "CHOLCHOL"
            },
            posta: "TEMUCO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "CHS",
                name: "CHOSHUENCO"
            },
            posta: "TEMUCO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZCM",
                name: "COMUY"
            },
            posta: "TEMUCO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "CCC",
                name: "CURACAUTIN"
            },
            posta: "TEMUCO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZHL",
                name: "HELO SUR"
            },
            posta: "TEMUCO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "UAL",
                name: "HUALPIN"
            },
            posta: "TEMUCO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZHS",
                name: "HUISCAPI"
            },
            posta: "TEMUCO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZBN",
                name: "LA SOMBRA"
            },
            posta: "TEMUCO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZLR",
                name: "LASTARRIA"
            },
            posta: "TEMUCO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "LCY",
                name: "LICAN RAY"
            },
            posta: "TEMUCO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "LLN",
                name: "LOLEN"
            },
            posta: "TEMUCO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZLV",
                name: "LOMACURA"
            },
            posta: "TEMUCO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZSO",
                name: "LOS LAURELES"
            },
            posta: "TEMUCO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "SUS",
                name: "LOS SAUCES"
            },
            posta: "TEMUCO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZLS",
                name: "LOS TALLOS"
            },
            posta: "TEMUCO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "LUM",
                name: "LUMACO"
            },
            posta: "TEMUCO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZOH",
                name: "MALALCAHUELO"
            },
            posta: "TEMUCO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZMN",
                name: "MANZANAR"
            },
            posta: "TEMUCO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "MQH",
                name: "MAQUEHUE"
            },
            posta: "TEMUCO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "MLP",
                name: "MELIPEUCO"
            },
            posta: "TEMUCO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "NCU",
                name: "NANCUL"
            },
            posta: "TEMUCO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZNH",
                name: "NEHUENTUE"
            },
            posta: "TEMUCO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZPH",
                name: "PAILAHUENQUE"
            },
            posta: "TEMUCO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "PGP",
                name: "PANGUIPULLI"
            },
            posta: "TEMUCO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "PEC",
                name: "PELECO"
            },
            posta: "TEMUCO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZNN",
                name: "PICHIPELLAHUEN"
            },
            posta: "TEMUCO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZPM",
                name: "PUERTO DOMINGUEZ"
            },
            posta: "TEMUCO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "PPM",
                name: "PUERTO PUMA"
            },
            posta: "TEMUCO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "PUR",
                name: "PUREN"
            },
            posta: "TEMUCO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZDQ",
                name: "QUIDICO"
            },
            posta: "TEMUCO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZQL",
                name: "QUILQUE"
            },
            posta: "TEMUCO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZRL",
                name: "RELUN"
            },
            posta: "TEMUCO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZXR",
                name: "RUCATRARO"
            },
            posta: "TEMUCO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "SSO",
                name: "SELVA OSCURA"
            },
            posta: "TEMUCO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZSI",
                name: "SIERRA NEVADA"
            },
            posta: "TEMUCO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "TUA",
                name: "TIRUA"
            },
            posta: "TEMUCO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZTQ",
                name: "TRES ESQUINAS"
            },
            posta: "TEMUCO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZTK",
                name: "TROVOLHUE"
            },
            posta: "TEMUCO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "VZA",
                name: "VILLA ARAUCANIA"
            },
            posta: "TEMUCO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZQG",
                name: "ANTIQUINA"
            },
            posta: "TEMUCO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZCB",
                name: "CABURGUA"
            },
            posta: "TEMUCO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZCZ",
                name: "CALAFQUEN"
            },
            posta: "TEMUCO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZZQ",
                name: "CARIRRINGUE"
            },
            posta: "TEMUCO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZCT",
                name: "CENTENARIO"
            },
            posta: "TEMUCO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "CPE",
                name: "COÑARIPE"
            },
            posta: "TEMUCO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "RRH",
                name: "CURARREHUE"
            },
            posta: "TEMUCO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZNC",
                name: "ENCO"
            },
            posta: "TEMUCO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZTZ",
                name: "HUIFE"
            },
            posta: "TEMUCO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZIC",
                name: "ICALMA"
            },
            posta: "TEMUCO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZVC",
                name: "LIQUINE"
            },
            posta: "TEMUCO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "IRA",
                name: "LIUCURA"
            },
            posta: "TEMUCO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZLE",
                name: "LOBERIA"
            },
            posta: "TEMUCO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "LQY",
                name: "LONQUIMAY"
            },
            posta: "TEMUCO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "NNH",
                name: "NAHUELVE"
            },
            posta: "TEMUCO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "NTM",
                name: "NELTUME"
            },
            posta: "TEMUCO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "MFN",
                name: "PLAYA NEGRA"
            },
            posta: "TEMUCO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZFQ",
                name: "PUERTO FUY"
            },
            posta: "TEMUCO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZPT",
                name: "PUERTO PIRIHUEICO"
            },
            posta: "TEMUCO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "PUE",
                name: "PUESCO"
            },
            posta: "TEMUCO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "PGE",
                name: "PULLINGUE"
            },
            posta: "TEMUCO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZQE",
                name: "QUECHEREGUAS"
            },
            posta: "TEMUCO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZRE",
                name: "REFUGIO"
            },
            posta: "TEMUCO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZRF",
                name: "REFUGIO LLAIMA"
            },
            posta: "TEMUCO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZGO",
                name: "REIGOLIL"
            },
            posta: "TEMUCO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZWE",
                name: "RINCONADA"
            },
            posta: "TEMUCO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "SZZ",
                name: "SANTA ROSA"
            },
            posta: "TEMUCO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "TRC",
                name: "TERMAS DE CONARIPE"
            },
            posta: "TEMUCO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZTR",
                name: "TERMAS DE HUIFE"
            },
            posta: "TEMUCO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZMT",
                name: "TERMAS DE MENETUE"
            },
            posta: "TEMUCO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZMY",
                name: "TERMAS DE MOLULCO"
            },
            posta: "TEMUCO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZTM",
                name: "TERMAS DE PALGUIN"
            },
            posta: "TEMUCO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "GAR",
                name: "TERMAS DE PANGUI"
            },
            posta: "TEMUCO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZTS",
                name: "TERMAS DE RIO BLANCO"
            },
            posta: "TEMUCO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "TSL",
                name: "TERMAS DE SAN LUIS"
            },
            posta: "TEMUCO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZTB",
                name: "TERMAS DE SAN SEBASTIAN"
            },
            posta: "TEMUCO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZTD",
                name: "TERMAS DE TOLHUACA"
            },
            posta: "TEMUCO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZTY",
                name: "TROYO"
            },
            posta: "TEMUCO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "LML",
                name: "LOS MOLINOS"
            },
            posta: "VALDIVIA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "NBL",
                name: "NIEBLA"
            },
            posta: "VALDIVIA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZAL",
                name: "VALDIVIA"
            },
            posta: "VALDIVIA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ANH",
                name: "ANTILHUE"
            },
            posta: "VALDIVIA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZAX",
                name: "CHANCOYAN"
            },
            posta: "VALDIVIA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZCI",
                name: "CIRUELOS"
            },
            posta: "VALDIVIA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "CNA",
                name: "CURINANCO"
            },
            posta: "VALDIVIA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZYH",
                name: "HUEYELHUE"
            },
            posta: "VALDIVIA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZLP",
                name: "LA PENA"
            },
            posta: "VALDIVIA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "LVS",
                name: "LAS VENTANAS"
            },
            posta: "VALDIVIA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "LAG",
                name: "LOS LAGOS"
            },
            posta: "VALDIVIA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "LPS",
                name: "LOS PELLINES"
            },
            posta: "VALDIVIA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZLX",
                name: "LOS ULMOS"
            },
            posta: "VALDIVIA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "MFL",
                name: "MAFIL"
            },
            posta: "VALDIVIA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "PAI",
                name: "PAILLACO"
            },
            posta: "VALDIVIA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZPI",
                name: "PICHIRROPULLI"
            },
            posta: "VALDIVIA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "CHY",
                name: "PICHOY"
            },
            posta: "VALDIVIA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZAE",
                name: "PUERTO PAICO"
            },
            posta: "VALDIVIA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "RMN",
                name: "REUMEN"
            },
            posta: "VALDIVIA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZRU",
                name: "RUNCA"
            },
            posta: "VALDIVIA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "MQA",
                name: "S.J. DE LA MARIQUINA"
            },
            posta: "VALDIVIA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZSS",
                name: "SANTA ELISA"
            },
            posta: "VALDIVIA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZAP",
                name: "TRALCAO"
            },
            posta: "VALDIVIA",
            radio: "BASE"
        },
        {
            localidad: {
                code: "COI",
                name: "COIQUE"
            },
            posta: "VALDIVIA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "COA",
                name: "COLEGUAL"
            },
            posta: "VALDIVIA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZCR",
                name: "CORRAL"
            },
            posta: "VALDIVIA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "CRS",
                name: "CRUCES"
            },
            posta: "VALDIVIA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZDO",
                name: "DOLLINCO"
            },
            posta: "VALDIVIA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZFO",
                name: "FOLILCO"
            },
            posta: "VALDIVIA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZFF",
                name: "FUERTE SAN LUIS"
            },
            posta: "VALDIVIA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "FAE",
                name: "FUNDO ALTUE"
            },
            posta: "VALDIVIA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "FTR",
                name: "FUTRONO"
            },
            posta: "VALDIVIA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "HIC",
                name: "HUICHACO"
            },
            posta: "VALDIVIA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZHI",
                name: "HUITE"
            },
            posta: "VALDIVIA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZLW",
                name: "LA CAPILLA"
            },
            posta: "VALDIVIA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "LRA",
                name: "LA LEONERA"
            },
            posta: "VALDIVIA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "LLI",
                name: "LLIFEN"
            },
            posta: "VALDIVIA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZAQ",
                name: "LOS CONALES"
            },
            posta: "VALDIVIA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "MAL",
                name: "MALALHUE"
            },
            posta: "VALDIVIA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "MMO",
                name: "MANAO"
            },
            posta: "VALDIVIA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "MEH",
                name: "MEHUIN"
            },
            posta: "VALDIVIA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZMB",
                name: "MONTUELA"
            },
            posta: "VALDIVIA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZPX",
                name: "PUCARA"
            },
            posta: "VALDIVIA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "PUC",
                name: "PUCONO"
            },
            posta: "VALDIVIA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "NUC",
                name: "PUNUCAPA"
            },
            posta: "VALDIVIA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZPG",
                name: "PURINGUE"
            },
            posta: "VALDIVIA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZPO",
                name: "PURULON"
            },
            posta: "VALDIVIA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZQU",
                name: "QUEULE"
            },
            posta: "VALDIVIA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZSA",
                name: "SALTO DEL AGUA"
            },
            posta: "VALDIVIA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZBZ",
                name: "BANOS DE CHIHUIO"
            },
            posta: "VALDIVIA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZXB",
                name: "CHABRANCO"
            },
            posta: "VALDIVIA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "EMN",
                name: "EL MUNDIAL"
            },
            posta: "VALDIVIA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZHU",
                name: "HUEQUECURA"
            },
            posta: "VALDIVIA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "OSN",
                name: "LIPINGUE"
            },
            posta: "VALDIVIA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZLO",
                name: "LOS LLOLLES"
            },
            posta: "VALDIVIA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "MLE",
                name: "MAIHUE"
            },
            posta: "VALDIVIA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "PTC",
                name: "PUNTA CHAIHUIN"
            },
            posta: "VALDIVIA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "XGR",
                name: "RINIHUE"
            },
            posta: "VALDIVIA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "TRR",
                name: "TRAITRACO"
            },
            posta: "VALDIVIA",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZOS",
                name: "OSORNO"
            },
            posta: "OSORNO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZBH",
                name: "BAHIA MANSA"
            },
            posta: "OSORNO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "OAR",
                name: "CANCURA"
            },
            posta: "OSORNO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "OLO",
                name: "CARACOL"
            },
            posta: "OSORNO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "OAM",
                name: "CASMA"
            },
            posta: "OSORNO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZCY",
                name: "CAYURRUCA"
            },
            posta: "OSORNO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "OOC",
                name: "CENTRAL RUPANCO"
            },
            posta: "OSORNO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "OOL",
                name: "CHAHUILCO"
            },
            posta: "OSORNO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZNX",
                name: "CHANCHAN"
            },
            posta: "OSORNO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "OER",
                name: "CHIRRE"
            },
            posta: "OSORNO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "OOA",
                name: "CONTACO"
            },
            posta: "OSORNO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "OOT",
                name: "CORTE ALTO"
            },
            posta: "OSORNO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "OOR",
                name: "CRUCERO"
            },
            posta: "OSORNO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZEE",
                name: "EL ENCANTO"
            },
            posta: "OSORNO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ORO",
                name: "EL MIRADOR"
            },
            posta: "OSORNO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ENL",
                name: "ENTRE LAGOS"
            },
            posta: "OSORNO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZFI",
                name: "FILUCO"
            },
            posta: "OSORNO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "FRT",
                name: "FRUTILLAR"
            },
            posta: "OSORNO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZHM",
                name: "HUILMA"
            },
            posta: "OSORNO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZIG",
                name: "IGNAO"
            },
            posta: "OSORNO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZLU",
                name: "LA UNION"
            },
            posta: "OSORNO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "OSM",
                name: "LAS LUMAS"
            },
            posta: "OSORNO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "OSE",
                name: "LOS CONALES"
            },
            posta: "OSORNO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZEU",
                name: "LOS CORRALES"
            },
            posta: "OSORNO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZWQ",
                name: "MAICOLPUE"
            },
            posta: "OSORNO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZMV",
                name: "MONTE VERDE"
            },
            posta: "OSORNO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZPY",
                name: "PARAGUAY"
            },
            posta: "OSORNO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "OSD",
                name: "PICHI DAMAS"
            },
            posta: "OSORNO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "OOH",
                name: "PUAUCHO"
            },
            posta: "OSORNO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "OEH",
                name: "PUCATRIHUE"
            },
            posta: "OSORNO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZXN",
                name: "PUERTO NUEVO"
            },
            posta: "OSORNO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "PCY",
                name: "PUERTO OCTAY"
            },
            posta: "OSORNO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "PRE",
                name: "PURRANQUE"
            },
            posta: "OSORNO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "PYH",
                name: "PUYEHUE"
            },
            posta: "OSORNO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZRP",
                name: "RAPACO"
            },
            posta: "OSORNO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZRM",
                name: "REMEHUE"
            },
            posta: "OSORNO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "RBN",
                name: "RIO BUENO"
            },
            posta: "OSORNO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "RNC",
                name: "RIO NEGRO"
            },
            posta: "OSORNO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "SJD",
                name: "SAN JUAN DE LA COSTA"
            },
            posta: "OSORNO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "SPL",
                name: "SAN PABLO"
            },
            posta: "OSORNO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZSR",
                name: "SANTA ROSA"
            },
            posta: "OSORNO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "OIP",
                name: "TRAPI"
            },
            posta: "OSORNO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "TMA",
                name: "TRUMAO"
            },
            posta: "OSORNO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZVV",
                name: "VIVANCO"
            },
            posta: "OSORNO",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZAA",
                name: "AGUAS CALIENTES"
            },
            posta: "OSORNO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZAC",
                name: "ANTICURA"
            },
            posta: "OSORNO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZIX",
                name: "CONCORDIA"
            },
            posta: "OSORNO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZEB",
                name: "EL BOLSON"
            },
            posta: "OSORNO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZEO",
                name: "EL ISLOTE"
            },
            posta: "OSORNO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZHY",
                name: "HUEYUSCA"
            },
            posta: "OSORNO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZEQ",
                name: "ILIHUE"
            },
            posta: "OSORNO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "OAA",
                name: "LA BARRA"
            },
            posta: "OSORNO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "LRO",
                name: "LAGO RANCO"
            },
            posta: "OSORNO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "LDS",
                name: "LAS CASCADAS"
            },
            posta: "OSORNO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "OEU",
                name: "LLIHUE"
            },
            posta: "OSORNO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZEP",
                name: "LOS BAJOS"
            },
            posta: "OSORNO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "LCL",
                name: "LOS CHILCOS"
            },
            posta: "OSORNO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "OET",
                name: "MILLANTUE"
            },
            posta: "OSORNO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZLY",
                name: "NILQUE"
            },
            posta: "OSORNO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "PNG",
                name: "PIEDRAS NEGRAS"
            },
            posta: "OSORNO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZPK",
                name: "PUERTO CLOCKER"
            },
            posta: "OSORNO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "XPF",
                name: "PUERTO FONCK"
            },
            posta: "OSORNO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "OOI",
                name: "PUERTO RICO"
            },
            posta: "OSORNO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "OLE",
                name: "PURRAPEL"
            },
            posta: "OSORNO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZRD",
                name: "REFUGIO LA PICADA"
            },
            posta: "OSORNO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZZR",
                name: "RININAHUE"
            },
            posta: "OSORNO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZTG",
                name: "TEGUALDA"
            },
            posta: "OSORNO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ODA",
                name: "TRINIDAD"
            },
            posta: "OSORNO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZHC",
                name: "HUEICOLLA"
            },
            posta: "OSORNO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZPJ",
                name: "PAJARITOS"
            },
            posta: "OSORNO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZRN",
                name: "REFUGIO ANTILLANCA"
            },
            posta: "OSORNO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZTH",
                name: "TERMAS DE PUYEHUE"
            },
            posta: "OSORNO",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "PMC",
                name: "PUERTO MONTT"
            },
            posta: "PUERTO MONTT",
            radio: "BASE"
        },
        {
            localidad: {
                code: "RPM",
                name: "RANCHO PUERTO MONTT"
            },
            posta: "PUERTO MONTT",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ALE",
                name: "ALERCE"
            },
            posta: "PUERTO MONTT",
            radio: "BASE"
        },
        {
            localidad: {
                code: "CBU",
                name: "CALBUCO"
            },
            posta: "PUERTO MONTT",
            radio: "BASE"
        },
        {
            localidad: {
                code: "PCA",
                name: "CALETA LA ARENA"
            },
            posta: "PUERTO MONTT",
            radio: "BASE"
        },
        {
            localidad: {
                code: "PZH",
                name: "CHAMIZA"
            },
            posta: "PUERTO MONTT",
            radio: "BASE"
        },
        {
            localidad: {
                code: "PCL",
                name: "COLEGUAL"
            },
            posta: "PUERTO MONTT",
            radio: "BASE"
        },
        {
            localidad: {
                code: "PCT",
                name: "CORRENTOSO"
            },
            posta: "PUERTO MONTT",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ETL",
                name: "EL TEPUAL"
            },
            posta: "PUERTO MONTT",
            radio: "BASE"
        },
        {
            localidad: {
                code: "FSA",
                name: "FRESIA"
            },
            posta: "PUERTO MONTT",
            radio: "BASE"
        },
        {
            localidad: {
                code: "PXY",
                name: "HUELMO"
            },
            posta: "PUERTO MONTT",
            radio: "BASE"
        },
        {
            localidad: {
                code: "PWP",
                name: "LA POSA"
            },
            posta: "PUERTO MONTT",
            radio: "BASE"
        },
        {
            localidad: {
                code: "PLS",
                name: "LAS QUEMAS"
            },
            posta: "PUERTO MONTT",
            radio: "BASE"
        },
        {
            localidad: {
                code: "PLW",
                name: "LENCA"
            },
            posta: "PUERTO MONTT",
            radio: "BASE"
        },
        {
            localidad: {
                code: "LLQ",
                name: "LLANQUIHUE"
            },
            posta: "PUERTO MONTT",
            radio: "BASE"
        },
        {
            localidad: {
                code: "LLA",
                name: "LOLCURA"
            },
            posta: "PUERTO MONTT",
            radio: "BASE"
        },
        {
            localidad: {
                code: "LNT",
                name: "LONCOTORO"
            },
            posta: "PUERTO MONTT",
            radio: "BASE"
        },
        {
            localidad: {
                code: "LMU",
                name: "LOS MUERMOS"
            },
            posta: "PUERTO MONTT",
            radio: "BASE"
        },
        {
            localidad: {
                code: "PWT",
                name: "LOS PELLINES"
            },
            posta: "PUERTO MONTT",
            radio: "BASE"
        },
        {
            localidad: {
                code: "MAU",
                name: "MAULLIN"
            },
            posta: "PUERTO MONTT",
            radio: "BASE"
        },
        {
            localidad: {
                code: "MSQ",
                name: "MISQUIHUE"
            },
            posta: "PUERTO MONTT",
            radio: "BASE"
        },
        {
            localidad: {
                code: "NBR",
                name: "NUEVA BRAUNAU"
            },
            posta: "PUERTO MONTT",
            radio: "BASE"
        },
        {
            localidad: {
                code: "PPD",
                name: "PARGA"
            },
            posta: "PUERTO MONTT",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZGA",
                name: "PARGUA"
            },
            posta: "PUERTO MONTT",
            radio: "BASE"
        },
        {
            localidad: {
                code: "PPP",
                name: "PUELPUN"
            },
            posta: "PUERTO MONTT",
            radio: "BASE"
        },
        {
            localidad: {
                code: "ZPV",
                name: "PUERTO VARAS"
            },
            posta: "PUERTO MONTT",
            radio: "BASE"
        },
        {
            localidad: {
                code: "PTE",
                name: "QUILLAIPE"
            },
            posta: "PUERTO MONTT",
            radio: "BASE"
        },
        {
            localidad: {
                code: "RIS",
                name: "RIO SUR"
            },
            posta: "PUERTO MONTT",
            radio: "BASE"
        },
        {
            localidad: {
                code: "PAB",
                name: "ABTAO"
            },
            posta: "PUERTO MONTT",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ACD",
                name: "ANCUD"
            },
            posta: "PUERTO MONTT",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "CRU",
                name: "CARELMAPU"
            },
            posta: "PUERTO MONTT",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "CTR",
                name: "CASTRO"
            },
            posta: "PUERTO MONTT",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "CHG",
                name: "CAULIN"
            },
            posta: "PUERTO MONTT",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "CAO",
                name: "CHACAO"
            },
            posta: "PUERTO MONTT",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ENS",
                name: "ENSENADA"
            },
            posta: "PUERTO MONTT",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "PLI",
                name: "LINAO"
            },
            posta: "PUERTO MONTT",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "PLU",
                name: "LLIUCO"
            },
            posta: "PUERTO MONTT",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "OSC",
                name: "LOS RISCOS"
            },
            posta: "PUERTO MONTT",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "PMA",
                name: "MAICHIHUE"
            },
            posta: "PUERTO MONTT",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "PMN",
                name: "MANAO"
            },
            posta: "PUERTO MONTT",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "PPA",
                name: "PANGAL"
            },
            posta: "PUERTO MONTT",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "PTH",
                name: "PETROHUE"
            },
            posta: "PUERTO MONTT",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "QLN",
                name: "QUELLON"
            },
            posta: "PUERTO MONTT",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "QUE",
                name: "QUEMCHI"
            },
            posta: "PUERTO MONTT",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "RQL",
                name: "RANCHO QUELLON"
            },
            posta: "PUERTO MONTT",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "PWF",
                name: "RIO FRIO"
            },
            posta: "PUERTO MONTT",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZAO",
                name: "ACHAO"
            },
            posta: "PUERTO MONTT",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "PZG",
                name: "AGUAS BUENAS"
            },
            posta: "PUERTO MONTT",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "AHI",
                name: "AHONI"
            },
            posta: "PUERTO MONTT",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "PWU",
                name: "AITUI"
            },
            posta: "PUERTO MONTT",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ADO",
                name: "ALDACHILDO"
            },
            posta: "PUERTO MONTT",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "PAT",
                name: "ALTO BUTALCURA"
            },
            posta: "PUERTO MONTT",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "APO",
                name: "APIAO"
            },
            posta: "PUERTO MONTT",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "PAU",
                name: "AUCAR"
            },
            posta: "PUERTO MONTT",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ALN",
                name: "AULEN"
            },
            posta: "PUERTO MONTT",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "PAY",
                name: "AYACARA"
            },
            posta: "PUERTO MONTT",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "PBS",
                name: "BANOS DE SOTOMO"
            },
            posta: "PUERTO MONTT",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "PZB",
                name: "BELBEN"
            },
            posta: "PUERTO MONTT",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "PBX",
                name: "BUILL"
            },
            posta: "PUERTO MONTT",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "PCG",
                name: "CALETA GONZALO"
            },
            posta: "PUERTO MONTT",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "PWX",
                name: "CALETA HUALAIHUE"
            },
            posta: "PUERTO MONTT",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "PBB",
                name: "CALETA SANTA BARBARA"
            },
            posta: "PUERTO MONTT",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "PWC",
                name: "CANUTILLAR"
            },
            posta: "PUERTO MONTT",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "PDN",
                name: "CHADMO CENTRAL"
            },
            posta: "PUERTO MONTT",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZCN",
                name: "CHAITEN"
            },
            posta: "PUERTO MONTT",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "PWQ",
                name: "CHAPARANO"
            },
            posta: "PUERTO MONTT",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "PHC",
                name: "CHAULINEC"
            },
            posta: "PUERTO MONTT",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "PWS",
                name: "CHOLGO"
            },
            posta: "PUERTO MONTT",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZCH",
                name: "CHONCHI"
            },
            posta: "PUERTO MONTT",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "CMO",
                name: "COCHAMO"
            },
            posta: "PUERTO MONTT",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "PWI",
                name: "COINCO"
            },
            posta: "PUERTO MONTT",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "PCM",
                name: "CONTAO"
            },
            posta: "PUERTO MONTT",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "PCN",
                name: "CONTAY"
            },
            posta: "PUERTO MONTT",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "CUO",
                name: "CUCAO"
            },
            posta: "PUERTO MONTT",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "CVL",
                name: "CURACO DE VELEZ"
            },
            posta: "PUERTO MONTT",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "CGE",
                name: "CURAHUE"
            },
            posta: "PUERTO MONTT",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "DLE",
                name: "DALCAHUE"
            },
            posta: "PUERTO MONTT",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "PDG",
                name: "DEGAN"
            },
            posta: "PUERTO MONTT",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "PWA",
                name: "EL BARRACO"
            },
            posta: "PUERTO MONTT",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "FTF",
                name: "FUTALEUFU"
            },
            posta: "PUERTO MONTT",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "GBN",
                name: "GUALBUN"
            },
            posta: "PUERTO MONTT",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "HRP",
                name: "HORNOPIREN"
            },
            posta: "PUERTO MONTT",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "HLH",
                name: "HUALAIHUE"
            },
            posta: "PUERTO MONTT",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "PHI",
                name: "HUILDAD"
            },
            posta: "PUERTO MONTT",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "HNO",
                name: "HUILLINCO"
            },
            posta: "PUERTO MONTT",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "PHN",
                name: "HUINAY"
            },
            posta: "PUERTO MONTT",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "PLG",
                name: "LAGO YELCHO"
            },
            posta: "PUERTO MONTT",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "PGX",
                name: "LLANADA GRANDE"
            },
            posta: "PUERTO MONTT",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "PME",
                name: "MECHUQUE"
            },
            posta: "PUERTO MONTT",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "PPU",
                name: "PAILDAD"
            },
            posta: "PUERTO MONTT",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "PLE",
                name: "PALENA"
            },
            posta: "PUERTO MONTT",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "PPI",
                name: "PICHANCO"
            },
            posta: "PUERTO MONTT",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "PWY",
                name: "POYO"
            },
            posta: "PUERTO MONTT",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "PPR",
                name: "PRIMER CORRAL"
            },
            posta: "PUERTO MONTT",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "PPL",
                name: "PUELO"
            },
            posta: "PUERTO MONTT",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "TCD",
                name: "PUERTO CARDENAS"
            },
            posta: "PUERTO MONTT",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "PIE",
                name: "PUERTO PIEDRA"
            },
            posta: "PUERTO MONTT",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "PRZ",
                name: "PUERTO RAMIREZ"
            },
            posta: "PUERTO MONTT",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "PQE",
                name: "PUQUELDON"
            },
            posta: "PUERTO MONTT",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "QLE",
                name: "QUEILEN"
            },
            posta: "PUERTO MONTT",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "QUV",
                name: "QUELLON VIEJO"
            },
            posta: "PUERTO MONTT",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "PQT",
                name: "QUETELMAHUE"
            },
            posta: "PUERTO MONTT",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "QIA",
                name: "QUICAVI"
            },
            posta: "PUERTO MONTT",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "QCH",
                name: "QUINCHAO"
            },
            posta: "PUERTO MONTT",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "PRA",
                name: "RALUN"
            },
            posta: "PUERTO MONTT",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "PWZ",
                name: "ROLLIZO"
            },
            posta: "PUERTO MONTT",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "JJN",
                name: "SAN JUAN"
            },
            posta: "PUERTO MONTT",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "PSE",
                name: "SEGUNDO CORRAL"
            },
            posta: "PUERTO MONTT",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "TLE",
                name: "TERMAS DE LLANCATUE"
            },
            posta: "PUERTO MONTT",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "PTM",
                name: "TERMAS EL AMARILLO"
            },
            posta: "PUERTO MONTT",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "PTU",
                name: "TEUPA"
            },
            posta: "PUERTO MONTT",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "PWD",
                name: "TRINCAO"
            },
            posta: "PUERTO MONTT",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "PVI",
                name: "VILLA SANTA LUCIA"
            },
            posta: "PUERTO MONTT",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "PVL",
                name: "VILLA VANGUARDIA"
            },
            posta: "PUERTO MONTT",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "PYA",
                name: "YALDAD"
            },
            posta: "PUERTO MONTT",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "GXQ",
                name: "COYHAIQUE"
            },
            posta: "COYHAIQUE",
            radio: "BASE"
        },
        {
            localidad: {
                code: "BBA",
                name: "BALMACEDA"
            },
            posta: "COYHAIQUE",
            radio: "BASE"
        },
        {
            localidad: {
                code: "WPA",
                name: "PUERTO AYSEN"
            },
            posta: "COYHAIQUE",
            radio: "BASE"
        },
        {
            localidad: {
                code: "GAX",
                name: "COYHAIQUE ALTO"
            },
            posta: "COYHAIQUE",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "GMH",
                name: "MANIHUALES"
            },
            posta: "COYHAIQUE",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "PCC",
                name: "PUERTO CHACABUCO"
            },
            posta: "COYHAIQUE",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "RIB",
                name: "RIO IBAÑEZ"
            },
            posta: "COYHAIQUE",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "MHS",
                name: "VILLA MANIHUALES"
            },
            posta: "COYHAIQUE",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "BNO",
                name: "BANO NUEVO"
            },
            posta: "COYHAIQUE",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "TOR",
                name: "CALETA TORTEL"
            },
            posta: "COYHAIQUE",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "CCH",
                name: "CHILE CHICO"
            },
            posta: "COYHAIQUE",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "CNS",
                name: "CISNES"
            },
            posta: "COYHAIQUE",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "CCL",
                name: "COCHRANE"
            },
            posta: "COYHAIQUE",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "IAU",
                name: "EL GATO"
            },
            posta: "COYHAIQUE",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "GCA",
                name: "GUAITECAS"
            },
            posta: "COYHAIQUE",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "GIA",
                name: "ISLA ANGAMOS"
            },
            posta: "COYHAIQUE",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "GIB",
                name: "ISLA BENJAMIN"
            },
            posta: "COYHAIQUE",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "GIC",
                name: "ISLA CAMPANA"
            },
            posta: "COYHAIQUE",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "GIP",
                name: "ISLA CUPTANA"
            },
            posta: "COYHAIQUE",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "GII",
                name: "ISLA IPUN"
            },
            posta: "COYHAIQUE",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "GIZ",
                name: "ISLA IZAZO"
            },
            posta: "COYHAIQUE",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "GIL",
                name: "ISLA LEVEL"
            },
            posta: "COYHAIQUE",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "GIM",
                name: "ISLA MELCHOR"
            },
            posta: "COYHAIQUE",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "GMJ",
                name: "ISLA MERINO JARPA"
            },
            posta: "COYHAIQUE",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "GIN",
                name: "ISLA NALCAYEC"
            },
            posta: "COYHAIQUE",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "GIY",
                name: "ISLA PATRICIO LYNCH"
            },
            posta: "COYHAIQUE",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "GIV",
                name: "ISLA VICTORIA"
            },
            posta: "COYHAIQUE",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "JUN",
                name: "LA JUNTA"
            },
            posta: "COYHAIQUE",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "GLT",
                name: "LA TAPERA"
            },
            posta: "COYHAIQUE",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ANE",
                name: "LAGO COCHRANE"
            },
            posta: "COYHAIQUE",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "LVE",
                name: "LAGO VERDE"
            },
            posta: "COYHAIQUE",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "GLS",
                name: "LAGUNA SAN RAFAEL"
            },
            posta: "COYHAIQUE",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "GLV",
                name: "LEVICAN"
            },
            posta: "COYHAIQUE",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "NKA",
                name: "MELINKA"
            },
            posta: "COYHAIQUE",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "GMT",
                name: "MINA EL TOQUI"
            },
            posta: "COYHAIQUE",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "GNG",
                name: "NIREGUAO"
            },
            posta: "COYHAIQUE",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "OHG",
                name: "O HIGGINS"
            },
            posta: "COYHAIQUE",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "AGR",
                name: "PUERTO AGUIRRE"
            },
            posta: "COYHAIQUE",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "PBI",
                name: "PUERTO BERTRAND"
            },
            posta: "COYHAIQUE",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "PCI",
                name: "PUERTO CISNES"
            },
            posta: "COYHAIQUE",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "GPF",
                name: "PUERTO FACHINAL"
            },
            posta: "COYHAIQUE",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "GVH",
                name: "PUERTO GAVIOTA"
            },
            posta: "COYHAIQUE",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "PGU",
                name: "PUERTO GUADAL"
            },
            posta: "COYHAIQUE",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "GXP",
                name: "PUERTO HERRADURA"
            },
            posta: "COYHAIQUE",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "GIQ",
                name: "PUERTO ING.IBANEZ"
            },
            posta: "COYHAIQUE",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "GPX",
                name: "PUERTO MURTA"
            },
            posta: "COYHAIQUE",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "GPZ",
                name: "PUERTO SANCHEZ"
            },
            posta: "COYHAIQUE",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "TGL",
                name: "PUERTO TRANQUILO"
            },
            posta: "COYHAIQUE",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "GPY",
                name: "PUERTO YUNGAY"
            },
            posta: "COYHAIQUE",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "YUH",
                name: "PUYUHUAPI"
            },
            posta: "COYHAIQUE",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "GRC",
                name: "RIO CISNES"
            },
            posta: "COYHAIQUE",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "GSM",
                name: "SANTA MARIA DEL MAR"
            },
            posta: "COYHAIQUE",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "GTP",
                name: "TERMAS DE PUYUHUAPI"
            },
            posta: "COYHAIQUE",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "TRT",
                name: "TORTEL"
            },
            posta: "COYHAIQUE",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "VGL",
                name: "VILLA AMENGUAL"
            },
            posta: "COYHAIQUE",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "VCC",
                name: "VILLA CERRO CASTILLO"
            },
            posta: "COYHAIQUE",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "GVC",
                name: "VILLA CHACABUCO"
            },
            posta: "COYHAIQUE",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "VOH",
                name: "VILLA OHIGGINS"
            },
            posta: "COYHAIQUE",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "GVZ",
                name: "VILLA ORTEGA"
            },
            posta: "COYHAIQUE",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "PUQ",
                name: "PUNTA ARENAS"
            },
            posta: "PUNTA ARENAS",
            radio: "BASE"
        },
        {
            localidad: {
                code: "RPU",
                name: "RANCHO PUNTA ARENAS"
            },
            posta: "PUNTA ARENAS",
            radio: "BASE"
        },
        {
            localidad: {
                code: "MPK",
                name: "MINA PECKET"
            },
            posta: "PUNTA ARENAS",
            radio: "BASE"
        },
        {
            localidad: {
                code: "KSB",
                name: "SAN SEBASTIAN"
            },
            posta: "PUNTA ARENAS",
            radio: "BASE"
        },
        {
            localidad: {
                code: "CAB",
                name: "CABEZA DE MAR"
            },
            posta: "PUNTA ARENAS",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "EVT",
                name: "ENTRE VIENTOS"
            },
            posta: "PUNTA ARENAS",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ESJ",
                name: "ESTANCIA SAN JUAN"
            },
            posta: "PUNTA ARENAS",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "FBL",
                name: "FUERTE BULNES"
            },
            posta: "PUNTA ARENAS",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ATC",
                name: "ANTARTICA"
            },
            posta: "PUNTA ARENAS",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ARM",
                name: "ARMONIA"
            },
            posta: "PUNTA ARENAS",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "HOR",
                name: "CABO DE HORNOS"
            },
            posta: "PUNTA ARENAS",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "CEU",
                name: "CALETA EUGENIA"
            },
            posta: "PUNTA ARENAS",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "PCX",
                name: "CAMERON"
            },
            posta: "PUNTA ARENAS",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "CEC",
                name: "CERRO CASTILLO"
            },
            posta: "PUNTA ARENAS",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "CSM",
                name: "CERRO SOMBRERO"
            },
            posta: "PUNTA ARENAS",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "QEN",
                name: "CULLEN"
            },
            posta: "PUNTA ARENAS",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ECH",
                name: "ESTANCIA CHINA CR."
            },
            posta: "PUNTA ARENAS",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ELO",
                name: "ESTANCIA LOS OLIVOS"
            },
            posta: "PUNTA ARENAS",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "PES",
                name: "ESTANCIA SN GREGORIO"
            },
            posta: "PUNTA ARENAS",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ESV",
                name: "ESTANCIA VICTORINA"
            },
            posta: "PUNTA ARENAS",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "GKE",
                name: "GAIKE"
            },
            posta: "PUNTA ARENAS",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "PGL",
                name: "GALLEGOS CHICOS"
            },
            posta: "PUNTA ARENAS",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "IDW",
                name: "ISLA DAWSON"
            },
            posta: "PUNTA ARENAS",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "INV",
                name: "ISLA NAVARINO"
            },
            posta: "PUNTA ARENAS",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "PLJ",
                name: "LA JUNTA"
            },
            posta: "PUNTA ARENAS",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "LBL",
                name: "LAGUNA BLANCA"
            },
            posta: "PUNTA ARENAS",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "LTA",
                name: "LAPATAIA"
            },
            posta: "PUNTA ARENAS",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "MTS",
                name: "MANANTIALES"
            },
            posta: "PUNTA ARENAS",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "MAY",
                name: "MONTE AYMOND"
            },
            posta: "PUNTA ARENAS",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "MRC",
                name: "MORRO CHICO"
            },
            posta: "PUNTA ARENAS",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ONA",
                name: "ONAISIN"
            },
            posta: "PUNTA ARENAS",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "PPG",
                name: "PAMPA GUANACOS"
            },
            posta: "PUNTA ARENAS",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "PHE",
                name: "PEHOE"
            },
            posta: "PUNTA ARENAS",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "ZPR",
                name: "PORVENIR"
            },
            posta: "PUNTA ARENAS",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "PRI",
                name: "PRIMAVERA"
            },
            posta: "PUNTA ARENAS",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "PPN",
                name: "PUERTO ALTAMIRANO"
            },
            posta: "PUNTA ARENAS",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "UOR",
                name: "PUERTO ARTURO"
            },
            posta: "PUNTA ARENAS",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "PPB",
                name: "PUERTO BORIES"
            },
            posta: "PUNTA ARENAS",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "PPC",
                name: "PUERTO CONDOR"
            },
            posta: "PUNTA ARENAS",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "PNT",
                name: "PUERTO NATALES"
            },
            posta: "PUNTA ARENAS",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "PZP",
                name: "PUERTO PERCY"
            },
            posta: "PUNTA ARENAS",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "PTO",
                name: "PUERTO TORO"
            },
            posta: "PUNTA ARENAS",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "PWL",
                name: "PUERTO WILLIAMS"
            },
            posta: "PUNTA ARENAS",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "PPY",
                name: "PUERTO YARTAU"
            },
            posta: "PUNTA ARENAS",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "PDU",
                name: "PUNTA DELGADA"
            },
            posta: "PUNTA ARENAS",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "RGG",
                name: "RIO GRANDE"
            },
            posta: "PUNTA ARENAS",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "RTT",
                name: "RIO TURBIO"
            },
            posta: "PUNTA ARENAS",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "RVE",
                name: "RIO VERDE"
            },
            posta: "PUNTA ARENAS",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "RBS",
                name: "RUBENS"
            },
            posta: "PUNTA ARENAS",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "SGG",
                name: "SAN GREGORIO"
            },
            posta: "PUNTA ARENAS",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "SRU",
                name: "SECCION RUSSFIN"
            },
            posta: "PUNTA ARENAS",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "TCN",
                name: "TERMINAL CABO NEGRO"
            },
            posta: "PUNTA ARENAS",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "PZA",
                name: "TERMINAL SAN GREGORIO"
            },
            posta: "PUNTA ARENAS",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "PTI",
                name: "TIMAUKEL"
            },
            posta: "PUNTA ARENAS",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "TRP",
                name: "TORRES DEL PAINE"
            },
            posta: "PUNTA ARENAS",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "VTT",
                name: "VILLA TEHUELCHES"
            },
            posta: "PUNTA ARENAS",
            radio: "EXTREMA"
        },
        {
            localidad: {
                code: "YEN",
                name: "YENDEGAIA"
            },
            posta: "PUNTA ARENAS",
            radio: "EXTREMA"
        }
    ]
};
export default zoneResponse;