const baseResponse =
{
    status: true,
    data: {
        listaBases: [
            {
                codigo: "ANF",
                nombre: "ANTOFAGASTA",
                coordenadas: "-23.60070582993808, -70.38890430399947",
                codigoLocalidad: "ANF"
            },
            {
                codigo: "ARI",
                nombre: "ARICA",
                coordenadas: "-18.479607894645206, -70.31087581573547",
                codigoLocalidad: "ARI"
            },
            {
                codigo: "CCO",
                nombre: "CURICO",
                coordenadas: "-34.973751503475114, -71.21013239263863",
                codigoLocalidad: "CCO"
            },
            {
                codigo: "CCP",
                nombre: "CONCEPCION",
                coordenadas: "-36.803832046675524, -73.08059223749945",
                codigoLocalidad: "CCP"
            },
            {
                codigo: "CDS",
                nombre: "CENTRO DISTRIBUCION STGO",
                coordenadas: "-33.432185551580666, -70.79555565346296",
                codigoLocalidad: "SCL"
            },
            {
                codigo: "CJC",
                nombre: "CALAMA",
                coordenadas: "-22.456969180654998, -68.92797181751465",
                codigoLocalidad: "CJC"
            },
            {
                codigo: "CPO",
                nombre: "COPIAPO",
                coordenadas: "-27.36680353133383, -70.33257890115007",
                codigoLocalidad: "CPO"
            },
            {
                codigo: "CTR",
                nombre: "CASTRO",
                coordenadas: "-42.46888288570615, -73.7658983305094",
                codigoLocalidad: "CTR"
            },
            {
                codigo: "GXQ",
                nombre: "COYHAIQUE",
                coordenadas: "-45.57085644019025, -72.06837964152777",
                codigoLocalidad: "GXQ"
            },
            {
                codigo: "IQQ",
                nombre: "IQUIQUE",
                coordenadas: "-20.29018981632055, -70.12525316585574",
                codigoLocalidad: "IQQ"
            },
            {
                codigo: "KNA",
                nombre: "VINA DEL MAR",
                coordenadas: "-32.995236051467586, -71.51820862892393",
                codigoLocalidad: "KNA"
            },
            {
                codigo: "LOB",
                nombre: "LOS ANDES",
                coordenadas: "-32.83255918959657, -70.60264136494962",
                codigoLocalidad: "LOB"
            },
            {
                codigo: "LSC",
                nombre: "LA SERENA",
                coordenadas: "-29.89951734253902, -71.25759996749395",
                codigoLocalidad: "LSC"
            },
            {
                codigo: "LSQ",
                nombre: "LOS ANGELES",
                coordenadas: "-37.466428755310325, -72.35311920174465",
                codigoLocalidad: "LSQ"
            },
            {
                codigo: "LYD",
                nombre: "LOG Y DISTRIB",
                coordenadas: "-33.432185551580666, -70.79555565346296",
                codigoLocalidad: "SCL"
            },
            {
                codigo: "OVL",
                nombre: "OVALLE",
                coordenadas: "-30.580773830591053, -71.18792173062926",
                codigoLocalidad: "OVL"
            },
            {
                codigo: "PMC",
                nombre: "PUERTO MONTT",
                coordenadas: "-41.45367589150643, -72.96150825919833",
                codigoLocalidad: "PMC"
            },
            {
                codigo: "PUQ",
                nombre: "PUNTA ARENAS",
                coordenadas: "-53.16273970234558, -70.9135986146754",
                codigoLocalidad: "PUQ"
            },
            {
                codigo: "RCG",
                nombre: "RANCAGUA",
                coordenadas: "-34.16784282585715, -70.74024452088676",
                codigoLocalidad: "RCG"
            },
            {
                codigo: "SCL",
                nombre: "SANTIAGO",
                coordenadas: "-33.432185551580666, -70.79555565346296",
                codigoLocalidad: "SCL"
            },
            {
                codigo: "SL3",
                nombre: "HUB 3",
                coordenadas: "-33.432185551580666, -70.79555565346296",
                codigoLocalidad: "SCL"
            },
            {
                codigo: "SL4",
                nombre: "HUB URBANO",
                coordenadas: "-33.432185551580666, -70.79555565346296",
                codigoLocalidad: "SCL"
            },
            {
                codigo: "SL5",
                nombre: "HUB 5",
                coordenadas: "-33.432185551580666, -70.79555565346296",
                codigoLocalidad: "SCL"
            },
            {
                codigo: "VAP",
                nombre: "VALPARAISO",
                coordenadas: "-33.041848664308844, -71.62418073057394",
                codigoLocalidad: "VAP"
            },
            {
                codigo: "YAI",
                nombre: "CHILLAN",
                coordenadas: "-36.58721021807741, -72.10227550241088",
                codigoLocalidad: "YAI"
            },
            {
                codigo: "ZAL",
                nombre: "VALDIVIA",
                coordenadas: "-39.81373485648916, -73.24769202354663",
                codigoLocalidad: "ZAL"
            },
            {
                codigo: "ZCA",
                nombre: "TALCA",
                coordenadas: "-34.97674930454786, -71.21050790169275",
                codigoLocalidad: "ZCA"
            },
            {
                codigo: "ZCO",
                nombre: "TEMUCO",
                coordenadas: "-38.72661391503369, -72.57298735838732",
                codigoLocalidad: "ZCO"
            },
            {
                codigo: "ZLC",
                nombre: "LA CALERA",
                coordenadas: "-32.77304689237179, -71.18680424407319",
                codigoLocalidad: "ZLC"
            },
            {
                codigo: "ZMP",
                nombre: "MELIPILLA",
                coordenadas: "-33.678611949879816, -71.21677445939486",
                codigoLocalidad: "ZMP"
            },
            {
                codigo: "ZOS",
                nombre: "OSORNO",
                coordenadas: "-40.574391995202674, -73.13484918805766",
                codigoLocalidad: "ZOS"
            }
        ]
    }
}
export default baseResponse;