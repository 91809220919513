const trackingPushPayloadBody = {
    trackingNumber: {
        type: "String",
        maxSize: 10,
        description: "Number of tracking shipment."
    },
    event: {
        codigo: {
            type: "String",
            maxSize: 2,
            description: "Is the event code."
        },
        descripcion: {
            type: "String",
            maxSize: 20,
            description: "Is the event description."
        }
    },
    eventDate: {
        type: "String",
        maxSize: 20,
        description: "Date time of event."
    },
    comment: {
        type: "String",
        maxSize: 80,
        description: "Some important comments"
    }
};
export default trackingPushPayloadBody;