import './App.css';
import Header from './components/Header/Header';
import MenuOptions from './components/MenuOptions/MenuOptions';
import Welcome from './components/Welcome/Welcome';
import Sider from './components/Sider/Sider';

function App() {
  return (
    <div className="App">
      <Header />
      <div className="body container-fluid">
        <div className="row body-container">
          <MenuOptions />
          <Welcome />
          <Sider/>
        </div>
      </div>
    </div>
  );
}

export default App;
