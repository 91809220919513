const trackingPushSubscribeRequest = {
    endpoint: {
        lookUpToken: {
            url: {
                type: "String",
                maxSize: 100,
                description: "Endpoint where Bluex's tracking push must call to get authentication token."
            },
            headers: [
                {
                    key: {
                        type: "String",
                        maxSize: 100,
                        description: "Key of header."
                    },
                    value:  {
                        type: "String",
                        maxSize: 100,
                        description: "Value of header."
                    }
                }
            ]
        },
        postTracking: {
            url: {
                type: "String",
                maxSize: 100,
                description: "Endpoint where Bluex's tracking push must call to make the post of shipment's status."
            },
            headers: [
                {
                    key: {
                        type: "String",
                        maxSize: 100,
                        description: "Key of header."
                    },
                    value:  {
                        type: "String",
                        maxSize: 100,
                        description: "Value of header."
                    }
                }
            ]
        }
    }
};
export default trackingPushSubscribeRequest;