var pricingRangeResponse = {
    status: true,
    data: [
        {
            from: {
                name: "SANTIAGO",
                code: "SCL",
                state: {
                    description: "REG.METROP.",
                    name: "Metropolitana de Santiago",
                    code: 13
                },
                city: {
                    name: "SANTIAGO",
                    code: 13101
                },
                country: "cl"
            },
            to: {
                name: "SANTA CLARA",
                code: "LST",
                state: {
                    description: "VIII REGION",
                    name: "Bío - Bío",
                    code: 8
                },
                city: {
                    name: "LOS ANGELES",
                    code: 8301
                },
                country: "cl"
            },
            weight: [
                {
                    kilo: 0.0,
                    amount: 41114.06
                },
                {
                    kilo: 0.5,
                    amount: 41114.06
                },
                {
                    kilo: 1.0,
                    amount: 41114.06
                },
                {
                    kilo: 1.5,
                    amount: 41114.06
                },
                {
                    kilo: 2.0,
                    amount: 41114.06
                },
                {
                    kilo: 2.5,
                    amount: 41114.06
                },
                {
                    kilo: 3.0,
                    amount: 41114.06
                },
                {
                    kilo: 3.5,
                    amount: 41114.06
                },
                {
                    kilo: 4.0,
                    amount: 41114.06
                },
                {
                    kilo: 4.5,
                    amount: 41114.06
                },
                {
                    kilo: 5.0,
                    amount: 41114.06
                },
                {
                    kilo: 5.5,
                    amount: 41114.06
                },
                {
                    kilo: 6.0,
                    amount: 41114.06
                },
                {
                    kilo: 6.5,
                    amount: 41114.06
                },
                {
                    kilo: 7.0,
                    amount: 41114.06
                },
                {
                    kilo: 7.5,
                    amount: 41114.06
                },
                {
                    kilo: 8.0,
                    amount: 41114.06
                },
                {
                    kilo: 8.5,
                    amount: 41114.06
                },
                {
                    kilo: 9.0,
                    amount: 41114.06
                },
                {
                    kilo: 9.5,
                    amount: 41114.06
                },
                {
                    kilo: 10.0,
                    amount: 41114.06
                },
                {
                    kilo: 10.5,
                    amount: 41114.06
                },
                {
                    kilo: 11.0,
                    amount: 41114.06
                },
                {
                    kilo: 11.5,
                    amount: 41114.06
                },
                {
                    kilo: 12.0,
                    amount: 41114.06
                },
                {
                    kilo: 12.5,
                    amount: 41114.06
                },
                {
                    kilo: 13.0,
                    amount: 41114.06
                },
                {
                    kilo: 13.5,
                    amount: 41114.06
                },
                {
                    kilo: 14.0,
                    amount: 41114.06
                },
                {
                    kilo: 14.5,
                    amount: 41114.06
                },
                {
                    kilo: 15.0,
                    amount: 41114.06
                },
                {
                    kilo: 15.5,
                    amount: 41114.06
                },
                {
                    kilo: 16.0,
                    amount: 41114.06
                },
                {
                    kilo: 16.5,
                    amount: 41114.06
                },
                {
                    kilo: 17.0,
                    amount: 41114.06
                },
                {
                    kilo: 17.5,
                    amount: 41114.06
                },
                {
                    kilo: 18.0,
                    amount: 41114.06
                },
                {
                    kilo: 18.5,
                    amount: 41114.06
                },
                {
                    kilo: 19.0,
                    amount: 41114.06
                },
                {
                    kilo: 19.5,
                    amount: 41114.06
                },
                {
                    kilo: 20.0,
                    amount: 41114.06
                },
                {
                    kilo: 20.5,
                    amount: 41114.06
                },
                {
                    kilo: 21.0,
                    amount: 41114.06
                },
                {
                    kilo: 21.5,
                    amount: 41114.06
                },
                {
                    kilo: 22.0,
                    amount: 41114.06
                },
                {
                    kilo: 22.5,
                    amount: 41114.06
                },
                {
                    kilo: 23.0,
                    amount: 41114.06
                },
                {
                    kilo: 23.5,
                    amount: 41114.06
                },
                {
                    kilo: 24.0,
                    amount: 41114.06
                },
                {
                    kilo: 24.5,
                    amount: 41114.06
                },
                {
                    kilo: 25.0,
                    amount: 41114.06
                },
                {
                    kilo: 25.5,
                    amount: 41114.06
                },
                {
                    kilo: 26.0,
                    amount: 41114.06
                },
                {
                    kilo: 26.5,
                    amount: 41114.06
                },
                {
                    kilo: 27.0,
                    amount: 41114.06
                },
                {
                    kilo: 27.5,
                    amount: 41114.06
                },
                {
                    kilo: 28.0,
                    amount: 41114.06
                },
                {
                    kilo: 28.5,
                    amount: 41114.06
                },
                {
                    kilo: 29.0,
                    amount: 41114.06
                },
                {
                    kilo: 29.5,
                    amount: 41114.06
                },
                {
                    kilo: 30.0,
                    amount: 41114.06
                },
                {
                    kilo: 30.5,
                    amount: 41114.06
                },
                {
                    kilo: 31.0,
                    amount: 41114.06
                },
                {
                    kilo: 31.5,
                    amount: 41114.06
                },
                {
                    kilo: 32.0,
                    amount: 41114.06
                },
                {
                    kilo: 32.5,
                    amount: 41114.06
                },
                {
                    kilo: 33.0,
                    amount: 41349.0
                },
                {
                    kilo: 33.5,
                    amount: 41975.5
                },
                {
                    kilo: 34.0,
                    amount: 42602.0
                },
                {
                    kilo: 34.5,
                    amount: 43228.5
                },
                {
                    kilo: 35.0,
                    amount: 43855.0
                },
                {
                    kilo: 35.5,
                    amount: 44481.5
                },
                {
                    kilo: 36.0,
                    amount: 45108.0
                },
                {
                    kilo: 36.5,
                    amount: 45734.5
                },
                {
                    kilo: 37.0,
                    amount: 46361.0
                },
                {
                    kilo: 37.5,
                    amount: 46987.5
                },
                {
                    kilo: 38.0,
                    amount: 47614.0
                },
                {
                    kilo: 38.5,
                    amount: 48240.5
                },
                {
                    kilo: 39.0,
                    amount: 48867.0
                },
                {
                    kilo: 39.5,
                    amount: 49493.5
                },
                {
                    kilo: 40.0,
                    amount: 50120.0
                },
                {
                    kilo: 40.5,
                    amount: 50746.5
                },
                {
                    kilo: 41.0,
                    amount: 51373.0
                },
                {
                    kilo: 41.5,
                    amount: 51999.5
                },
                {
                    kilo: 42.0,
                    amount: 52626.0
                },
                {
                    kilo: 42.5,
                    amount: 53252.5
                },
                {
                    kilo: 43.0,
                    amount: 53879.0
                },
                {
                    kilo: 43.5,
                    amount: 54505.5
                },
                {
                    kilo: 44.0,
                    amount: 55132.0
                },
                {
                    kilo: 44.5,
                    amount: 55758.5
                },
                {
                    kilo: 45.0,
                    amount: 56385.0
                },
                {
                    kilo: 45.5,
                    amount: 57011.5
                },
                {
                    kilo: 46.0,
                    amount: 57638.0
                },
                {
                    kilo: 46.5,
                    amount: 58264.5
                },
                {
                    kilo: 47.0,
                    amount: 58891.0
                },
                {
                    kilo: 47.5,
                    amount: 59517.5
                },
                {
                    kilo: 48.0,
                    amount: 60144.0
                },
                {
                    kilo: 48.5,
                    amount: 60770.5
                },
                {
                    kilo: 49.0,
                    amount: 61397.0
                },
                {
                    kilo: 49.5,
                    amount: 62023.5
                },
                {
                    kilo: 50.0,
                    amount: 62650.0
                },
                {
                    kilo: 50.5,
                    amount: 51005.0
                },
                {
                    kilo: 51.0,
                    amount: 51510.0
                },
                {
                    kilo: 51.5,
                    amount: 52015.0
                },
                {
                    kilo: 52.0,
                    amount: 52520.0
                },
                {
                    kilo: 52.5,
                    amount: 53025.0
                },
                {
                    kilo: 53.0,
                    amount: 53530.0
                },
                {
                    kilo: 53.5,
                    amount: 54035.0
                },
                {
                    kilo: 54.0,
                    amount: 54540.0
                },
                {
                    kilo: 54.5,
                    amount: 55045.0
                },
                {
                    kilo: 55.0,
                    amount: 55550.0
                }
            ]
        }
    ]
};
export default pricingRangeResponse;