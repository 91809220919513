const exceptionSchema = {
    status: {
        type: "Boolean",
        description: "Response status."
    },
    data: {
        type: "Array[String]",
        description: "List of exceptions."
    }
};
export default exceptionSchema;