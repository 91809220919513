const labelSchemaResponse =
{
    status: {
        type: "Boolean",
        description: "Response status."
    },
    data: {
        base64: {
            type: "String",
            description: "Content of label."
        }
    }
};

export default labelSchemaResponse;