const trackingPullResponse ={
    status: true,
    data: {
        idEspecieValorada: "230663789",
        nroOS: "6755220286",
        fechaCreacion: "2020-11-02 10:31:37",
        numeroReferencia: "2515364",
        ctaCte: "@ctaCte",
        origen: {
            region: "Metropolitana de San",
            comuna: "SAN BERNARDO",
            codigoLocalidad: "SBD",
            nombreLocalidad: "SAN BERNARDO",
            direccion: "@direccion"
        },
        destino: {
            region: "Los Ríos",
            comuna: "VALDIVIA",
            codigoLocalidad: "ZAL",
            nombreLocalidad: "VALDIVIA",
            direccion: "@direccion"
        },
        codigoTipoServicio: "72",
        nombreTipoServicio: "EXPRESO-P",
        codigoProducto: "P",
        codigoPersona: "@codigoPersona",
        pesoFisico: ".46",
        pesoVolumen: "2.92",
        valorFlete: "3915",
        cantidadPiezas: "1",
        macroEstadoActual: "Entregado",
        fechaMacroEstadoActual: "25/11/2020 17:08",
        tpeecdg: "D9",
        observaciones: null,
        pinchazos: [
            {
                tipoMovimiento: {
                    codigo: "CBH",
                    descripcion: "CUBICADO POR HUMANO"
                },
                codigoPieza: "33006755220286",
                fechaHora: "2020-12-02 18:24:47",
                cantidadPiezas: "1",
                observacion: null
            },
            {
                tipoMovimiento: {
                    codigo: "DL",
                    descripcion: "ENTREGADO"
                },
                codigoPieza: "33006755220286",
                fechaHora: "2020-11-25 17:17:40",
                cantidadPiezas: "1",
                observacion: "@observacion"
            },
            {
                tipoMovimiento: {
                    codigo: "LD",
                    descripcion: "EN REPARTO"
                },
                codigoPieza: "33006755220286",
                fechaHora: "2020-11-25 09:59:02",
                cantidadPiezas: "1",
                observacion: null
            },
            {
                tipoMovimiento: {
                    codigo: "DA",
                    descripcion: "ARRIBADO"
                },
                codigoPieza: "33006755220286",
                fechaHora: "2020-11-25 07:46:52",
                cantidadPiezas: "1",
                observacion: null
            },
            {
                tipoMovimiento: {
                    codigo: "IC",
                    descripcion: "INGRESO CAMION"
                },
                codigoPieza: "33006755220286",
                fechaHora: "2020-11-24 16:20:11",
                cantidadPiezas: "1",
                observacion: "6773796993MELI"
            },
            {
                tipoMovimiento: {
                    codigo: "MCR",
                    descripcion: "MOVIMIENTO DE CLASIFICACION DE RUTEO"
                },
                codigoPieza: "33006755220286",
                fechaHora: "2020-11-24 10:47:56",
                cantidadPiezas: "1",
                observacion: null
            },
            {
                tipoMovimiento: {
                    codigo: "DA",
                    descripcion: "ARRIBADO"
                },
                codigoPieza: "33006755220286",
                fechaHora: "2020-11-24 06:09:58",
                cantidadPiezas: "1",
                observacion: null
            },
            {
                tipoMovimiento: {
                    codigo: "IC",
                    descripcion: "INGRESO CAMION"
                },
                codigoPieza: "33006755220286",
                fechaHora: "2020-11-23 16:45:44",
                cantidadPiezas: "1",
                observacion: "LSQ"
            },
            {
                tipoMovimiento: {
                    codigo: "SC",
                    descripcion: "SOLUCIONADO Y ENVIADO A DESTINATARIO"
                },
                codigoPieza: "33006755220286",
                fechaHora: "2020-11-23 13:27:55",
                cantidadPiezas: "1",
                observacion: "A RUTA "
            },
            {
                tipoMovimiento: {
                    codigo: "SQ",
                    descripcion: "SOLUCION QUIEBRE DE SERVICIO"
                },
                codigoPieza: "33006755220286",
                fechaHora: "2020-11-23 08:47:46",
                cantidadPiezas: "1",
                observacion: "SC_OD N: 3578995"
            },
            {
                tipoMovimiento: {
                    codigo: "SQ",
                    descripcion: "SOLUCION QUIEBRE DE SERVICIO"
                },
                codigoPieza: "33006755220286",
                fechaHora: "2020-11-20 19:28:51",
                cantidadPiezas: "1",
                observacion: "DR N: 3578995"
            },
            {
                tipoMovimiento: {
                    codigo: "DV",
                    descripcion: "EN ESPERA EN BOD. DEV."
                },
                codigoPieza: "33006755220286",
                fechaHora: "2020-11-13 12:36:59",
                cantidadPiezas: "1",
                observacion: "JAULA 06"
            },
            {
                tipoMovimiento: {
                    codigo: "DV",
                    descripcion: "EN ESPERA EN BOD. DEV."
                },
                codigoPieza: "33006755220286",
                fechaHora: "2020-11-06 12:40:31",
                cantidadPiezas: "1",
                observacion: "JAULA"
            },
            {
                tipoMovimiento: {
                    codigo: "TS",
                    descripcion: "PARA SOLUCION"
                },
                codigoPieza: "33006755220286",
                fechaHora: "2020-11-05 14:48:11",
                cantidadPiezas: "1",
                observacion: "BA"
            },
            {
                tipoMovimiento: {
                    codigo: "BC",
                    descripcion: "EN BODEGA DEVOLUCION"
                },
                codigoPieza: "33006755220286",
                fechaHora: "2020-11-05 14:46:46",
                cantidadPiezas: "1",
                observacion: null
            },
            {
                tipoMovimiento: {
                    codigo: "BA",
                    descripcion: "FALTAN DATOS PARA LA ENTREGA"
                },
                codigoPieza: "33006755220286",
                fechaHora: "2020-11-05 11:49:37",
                cantidadPiezas: "1",
                observacion: "LLAMANDO A CLIENTE"
            },
            {
                tipoMovimiento: {
                    codigo: "CBM",
                    descripcion: "CUBICADO POR MAQUINA"
                },
                codigoPieza: "33006755220286",
                fechaHora: "2020-11-04 06:08:21",
                cantidadPiezas: "1",
                observacion: null
            },
            {
                tipoMovimiento: {
                    codigo: "DA",
                    descripcion: "ARRIBADO"
                },
                codigoPieza: "33006755220286",
                fechaHora: "2020-11-04 05:18:00",
                cantidadPiezas: "1",
                observacion: null
            },
            {
                tipoMovimiento: {
                    codigo: "PUH",
                    descripcion: "PICK UP HUB"
                },
                codigoPieza: "33006755220286",
                fechaHora: "2020-11-03 20:11:26",
                cantidadPiezas: "1",
                observacion: "E:1000-SP:026-PZA:1"
            },
            {
                tipoMovimiento: {
                    codigo: "MDR",
                    descripcion: "MOVIMIENTO DE DETALLE DE RUTEO"
                },
                codigoPieza: "33006755220286",
                fechaHora: "2020-11-03 20:10:38",
                cantidadPiezas: "1",
                observacion: "SORTER - RUTEO"
            },
            {
                tipoMovimiento: {
                    codigo: "PU",
                    descripcion: "RETIRADO"
                },
                codigoPieza: "33006755220286",
                fechaHora: "2020-11-03 09:07:18",
                cantidadPiezas: "1",
                observacion: "LIPPI"
            },
            {
                tipoMovimiento: {
                    codigo: "FI",
                    descripcion: "GUIA EN DIGITACION"
                },
                codigoPieza: "33006755220286",
                fechaHora: "2020-11-02 10:31:37",
                cantidadPiezas: "1",
                observacion: null
            },
            {
                tipoMovimiento: {
                    codigo: "GE",
                    descripcion: "GUIA ENVIADA"
                },
                codigoPieza: "33006755220286",
                fechaHora: "2020-11-02 10:31:37",
                cantidadPiezas: "1",
                observacion: "WEB - GUIA ENVIADA"
            }
        ]
    }
}
export default trackingPullResponse;