var geoResponse =
{
    status: true,
    data: [
        {
            id: "CL",
            name: "CHILE",
            states: [
                {
                    name: "Arica y Parinacota",
                    code: 15,
                    cities: [
                        {
                            name: "ARICA",
                            code: 15101,
                            districts: [
                                {
                                    name: "CODPA",
                                    code: "ACZ"
                                },
                                {
                                    name: "GUANACAHUA",
                                    code: "AGN"
                                },
                                {
                                    name: "MOLINOS",
                                    code: "AMO"
                                },
                                {
                                    name: "ARICA",
                                    code: "ARI"
                                },
                                {
                                    name: "ESQUINA",
                                    code: "AQE"
                                },
                                {
                                    name: "AUSIPAR",
                                    code: "APR"
                                },
                                {
                                    name: "ALTO RAMIREZ",
                                    code: "ARZ"
                                },
                                {
                                    name: "TIMAR",
                                    code: "ATI"
                                },
                                {
                                    name: "SOBRAYA",
                                    code: "AYA"
                                },
                                {
                                    name: "SORA",
                                    code: "SOR"
                                },
                                {
                                    name: "RANCHO ARICA",
                                    code: "RAR"
                                },
                                {
                                    name: "POCONCHILE",
                                    code: "POC"
                                },
                                {
                                    name: "CUZ CUZ - ARICA",
                                    code: "CZZ"
                                },
                                {
                                    name: "AZAPA",
                                    code: "AZA"
                                },
                                {
                                    name: "VILLA FRONTERA",
                                    code: "AVF"
                                }
                            ]
                        },
                        {
                            name: "CAMARONES",
                            code: 15102,
                            districts: [
                                {
                                    name: "CAMARONES - ARICA",
                                    code: "CAM"
                                },
                                {
                                    name: "CUYA",
                                    code: "ZYA"
                                },
                                {
                                    name: "SUCA",
                                    code: "UCA"
                                },
                                {
                                    name: "QUIPINTA",
                                    code: "QPT"
                                },
                                {
                                    name: "MINIMINE",
                                    code: "MMN"
                                },
                                {
                                    name: "MINITA",
                                    code: "MIY"
                                },
                                {
                                    name: "CALETA CAMARONES",
                                    code: "CTM"
                                },
                                {
                                    name: "CALETA CHICA",
                                    code: "ICC"
                                }
                            ]
                        },
                        {
                            name: "GENERAL LAGOS",
                            code: 15202,
                            districts: [
                                {
                                    name: "COSAPILLA",
                                    code: "ACI"
                                },
                                {
                                    name: "GENERAL LAGOS",
                                    code: "LGR"
                                },
                                {
                                    name: "VISVIRI",
                                    code: "VIV"
                                },
                                {
                                    name: "VILLA INDUSTRIAL",
                                    code: "VIU"
                                },
                                {
                                    name: "NASAHUENTO",
                                    code: "NSH"
                                },
                                {
                                    name: "GUACOLLO",
                                    code: "GLL"
                                },
                                {
                                    name: "AGUAS CALIENTES ",
                                    code: "ATS"
                                },
                                {
                                    name: "AZUFRERA TACORA",
                                    code: "AZT"
                                }
                            ]
                        },
                        {
                            name: "PUTRE",
                            code: 15201,
                            districts: [
                                {
                                    name: "BRITANIA",
                                    code: "ABT"
                                },
                                {
                                    name: "CHOQUELIMPIE",
                                    code: "ACL"
                                },
                                {
                                    name: "CORONEL ALCERRECA",
                                    code: "ACN"
                                },
                                {
                                    name: "GUALLATIRI",
                                    code: "ALL"
                                },
                                {
                                    name: "PARINACOTA",
                                    code: "API"
                                },
                                {
                                    name: "TARUGUIRE",
                                    code: "TRG"
                                },
                                {
                                    name: "TIMALCHACA",
                                    code: "TCA"
                                },
                                {
                                    name: "PUTRE",
                                    code: "PTR"
                                },
                                {
                                    name: "JURASI",
                                    code: "JRI"
                                },
                                {
                                    name: "ITISA",
                                    code: "ITT"
                                },
                                {
                                    name: "LAGO CHUNGARA",
                                    code: "CGA"
                                },
                                {
                                    name: "BELEN",
                                    code: "BNN"
                                },
                                {
                                    name: "TERMAS DE CHITUNE",
                                    code: "ATQ"
                                },
                                {
                                    name: "TIGNAMAR",
                                    code: "ATG"
                                },
                                {
                                    name: "SOCOROMA",
                                    code: "ASO"
                                },
                                {
                                    name: "PACHAMA",
                                    code: "APH"
                                },
                                {
                                    name: "CHACUYO",
                                    code: "ACC"
                                },
                                {
                                    name: "CHAPIQUINA",
                                    code: "ACP"
                                },
                                {
                                    name: "PUQUISA",
                                    code: "PQS"
                                },
                                {
                                    name: "MAILLKU",
                                    code: "MKU"
                                },
                                {
                                    name: "CHICAYA",
                                    code: "CYH"
                                },
                                {
                                    name: "COPAQUILLA",
                                    code: "CQY"
                                },
                                {
                                    name: "CAQUENA",
                                    code: "ACQ"
                                }
                            ]
                        }
                    ]
                },
                {
                    name: "Tarapacá",
                    code: 1,
                    cities: [
                        {
                            name: "ALTO HOSPICIO",
                            code: 1107,
                            districts: [
                                {
                                    name: "ALTO HOSPICIO",
                                    code: "AHP"
                                }
                            ]
                        },
                        {
                            name: "CAMINA",
                            code: 1402,
                            districts: [
                                {
                                    name: "CAMINA",
                                    code: "CMN"
                                },
                                {
                                    name: "ALPAJERES",
                                    code: "PJR"
                                },
                                {
                                    name: "CARITAYA",
                                    code: "RTA"
                                },
                                {
                                    name: "CORSA",
                                    code: "QCR"
                                },
                                {
                                    name: "PALCA",
                                    code: "PKA"
                                },
                                {
                                    name: "PISAGUA",
                                    code: "PIS"
                                },
                                {
                                    name: "ALTUZA",
                                    code: "IAL"
                                },
                                {
                                    name: "CHAPIQUITA",
                                    code: "ICY"
                                },
                                {
                                    name: "NAMA",
                                    code: "NMA"
                                },
                                {
                                    name: "VILAVILA",
                                    code: "IVV"
                                },
                                {
                                    name: "CALATAMBO",
                                    code: "CTA"
                                }
                            ]
                        },
                        {
                            name: "COLCHANE",
                            code: 1403,
                            districts: [
                                {
                                    name: "ANAGUANI",
                                    code: "ANG"
                                },
                                {
                                    name: "SOTOCA",
                                    code: "STA"
                                },
                                {
                                    name: "ENQUELGA",
                                    code: "QEP"
                                },
                                {
                                    name: "MOCOMUCANE",
                                    code: "MCL"
                                },
                                {
                                    name: "ISLUGA",
                                    code: "IIS"
                                },
                                {
                                    name: "CARIQUIMA",
                                    code: "ICQ"
                                },
                                {
                                    name: "CHIAPA",
                                    code: "ICI"
                                },
                                {
                                    name: "COLCHANE",
                                    code: "COE"
                                },
                                {
                                    name: "ANCUAQUE",
                                    code: "ANQ"
                                },
                                {
                                    name: "ANCOVINTO",
                                    code: "CVT"
                                }
                            ]
                        },
                        {
                            name: "HUARA",
                            code: 1404,
                            districts: [
                                {
                                    name: "HUARA",
                                    code: "HRA"
                                },
                                {
                                    name: "LIRIMA",
                                    code: "ILI"
                                },
                                {
                                    name: "POROMA",
                                    code: "QPR"
                                },
                                {
                                    name: "COLLACAGUA",
                                    code: "QLG"
                                },
                                {
                                    name: "TARAPACA",
                                    code: "ITP"
                                },
                                {
                                    name: "PACHICA",
                                    code: "IPH"
                                },
                                {
                                    name: "MOCHA",
                                    code: "IMO"
                                },
                                {
                                    name: "CANCOSA",
                                    code: "ICO"
                                },
                                {
                                    name: "CHUSMISA",
                                    code: "ICA"
                                }
                            ]
                        },
                        {
                            name: "IQUIQUE",
                            code: 1101,
                            districts: [
                                {
                                    name: "CALETA BUENA - IQUIQ",
                                    code: "ICB"
                                },
                                {
                                    name: "MINA FAKIR",
                                    code: "IMF"
                                },
                                {
                                    name: "MINA LOBOS",
                                    code: "IML"
                                },
                                {
                                    name: "IQUIQUE",
                                    code: "IQQ"
                                },
                                {
                                    name: "PLAYA BLANCA",
                                    code: "ZPL"
                                },
                                {
                                    name: "RIO SECO",
                                    code: "RSC"
                                },
                                {
                                    name: "SAN MARCOS - IQUIQUE",
                                    code: "RFO"
                                },
                                {
                                    name: "PUNTA LOBOS",
                                    code: "QLB"
                                },
                                {
                                    name: "CHIPANA",
                                    code: "PQA"
                                },
                                {
                                    name: "PUERTO PATILLOS",
                                    code: "IPP"
                                }
                            ]
                        },
                        {
                            name: "PICA",
                            code: 1405,
                            districts: [
                                {
                                    name: "MINERA QUEBRADA BLAN",
                                    code: "BQM"
                                },
                                {
                                    name: "COLLAHUASI",
                                    code: "CLH"
                                },
                                {
                                    name: "COLONIA PINTADOS",
                                    code: "ICP"
                                },
                                {
                                    name: "LA HUAICA",
                                    code: "LGC"
                                },
                                {
                                    name: "OFICINA VICTORIA",
                                    code: "IOV"
                                },
                                {
                                    name: "PICA",
                                    code: "OPC"
                                },
                                {
                                    name: "GUATACONDO",
                                    code: "ZRC"
                                },
                                {
                                    name: "PUQUIOS - IQUIQUE",
                                    code: "PQU"
                                },
                                {
                                    name: "MATILLA",
                                    code: "MTA"
                                }
                            ]
                        },
                        {
                            name: "POZO ALMONTE",
                            code: 1401,
                            districts: [
                                {
                                    name: "FUERTE BAQUEDANO",
                                    code: "IFB"
                                },
                                {
                                    name: "TAMBILLO",
                                    code: "IUJ"
                                },
                                {
                                    name: "MAMINA",
                                    code: "MAN"
                                },
                                {
                                    name: "POZO ALMONTE",
                                    code: "PAM"
                                },
                                {
                                    name: "LA TIRANA",
                                    code: "LTI"
                                },
                                {
                                    name: "MACAYA",
                                    code: "IMA"
                                }
                            ]
                        }
                    ]
                },
                {
                    name: "Antofagasta",
                    code: 2,
                    cities: [
                        {
                            name: "ANTOFAGASTA",
                            code: 2101,
                            districts: [
                                {
                                    name: "AGUA VERDE",
                                    code: "AAV"
                                },
                                {
                                    name: "ISLA SANTA MARIA",
                                    code: "ZSM"
                                },
                                {
                                    name: "RANCHO ANTOFAGASTA",
                                    code: "RAN"
                                },
                                {
                                    name: "PARANAL",
                                    code: "PRL"
                                },
                                {
                                    name: "MANTOS BLANCOS",
                                    code: "MTB"
                                },
                                {
                                    name: "MINA LOMAS BAYAS",
                                    code: "MIN"
                                },
                                {
                                    name: "LA NEGRA",
                                    code: "LNA"
                                },
                                {
                                    name: "ESTACION AGUAS BLANC",
                                    code: "AGC"
                                },
                                {
                                    name: "EL WAY",
                                    code: "AEW"
                                },
                                {
                                    name: "ESTACION AUGUSTA VIC",
                                    code: "AEV"
                                },
                                {
                                    name: "ENSUENO",
                                    code: "AEO"
                                },
                                {
                                    name: "EL MEDANO",
                                    code: "AEM"
                                },
                                {
                                    name: "ESTACION LOS VIENTOS",
                                    code: "AEL"
                                },
                                {
                                    name: "ESTACION LA RIOJA",
                                    code: "AEJ"
                                },
                                {
                                    name: "ESTACION O`HIGGINS",
                                    code: "AEH"
                                },
                                {
                                    name: "BLANCO ENCALADA",
                                    code: "ABL"
                                },
                                {
                                    name: "CERRO PARANAL",
                                    code: "CPL"
                                },
                                {
                                    name: "CARMEN ALTO",
                                    code: "CIN"
                                },
                                {
                                    name: "PAPOSO",
                                    code: "APP"
                                },
                                {
                                    name: "ESTACION PALESTINA",
                                    code: "APE"
                                },
                                {
                                    name: "PUERTO COLOSO",
                                    code: "APC"
                                },
                                {
                                    name: "ANTOFAGASTA",
                                    code: "ANF"
                                },
                                {
                                    name: "ESTACION MONTURAQUI",
                                    code: "AMQ"
                                },
                                {
                                    name: "JUAN LOPEZ",
                                    code: "AJL"
                                },
                                {
                                    name: "CALETA BOTIJA",
                                    code: "AJJ"
                                },
                                {
                                    name: "ESTACION AGUA BUENA",
                                    code: "AEB"
                                },
                                {
                                    name: "ESTACION CATALINA",
                                    code: "AEC"
                                },
                                {
                                    name: "EL GUANACO",
                                    code: "AEG"
                                },
                                {
                                    name: "CALETA EL COBRE",
                                    code: "AEE"
                                },
                                {
                                    name: "ESTACION SOCOMPA",
                                    code: "ESA"
                                },
                                {
                                    name: "AZUFRERA",
                                    code: "AZF"
                                },
                                {
                                    name: "EX OFICINA FLOR DE C",
                                    code: "AXF"
                                },
                                {
                                    name: "EX OFICINA CHILE",
                                    code: "AXC"
                                },
                                {
                                    name: "EX OFICINA ALEMANIA",
                                    code: "AXA"
                                },
                                {
                                    name: "ESTACION VARILLA",
                                    code: "AVE"
                                },
                                {
                                    name: "AZUFRERA PLATO DE SO",
                                    code: "ATM"
                                },
                                {
                                    name: "SAN CRISTOBAL",
                                    code: "ASC"
                                },
                                {
                                    name: "ESTACION PAN DE AZUC",
                                    code: "APZ"
                                },
                                {
                                    name: "CERRO MORENO",
                                    code: "ACM"
                                },
                                {
                                    name: "BAQUEDANO",
                                    code: "BQO"
                                }
                            ]
                        },
                        {
                            name: "CALAMA",
                            code: 2201,
                            districts: [
                                {
                                    name: "CASPANA",
                                    code: "CAP"
                                },
                                {
                                    name: "AYQUINA",
                                    code: "CAY"
                                },
                                {
                                    name: "CONCHI   ",
                                    code: "CCI"
                                },
                                {
                                    name: "CUPO",
                                    code: "CEY"
                                },
                                {
                                    name: "CALAMA",
                                    code: "CJC"
                                },
                                {
                                    name: "TOCONCE",
                                    code: "COV"
                                },
                                {
                                    name: "TUINA",
                                    code: "CTN"
                                },
                                {
                                    name: "CHUQUICAMATA",
                                    code: "QUI"
                                },
                                {
                                    name: "MINA RADOMIRO TOMIC",
                                    code: "MRT"
                                },
                                {
                                    name: "MINA EL LITIO",
                                    code: "MLT"
                                },
                                {
                                    name: "MINA GABY",
                                    code: "MGY"
                                },
                                {
                                    name: "MINA FARIDE",
                                    code: "MFD"
                                },
                                {
                                    name: "MINA CERRO DOMINADOR",
                                    code: "MCD"
                                },
                                {
                                    name: "LINZOR",
                                    code: "LZR"
                                },
                                {
                                    name: "MINA EL ABRA",
                                    code: "ELA"
                                },
                                {
                                    name: "LASANA",
                                    code: "CXL"
                                },
                                {
                                    name: "TURI",
                                    code: "TTR"
                                },
                                {
                                    name: "MINERA SPENCER",
                                    code: "SPM"
                                },
                                {
                                    name: "SAN JOSE  ",
                                    code: "SJE"
                                },
                                {
                                    name: "SANTA ROSA - CALAMA",
                                    code: "CSR"
                                },
                                {
                                    name: "INCACALIRI",
                                    code: "CJI"
                                },
                                {
                                    name: "CHIUCHIU",
                                    code: "CHU"
                                },
                                {
                                    name: "CONCHI VIEJO",
                                    code: "CCV"
                                },
                                {
                                    name: "ESTACION CERRITOS BAYOS",
                                    code: "CCB"
                                },
                                {
                                    name: "BANOS DE TURI",
                                    code: "CBT"
                                }
                            ]
                        },
                        {
                            name: "MARIA ELENA",
                            code: 2302,
                            districts: [
                                {
                                    name: "ESTACION MIRAJE",
                                    code: "AET"
                                },
                                {
                                    name: "OFICINA PEDRO DE VAL",
                                    code: "ALQ"
                                },
                                {
                                    name: "OFICINA VERGARA",
                                    code: "OVR"
                                },
                                {
                                    name: "MARIA ELENA SOQUIMICH",
                                    code: "MEQ"
                                },
                                {
                                    name: "MARIA ELENA",
                                    code: "MAE"
                                },
                                {
                                    name: "QUILLAGUA",
                                    code: "CQG"
                                }
                            ]
                        },
                        {
                            name: "MEJILLONES",
                            code: 2102,
                            districts: [
                                {
                                    name: "HORNITOS - ANTOFAGAS",
                                    code: "AHO"
                                },
                                {
                                    name: "MEJILLONES",
                                    code: "MJS"
                                }
                            ]
                        },
                        {
                            name: "OLLAGUE",
                            code: 2202,
                            districts: [
                                {
                                    name: "AMINCHA",
                                    code: "AMC"
                                },
                                {
                                    name: "AUCANQUILCHA",
                                    code: "QCA"
                                },
                                {
                                    name: "SAN PEDRO DE ATACAMA",
                                    code: "SPD"
                                },
                                {
                                    name: "OLLAGUE",
                                    code: "OLL"
                                },
                                {
                                    name: "ESTACION CEBOLLAR",
                                    code: "CEB"
                                },
                                {
                                    name: "ESTACION CARCOTE",
                                    code: "ETC"
                                },
                                {
                                    name: "POLAN",
                                    code: "CXP"
                                },
                                {
                                    name: "LEQUENA",
                                    code: "CLQ"
                                },
                                {
                                    name: "CALACHUZ",
                                    code: "CCZ"
                                },
                                {
                                    name: "ASCOTAN",
                                    code: "ASN"
                                }
                            ]
                        },
                        {
                            name: "SAN PEDRO DE ATACAMA",
                            code: 2203,
                            districts: [
                                {
                                    name: "AGUAS BLANCAS",
                                    code: "CAA"
                                },
                                {
                                    name: "TOCONAO",
                                    code: "TCO"
                                },
                                {
                                    name: "SAN PEDRO DE ATACAMA",
                                    code: "SPX"
                                },
                                {
                                    name: "PURITAMA",
                                    code: "RMA"
                                },
                                {
                                    name: "RIO GRANDE - CALAMA",
                                    code: "RGS"
                                },
                                {
                                    name: "PEINE",
                                    code: "PNI"
                                },
                                {
                                    name: "ALITAR",
                                    code: "CTZ"
                                },
                                {
                                    name: "TILO POZO",
                                    code: "CTP"
                                },
                                {
                                    name: "TALABRE",
                                    code: "CTB"
                                },
                                {
                                    name: "SOCAIRE",
                                    code: "CSE"
                                },
                                {
                                    name: "SAN BARTOLO",
                                    code: "CSB"
                                },
                                {
                                    name: "CAMAR",
                                    code: "CMR"
                                },
                                {
                                    name: "TILOMONTE",
                                    code: "CIL"
                                }
                            ]
                        },
                        {
                            name: "SIERRA GORDA",
                            code: 2103,
                            districts: [
                                {
                                    name: "CARACOLES",
                                    code: "CCR"
                                },
                                {
                                    name: "SIERRA GORDA",
                                    code: "SGD"
                                },
                                {
                                    name: "MINERA ZALDIVAR",
                                    code: "MZL"
                                },
                                {
                                    name: "FLOR DEL DESIERTO",
                                    code: "FDT"
                                },
                                {
                                    name: "MELLIZOS",
                                    code: "MLZ"
                                },
                                {
                                    name: "CENTINELA",
                                    code: "CDQ"
                                }
                            ]
                        },
                        {
                            name: "TALTAL",
                            code: 2104,
                            districts: [
                                {
                                    name: "ALTAMIRA",
                                    code: "AMR"
                                },
                                {
                                    name: "LA POLVORA",
                                    code: "LPV"
                                },
                                {
                                    name: "CIFUNCHO",
                                    code: "CFI"
                                },
                                {
                                    name: "TALTAL",
                                    code: "TTL"
                                },
                                {
                                    name: "ESMERALDA",
                                    code: "SDA"
                                }
                            ]
                        },
                        {
                            name: "TOCOPILLA",
                            code: 2301,
                            districts: [
                                {
                                    name: "CALETA BOY",
                                    code: "ACB"
                                },
                                {
                                    name: "CALETA BUENA - ANTOF",
                                    code: "ACE"
                                },
                                {
                                    name: "TOCOPILLA",
                                    code: "TOC"
                                },
                                {
                                    name: "MINA MICHILLA",
                                    code: "MIC"
                                },
                                {
                                    name: "MICHILLA",
                                    code: "AMI"
                                },
                                {
                                    name: "COBIJA",
                                    code: "ACJ"
                                }
                            ]
                        }
                    ]
                },
                {
                    name: "Atacama",
                    code: 3,
                    cities: [
                        {
                            name: "ALTO DEL CARMEN",
                            code: 3302,
                            districts: [
                                {
                                    name: "ALTO DEL CARMEN",
                                    code: "ADC"
                                },
                                {
                                    name: "LA HIGUERA",
                                    code: "LHI"
                                },
                                {
                                    name: "CONAY",
                                    code: "VCO"
                                },
                                {
                                    name: "JUNTA VALERIANO",
                                    code: "VJU"
                                },
                                {
                                    name: "LAGUNA GRANDE",
                                    code: "ZTA"
                                },
                                {
                                    name: "EL NEVADO",
                                    code: "ZMJ"
                                },
                                {
                                    name: "SAN FELIX",
                                    code: "VSA"
                                },
                                {
                                    name: "LA HIGUERITA",
                                    code: "VLH"
                                },
                                {
                                    name: "LA ARENA ",
                                    code: "VLA"
                                },
                                {
                                    name: "EL TRANSITO - SERENA",
                                    code: "VET"
                                },
                                {
                                    name: "EL RETAMO",
                                    code: "TMO"
                                }
                            ]
                        },
                        {
                            name: "CALDERA",
                            code: 3102,
                            districts: [
                                {
                                    name: "BAHIA INGLESA",
                                    code: "CBH"
                                },
                                {
                                    name: "CALDERA",
                                    code: "CLR"
                                },
                                {
                                    name: "PUERTO VIEJO",
                                    code: "CPV"
                                },
                                {
                                    name: "RANCHO CALDERA",
                                    code: "RCD"
                                }
                            ]
                        },
                        {
                            name: "CHANARAL",
                            code: 3201,
                            districts: [
                                {
                                    name: "FLAMENCO",
                                    code: "CFL"
                                },
                                {
                                    name: "PAN DE AZUCAR - COPI",
                                    code: "CPZ"
                                },
                                {
                                    name: "MINA ROSARIO",
                                    code: "RSO"
                                },
                                {
                                    name: "PLAYA REFUGIO",
                                    code: "QPO"
                                },
                                {
                                    name: "MINA LA ESTRELLA",
                                    code: "QME"
                                },
                                {
                                    name: "MINA DICHOSA",
                                    code: "QMD"
                                },
                                {
                                    name: "OBISPITO",
                                    code: "OBT"
                                },
                                {
                                    name: "CHANARAL - COPIAPO",
                                    code: "CHN"
                                },
                                {
                                    name: "PUERTO FINO",
                                    code: "CPF"
                                }
                            ]
                        },
                        {
                            name: "COPIAPO",
                            code: 3101,
                            districts: [
                                {
                                    name: "BARRANQUILLAS",
                                    code: "BQQ"
                                },
                                {
                                    name: "CALETA PAJONAL",
                                    code: "QCP"
                                },
                                {
                                    name: "TOTORAL",
                                    code: "CTX"
                                },
                                {
                                    name: "COPIAPO",
                                    code: "CPO"
                                },
                                {
                                    name: "HACIENDA CASTILLA",
                                    code: "CHX"
                                },
                                {
                                    name: "CALETA DEL MEDIO",
                                    code: "CCM"
                                }
                            ]
                        },
                        {
                            name: "DIEGO DE ALMAGRO",
                            code: 3202,
                            districts: [
                                {
                                    name: "FINCA DE CHANARAL",
                                    code: "CFC"
                                },
                                {
                                    name: "VEGAS DE CHANARAL AL",
                                    code: "QVC"
                                },
                                {
                                    name: "TERMAS DE RIO NEGRO",
                                    code: "QTR"
                                },
                                {
                                    name: "EL PINO",
                                    code: "QPN"
                                },
                                {
                                    name: "LA OLA",
                                    code: "QLA"
                                },
                                {
                                    name: "BOCAMINA",
                                    code: "QBQ"
                                },
                                {
                                    name: "POTRERILLOS",
                                    code: "PTS"
                                },
                                {
                                    name: "MONTANDON",
                                    code: "MNN"
                                },
                                {
                                    name: "LLANTA",
                                    code: "LLT"
                                },
                                {
                                    name: "INCA DE ORO",
                                    code: "IRO"
                                },
                                {
                                    name: "EL SALVADOR",
                                    code: "ESR"
                                },
                                {
                                    name: "EL SALADO",
                                    code: "ESL"
                                },
                                {
                                    name: "DIEGO DE ALMAGRO",
                                    code: "DAG"
                                },
                                {
                                    name: "MINA CHIVATO",
                                    code: "CLC"
                                }
                            ]
                        },
                        {
                            name: "FREIRINA",
                            code: 3303,
                            districts: [
                                {
                                    name: "FREIRINA",
                                    code: "FRN"
                                },
                                {
                                    name: "MINA ALGARROBO",
                                    code: "VMI"
                                },
                                {
                                    name: "MAITENCILLO - CALERA",
                                    code: "VMT"
                                },
                                {
                                    name: "LABRAR",
                                    code: "VLB"
                                },
                                {
                                    name: "CALETA SARCO",
                                    code: "TSC"
                                },
                                {
                                    name: "CARRIZALILLO",
                                    code: "VCI"
                                },
                                {
                                    name: "LA FRAGUITA",
                                    code: "VFR"
                                },
                                {
                                    name: "CALETA CHANARAL",
                                    code: "VCL"
                                }
                            ]
                        },
                        {
                            name: "HUASCO",
                            code: 3304,
                            districts: [
                                {
                                    name: "LOS TOYOS",
                                    code: "CXQ"
                                },
                                {
                                    name: "HUASCO BAJO",
                                    code: "HCB"
                                },
                                {
                                    name: "MIRAFLORES - SERENA",
                                    code: "VMR"
                                },
                                {
                                    name: "CARRIZAL BAJO",
                                    code: "VCR"
                                },
                                {
                                    name: "CANTO DE AGUA",
                                    code: "TAG"
                                },
                                {
                                    name: "HUASCO",
                                    code: "HCO"
                                }
                            ]
                        },
                        {
                            name: "TIERRA AMARILLA",
                            code: 3103,
                            districts: [
                                {
                                    name: "AMOLANAS",
                                    code: "AMN"
                                },
                                {
                                    name: "LA GUARDIA",
                                    code: "CGD"
                                },
                                {
                                    name: "ELISA DE BORDO",
                                    code: "CBD"
                                },
                                {
                                    name: "LAS JUNTAS",
                                    code: "CLJ"
                                },
                                {
                                    name: "LOS AZULES",
                                    code: "CLZ"
                                },
                                {
                                    name: "MINA LA COIPA",
                                    code: "CMQ"
                                },
                                {
                                    name: "NANTOCO",
                                    code: "CNN"
                                },
                                {
                                    name: "TOTORALILLO",
                                    code: "RQM"
                                },
                                {
                                    name: "PUQUIOS",
                                    code: "QPQ"
                                },
                                {
                                    name: "PASTOS LARGOS",
                                    code: "QPL"
                                },
                                {
                                    name: "HORNITOS - COPIAPO",
                                    code: "QHN"
                                },
                                {
                                    name: "COLINA",
                                    code: "QCI"
                                },
                                {
                                    name: "PAIPOTE",
                                    code: "PPE"
                                },
                                {
                                    name: "MINA MARTE",
                                    code: "MTE"
                                },
                                {
                                    name: "MINA CANDELARIA",
                                    code: "MCR"
                                },
                                {
                                    name: "CHANARCILLO",
                                    code: "CYO"
                                },
                                {
                                    name: "TIERRA AMARILLA",
                                    code: "TRM"
                                },
                                {
                                    name: "SAN ANTONIO - COPIAP",
                                    code: "SAI"
                                },
                                {
                                    name: "LA PUERTA",
                                    code: "CXA"
                                },
                                {
                                    name: "VALLE HERMOSO",
                                    code: "CVX"
                                },
                                {
                                    name: "EL VOLCAN",
                                    code: "CVO"
                                },
                                {
                                    name: "LOS LOROS",
                                    code: "CLW"
                                }
                            ]
                        },
                        {
                            name: "VALLENAR",
                            code: 3301,
                            districts: [
                                {
                                    name: "DOMEYKO",
                                    code: "DYK"
                                },
                                {
                                    name: "OBSERVATORIO CAMPANA",
                                    code: "VOS"
                                },
                                {
                                    name: "ALGARROBAL",
                                    code: "VZV"
                                },
                                {
                                    name: "EL DONKEY",
                                    code: "VOB"
                                },
                                {
                                    name: "OBSERVATORIO LA SILL",
                                    code: "LSX"
                                },
                                {
                                    name: "CACHIYUYO",
                                    code: "VCQ"
                                },
                                {
                                    name: "LA HOYADA",
                                    code: "VHY"
                                },
                                {
                                    name: "LOS COLORADOS",
                                    code: "VLO"
                                },
                                {
                                    name: "VALLENAR",
                                    code: "VAL"
                                },
                                {
                                    name: "EL BORATILLO",
                                    code: "EBL"
                                }
                            ]
                        }
                    ]
                },
                {
                    name: "Coquimbo",
                    code: 4,
                    cities: [
                        {
                            name: "ANDACOLLO",
                            code: 4103,
                            districts: [
                                {
                                    name: "ANDACOLLO - LA SEREN",
                                    code: "ACO"
                                },
                                {
                                    name: "BARRANCAS",
                                    code: "BRR"
                                }
                            ]
                        },
                        {
                            name: "CANELA",
                            code: 4202,
                            districts: [
                                {
                                    name: "CANELA",
                                    code: "CNE"
                                },
                                {
                                    name: "PUERTO OSCURO",
                                    code: "POZ"
                                },
                                {
                                    name: "MANTOS DE HORNILLOS",
                                    code: "RMH"
                                },
                                {
                                    name: "POZA HONDA",
                                    code: "XZH"
                                },
                                {
                                    name: "CALETA MORRITOS",
                                    code: "RCC"
                                }
                            ]
                        },
                        {
                            name: "COMBARBALA",
                            code: 4302,
                            districts: [
                                {
                                    name: "COMBARBALA",
                                    code: "COB"
                                },
                                {
                                    name: "LLAHUIN",
                                    code: "OLH"
                                },
                                {
                                    name: "COGOTI",
                                    code: "RCT"
                                },
                                {
                                    name: "SANTA CECILIA",
                                    code: "SSC"
                                },
                                {
                                    name: "LA LIGUA BAJO",
                                    code: "RLU"
                                },
                                {
                                    name: "QUILITAPIA",
                                    code: "QWQ"
                                },
                                {
                                    name: "PARMA",
                                    code: "OPA"
                                },
                                {
                                    name: "CHINGAY",
                                    code: "ONG"
                                },
                                {
                                    name: "HILARICOS",
                                    code: "OHY"
                                },
                                {
                                    name: "CHINEO",
                                    code: "OCH"
                                },
                                {
                                    name: "LAS COLORADAS",
                                    code: "LZZ"
                                }
                            ]
                        },
                        {
                            name: "COQUIMBO",
                            code: 4102,
                            districts: [
                                {
                                    name: "COQUIMBO",
                                    code: "COQ"
                                },
                                {
                                    name: "TOTORALILLO - COQUIM",
                                    code: "TOT"
                                },
                                {
                                    name: "TONGOY",
                                    code: "TGY"
                                },
                                {
                                    name: "RETIRO - COQUIMBO",
                                    code: "RTO"
                                },
                                {
                                    name: "PUERTO VELERO",
                                    code: "PVO"
                                },
                                {
                                    name: "EL PENON - LA SERENA",
                                    code: "PON"
                                },
                                {
                                    name: "PLACILLA - COQUIMBO",
                                    code: "PLM"
                                },
                                {
                                    name: "PENUELAS - LA SERENA",
                                    code: "PLB"
                                },
                                {
                                    name: "LA HERRADURA",
                                    code: "LRR"
                                },
                                {
                                    name: "LAS TACAS",
                                    code: "LTC"
                                },
                                {
                                    name: "TIERRAS BLANCAS",
                                    code: "LTB"
                                },
                                {
                                    name: "PAN DE AZUCAR - SERE",
                                    code: "LPZ"
                                },
                                {
                                    name: "GUACHALALUME",
                                    code: "GHC"
                                },
                                {
                                    name: "GUANAQUEROS",
                                    code: "GQS"
                                }
                            ]
                        },
                        {
                            name: "ILLAPEL",
                            code: 4201,
                            districts: [
                                {
                                    name: "CUZ CUZ - CALERA",
                                    code: "AZZ"
                                },
                                {
                                    name: "CHOAPA",
                                    code: "CPA"
                                },
                                {
                                    name: "TUNGA NORTE",
                                    code: "ZTU"
                                },
                                {
                                    name: "TUNGA SUR",
                                    code: "ZTN"
                                },
                                {
                                    name: "RABANALES",
                                    code: "ZTL"
                                },
                                {
                                    name: "CAREN - CALERA",
                                    code: "ZNK"
                                },
                                {
                                    name: "MINCHA SUR",
                                    code: "ZMM"
                                },
                                {
                                    name: "MINCHA",
                                    code: "ZMH"
                                },
                                {
                                    name: "MATANCILLA",
                                    code: "ZMC"
                                },
                                {
                                    name: "LAS PIRCAS",
                                    code: "ZKZ"
                                },
                                {
                                    name: "HUENTELAUQUEN",
                                    code: "ZHT"
                                },
                                {
                                    name: "HUINTIL",
                                    code: "ZHN"
                                },
                                {
                                    name: "FARELLON SANCHEZ",
                                    code: "ZFA"
                                },
                                {
                                    name: "LAS CANAS",
                                    code: "LZC"
                                },
                                {
                                    name: "ILLAPEL",
                                    code: "ILL"
                                },
                                {
                                    name: "COCUA",
                                    code: "CCY"
                                }
                            ]
                        },
                        {
                            name: "LA HIGUERA",
                            code: 4104,
                            districts: [
                                {
                                    name: "EL OLIVO",
                                    code: "EOV"
                                },
                                {
                                    name: "INCAGUASI",
                                    code: "LIH"
                                },
                                {
                                    name: "LAS BREAS - SERENA",
                                    code: "MQZ"
                                },
                                {
                                    name: "TRES CRUCES",
                                    code: "TCS"
                                },
                                {
                                    name: "EL TOFO",
                                    code: "TFO"
                                },
                                {
                                    name: "PUNTA CHOROS",
                                    code: "PHS"
                                },
                                {
                                    name: "TRAPICHE - LA SERENA",
                                    code: "LTH"
                                },
                                {
                                    name: "CHOROS BAJOS",
                                    code: "LKM"
                                },
                                {
                                    name: "LA HIGUERA - LA SERE",
                                    code: "LHC"
                                },
                                {
                                    name: "CHUNGUNGO",
                                    code: "LGY"
                                },
                                {
                                    name: "LOS HORNOS",
                                    code: "LHB"
                                }
                            ]
                        },
                        {
                            name: "LA SERENA",
                            code: 4101,
                            districts: [
                                {
                                    name: "AGUA GRANDE",
                                    code: "AGG"
                                },
                                {
                                    name: "SAN PABLO",
                                    code: "XPB"
                                },
                                {
                                    name: "TEATINOS",
                                    code: "TNS"
                                },
                                {
                                    name: "LAS ROJAS",
                                    code: "RSE"
                                },
                                {
                                    name: "LA SERENA",
                                    code: "LSC"
                                },
                                {
                                    name: "EL ROMERAL - SERENA",
                                    code: "LER"
                                },
                                {
                                    name: "EL ISLON",
                                    code: "LEI"
                                },
                                {
                                    name: "COQUIMBITO",
                                    code: "LCQ"
                                },
                                {
                                    name: "LAMBERT",
                                    code: "LBT"
                                },
                                {
                                    name: "EL CHACAY",
                                    code: "HAY"
                                },
                                {
                                    name: "LA COMPANIA - SERENA",
                                    code: "GOS"
                                },
                                {
                                    name: "ALTOVALSOL",
                                    code: "AVS"
                                },
                                {
                                    name: "ALMIRANTE LATORRE",
                                    code: "ALT"
                                }
                            ]
                        },
                        {
                            name: "LOS VILOS",
                            code: 4203,
                            districts: [
                                {
                                    name: "CAIMANES",
                                    code: "ANS"
                                },
                                {
                                    name: "LOS VILOS",
                                    code: "LVL"
                                },
                                {
                                    name: "GUANGALI",
                                    code: "ZIV"
                                },
                                {
                                    name: "MAURO",
                                    code: "ZMR"
                                },
                                {
                                    name: "CASUTO",
                                    code: "ZOQ"
                                },
                                {
                                    name: "QUELON",
                                    code: "ZQO"
                                },
                                {
                                    name: "TILAMA",
                                    code: "ZTI"
                                },
                                {
                                    name: "QUILIMARI",
                                    code: "ZQM"
                                },
                                {
                                    name: "CHIGUALOCO",
                                    code: "ZOA"
                                },
                                {
                                    name: "LOS ERMITANOS",
                                    code: "ZKK"
                                },
                                {
                                    name: "TOTORALILLO - CALERA",
                                    code: "TTZ"
                                },
                                {
                                    name: "PICHIDANGUI",
                                    code: "PCH"
                                },
                                {
                                    name: "LOS MOLLES",
                                    code: "LMO"
                                },
                                {
                                    name: "PUPIO",
                                    code: "LOI"
                                }
                            ]
                        },
                        {
                            name: "MONTE PATRIA",
                            code: 4303,
                            districts: [
                                {
                                    name: "CHANARAL ALTO - OVAL",
                                    code: "CHZ"
                                },
                                {
                                    name: "VALDIVIA - OVALLE",
                                    code: "ZOV"
                                },
                                {
                                    name: "BOCATOMA",
                                    code: "XBC"
                                },
                                {
                                    name: "EL CARRIZAL",
                                    code: "OEC"
                                },
                                {
                                    name: "CHILECITO",
                                    code: "OCT"
                                },
                                {
                                    name: "BANOS DEL GORDITO",
                                    code: "OBG"
                                },
                                {
                                    name: "MONTE PATRIA",
                                    code: "MPC"
                                },
                                {
                                    name: "LAS MOLLACAS",
                                    code: "LMS"
                                },
                                {
                                    name: "CAREN - OVALLE",
                                    code: "KEN"
                                },
                                {
                                    name: "GUATULAME",
                                    code: "GUT"
                                },
                                {
                                    name: "EL PALQUI",
                                    code: "EPI"
                                },
                                {
                                    name: "TULAHUEN",
                                    code: "TNH"
                                },
                                {
                                    name: "SAN MARCOS - OVALLE",
                                    code: "SMC"
                                },
                                {
                                    name: "RAPEL - OVALLE",
                                    code: "RQW"
                                },
                                {
                                    name: "EL MAQUI ",
                                    code: "RMQ"
                                },
                                {
                                    name: "LAS RAMADAS",
                                    code: "RLR"
                                },
                                {
                                    name: "LAS JUNTAS - OVALLE",
                                    code: "RLJ"
                                },
                                {
                                    name: "PEDREGAL",
                                    code: "RGR"
                                },
                                {
                                    name: "SAN LORENZO - OVALLE",
                                    code: "RFG"
                                },
                                {
                                    name: "EL MAITEN",
                                    code: "REM"
                                },
                                {
                                    name: "CENTRAL LOS MOLLES",
                                    code: "RCM"
                                }
                            ]
                        },
                        {
                            name: "OVALLE",
                            code: 4301,
                            districts: [
                                {
                                    name: "CHALINGA",
                                    code: "AGA"
                                },
                                {
                                    name: "LAS CARDAS",
                                    code: "LLS"
                                },
                                {
                                    name: "LA TORRE",
                                    code: "LAT"
                                },
                                {
                                    name: "LA CHIMBA",
                                    code: "OLB"
                                },
                                {
                                    name: "OVALLE",
                                    code: "OVL"
                                },
                                {
                                    name: "TERMAS DE SOCO",
                                    code: "SQT"
                                },
                                {
                                    name: "SOTAQUI",
                                    code: "SOZ"
                                },
                                {
                                    name: "SAMO ALTO",
                                    code: "RVG"
                                },
                                {
                                    name: "SAMO BAJO",
                                    code: "RVF"
                                },
                                {
                                    name: "RECOLETA",
                                    code: "RTG"
                                },
                                {
                                    name: "ALGARROBITO - OVALLE",
                                    code: "RRG"
                                },
                                {
                                    name: "BARRAZA",
                                    code: "RRB"
                                },
                                {
                                    name: "CAMARICO VIEJO",
                                    code: "RQV"
                                },
                                {
                                    name: "PEJERREYES",
                                    code: "RPJ"
                                },
                                {
                                    name: "LIMARI",
                                    code: "RLI"
                                },
                                {
                                    name: "HIGUERITAS",
                                    code: "RHG"
                                },
                                {
                                    name: "GUAMPULLA",
                                    code: "RGU"
                                },
                                {
                                    name: "EL ALTAR",
                                    code: "REA"
                                },
                                {
                                    name: "QUEBRADA SECA",
                                    code: "QWR"
                                },
                                {
                                    name: "PACHINGO",
                                    code: "PIC"
                                },
                                {
                                    name: "OTAROLA",
                                    code: "OTR"
                                },
                                {
                                    name: "GUAMALATA",
                                    code: "OGT"
                                },
                                {
                                    name: "CERRILLOS DE TAMAYA",
                                    code: "CTY"
                                },
                                {
                                    name: "CAMARICO",
                                    code: "CRI"
                                }
                            ]
                        },
                        {
                            name: "PAIGUANO",
                            code: 4105,
                            districts: [
                                {
                                    name: "COCHIGUAS",
                                    code: "CGS"
                                },
                                {
                                    name: "MINA DEL INDIO",
                                    code: "LMX"
                                },
                                {
                                    name: "MONTE GRANDE",
                                    code: "LMG"
                                },
                                {
                                    name: "LLANOS DE GUANTA",
                                    code: "LLG"
                                },
                                {
                                    name: "CHAPILCA",
                                    code: "LKK"
                                },
                                {
                                    name: "JUNTAS DEL TORO",
                                    code: "LJZ"
                                },
                                {
                                    name: "LAS HEDIONDAS",
                                    code: "LHD"
                                },
                                {
                                    name: "GUANTA",
                                    code: "LGU"
                                },
                                {
                                    name: "BALALA",
                                    code: "LBW"
                                },
                                {
                                    name: "SOL NACIENTE",
                                    code: "TAL"
                                },
                                {
                                    name: "PAIHUANO",
                                    code: "PHO"
                                },
                                {
                                    name: "NUEVA ELQUI",
                                    code: "NEQ"
                                },
                                {
                                    name: "ALCOHUAS",
                                    code: "MRR"
                                },
                                {
                                    name: "VARILLAR",
                                    code: "LVR"
                                },
                                {
                                    name: "RIVADAVIA",
                                    code: "LRV"
                                },
                                {
                                    name: "BANOS DEL TORO",
                                    code: "LBJ"
                                },
                                {
                                    name: "PISCO ELQUI",
                                    code: "ELQ"
                                }
                            ]
                        },
                        {
                            name: "PUNITAQUI",
                            code: 4304,
                            districts: [
                                {
                                    name: "LOS PERALES - OVALLE",
                                    code: "LZP"
                                },
                                {
                                    name: "PUNITAQUI",
                                    code: "PTQ"
                                },
                                {
                                    name: "LA AGUADA",
                                    code: "RLG"
                                },
                                {
                                    name: "LITIPAMPA",
                                    code: "RLL"
                                },
                                {
                                    name: "PENA BLANCA",
                                    code: "OPB"
                                },
                                {
                                    name: "LA PLACILLA",
                                    code: "OLP"
                                }
                            ]
                        },
                        {
                            name: "RIO HURTADO",
                            code: 4305,
                            districts: [
                                {
                                    name: "CHANAR ",
                                    code: "LHH"
                                },
                                {
                                    name: "CORRAL QUEMADO",
                                    code: "RQO"
                                },
                                {
                                    name: "PICHASCA",
                                    code: "RPI"
                                },
                                {
                                    name: "PABELLON - OVALLE",
                                    code: "RPB"
                                },
                                {
                                    name: "LA FUNDINA",
                                    code: "RLF"
                                },
                                {
                                    name: "LAS BREAS - OVALLE",
                                    code: "RLB"
                                },
                                {
                                    name: "RIO HURTADO",
                                    code: "RHU"
                                },
                                {
                                    name: "HURTADO",
                                    code: "RHT"
                                },
                                {
                                    name: "ALTO BUEY",
                                    code: "OAY"
                                },
                                {
                                    name: "HACIENDA LOS ANDES",
                                    code: "XMR"
                                }
                            ]
                        },
                        {
                            name: "SALAMANCA",
                            code: 4204,
                            districts: [
                                {
                                    name: "ARBOLEDA GRANDE",
                                    code: "ABG"
                                },
                                {
                                    name: "LOS PELADEROS",
                                    code: "LSO"
                                },
                                {
                                    name: "CHELLEPIN",
                                    code: "LNI"
                                },
                                {
                                    name: "PALQUIAL",
                                    code: "PZC"
                                },
                                {
                                    name: "BATUCO - CALERA",
                                    code: "ZBG"
                                },
                                {
                                    name: "JORQUERA",
                                    code: "ZJO"
                                },
                                {
                                    name: "ZAPALLAR",
                                    code: "ZPF"
                                },
                                {
                                    name: "LIMAHUIDA",
                                    code: "ZVH"
                                },
                                {
                                    name: "SAN AGUSTIN",
                                    code: "ZSG"
                                },
                                {
                                    name: "COIRON",
                                    code: "ZNP"
                                },
                                {
                                    name: "EL TAMBO - CALERA",
                                    code: "ZET"
                                },
                                {
                                    name: "ALMENDRALILLO",
                                    code: "ZAM"
                                },
                                {
                                    name: "SALAMANCA",
                                    code: "SCA"
                                },
                                {
                                    name: "LLIMPO",
                                    code: "LLP"
                                }
                            ]
                        },
                        {
                            name: "VICUNA",
                            code: 4106,
                            districts: [
                                {
                                    name: "ANDACOLLO HOLDING",
                                    code: "AVU"
                                },
                                {
                                    name: "CORTADERA",
                                    code: "CEA"
                                },
                                {
                                    name: "CASERONES",
                                    code: "LCR"
                                },
                                {
                                    name: "LA LAJA",
                                    code: "YCV"
                                },
                                {
                                    name: "VICUNA",
                                    code: "VCA"
                                },
                                {
                                    name: "EL TAMBO - LA SERENA",
                                    code: "TBE"
                                },
                                {
                                    name: "PERALILLO - SERENA",
                                    code: "PLV"
                                },
                                {
                                    name: "VINITA BAJA",
                                    code: "LVT"
                                },
                                {
                                    name: "OBSERVATORIO TOLOLO",
                                    code: "LOO"
                                },
                                {
                                    name: "EL ROMERAL - SERENA",
                                    code: "LEM"
                                },
                                {
                                    name: "CONDORIACO",
                                    code: "LCO"
                                },
                                {
                                    name: "DIAGUITAS",
                                    code: "DTS"
                                }
                            ]
                        }
                    ]
                },
                {
                    name: "Valparaiso",
                    code: 5,
                    cities: [
                        {
                            name: "ALGARROBO",
                            code: 5602,
                            districts: [
                                {
                                    name: "ALGARROBO - MELIPILL",
                                    code: "ABO"
                                },
                                {
                                    name: "QUINTAY",
                                    code: "QTY"
                                },
                                {
                                    name: "MIRASOL",
                                    code: "MRL"
                                }
                            ]
                        },
                        {
                            name: "CABILDO",
                            code: 5402,
                            districts: [
                                {
                                    name: "CABILDO",
                                    code: "CDO"
                                },
                                {
                                    name: "LA MORA",
                                    code: "LAO"
                                },
                                {
                                    name: "ALICAHUE",
                                    code: "ZAI"
                                },
                                {
                                    name: "EL GUAYACAN",
                                    code: "ZEG"
                                },
                                {
                                    name: "SAN LORENZO - CALERA",
                                    code: "ZSL"
                                },
                                {
                                    name: "SANTA MARTA - CALERA",
                                    code: "ZST"
                                },
                                {
                                    name: "ARTIFICIO",
                                    code: "ZAT"
                                },
                                {
                                    name: "LA VINA ",
                                    code: "XLV"
                                },
                                {
                                    name: "PEDEGUA",
                                    code: "PUA"
                                },
                                {
                                    name: "LAS PALMAS",
                                    code: "LSA"
                                }
                            ]
                        },
                        {
                            name: "CALERA",
                            code: 5502,
                            districts: [
                                {
                                    name: "LA CALERA",
                                    code: "ZLC"
                                }
                            ]
                        },
                        {
                            name: "CALLE LARGA",
                            code: 5302,
                            districts: [
                                {
                                    name: "CALLE LARGA",
                                    code: "CLG"
                                }
                            ]
                        },
                        {
                            name: "CARTAGENA",
                            code: 5603,
                            districts: [
                                {
                                    name: "LO ABARCA",
                                    code: "ABA"
                                },
                                {
                                    name: "SAN SEBASTIAN - MELI",
                                    code: "SSB"
                                },
                                {
                                    name: "LAS CRUCES",
                                    code: "LCX"
                                },
                                {
                                    name: "EL TURCO",
                                    code: "KER"
                                },
                                {
                                    name: "CARTAGENA",
                                    code: "CRT"
                                }
                            ]
                        },
                        {
                            name: "CASABLANCA",
                            code: 5102,
                            districts: [
                                {
                                    name: "CASABLANCA",
                                    code: "CBC"
                                },
                                {
                                    name: "LAS DICHAS",
                                    code: "KHD"
                                },
                                {
                                    name: "LO OROZCO",
                                    code: "KLO"
                                },
                                {
                                    name: "LAS MERCEDES",
                                    code: "KME"
                                },
                                {
                                    name: "TAPIHUE",
                                    code: "KTH"
                                },
                                {
                                    name: "SAN GERONIMO",
                                    code: "KSG"
                                },
                                {
                                    name: "LO VASQUEZ",
                                    code: "KLV"
                                },
                                {
                                    name: "LAGUNILLAS",
                                    code: "KLL"
                                },
                                {
                                    name: "EL CARPINTERO",
                                    code: "KEC"
                                }
                            ]
                        },
                        {
                            name: "CATEMU",
                            code: 5702,
                            districts: [
                                {
                                    name: "CATEMU",
                                    code: "CAT"
                                },
                                {
                                    name: "EL CERRADO",
                                    code: "SWC"
                                },
                                {
                                    name: "CHAGRES",
                                    code: "SHW"
                                },
                                {
                                    name: "NILHUE",
                                    code: "SNW"
                                },
                                {
                                    name: "EL COBRE - LOS ANDES",
                                    code: "SOW"
                                }
                            ]
                        },
                        {
                            name: "CONCON",
                            code: 5103,
                            districts: [
                                {
                                    name: "CONCON",
                                    code: "CON"
                                }
                            ]
                        },
                        {
                            name: "EL QUISCO",
                            code: 5604,
                            districts: [
                                {
                                    name: "COSTA AZUL",
                                    code: "AZU"
                                },
                                {
                                    name: "PUNTA DE TRALCA",
                                    code: "KPT"
                                },
                                {
                                    name: "ISLA NEGRA",
                                    code: "INE"
                                },
                                {
                                    name: "EL QUISCO",
                                    code: "EQO"
                                }
                            ]
                        },
                        {
                            name: "EL TABO",
                            code: 5605,
                            districts: [
                                {
                                    name: "EL TABO",
                                    code: "ETB"
                                }
                            ]
                        },
                        {
                            name: "HIJUELAS",
                            code: 5503,
                            districts: [
                                {
                                    name: "HIJUELAS",
                                    code: "HJS"
                                }
                            ]
                        },
                        {
                            name: "ISLA DE PASCUA",
                            code: 5201,
                            districts: [
                                {
                                    name: "ISLA DE PASCUA",
                                    code: "IPC"
                                }
                            ]
                        },
                        {
                            name: "JUAN FERNANDEZ",
                            code: 5104,
                            districts: [
                                {
                                    name: "ISLA JUAN FERNANDEZ",
                                    code: "JFZ"
                                }
                            ]
                        },
                        {
                            name: "LA CRUZ",
                            code: 5504,
                            districts: [
                                {
                                    name: "LA CRUZ - CALERA",
                                    code: "LCZ"
                                },
                                {
                                    name: "OCOA",
                                    code: "SOX"
                                }
                            ]
                        },
                        {
                            name: "LA LIGUA",
                            code: 5401,
                            districts: [
                                {
                                    name: "LONGOTOMA",
                                    code: "GMA"
                                },
                                {
                                    name: "LA LIGUA",
                                    code: "LLC"
                                },
                                {
                                    name: "PICHICUY",
                                    code: "PZY"
                                },
                                {
                                    name: "TRAPICHE - CALERA",
                                    code: "ZEW"
                                },
                                {
                                    name: "LA PATAGUA",
                                    code: "ZGC"
                                },
                                {
                                    name: "QUINQUIMO",
                                    code: "ZQQ"
                                },
                                {
                                    name: "EL INGENIO",
                                    code: "ZEI"
                                },
                                {
                                    name: "CALETA POLCURA",
                                    code: "LAR"
                                }
                            ]
                        },
                        {
                            name: "LIMACHE",
                            code: 5505,
                            districts: [
                                {
                                    name: "LIMACHE",
                                    code: "LIC"
                                },
                                {
                                    name: "QUEBRADA ALVARADO",
                                    code: "ZQB"
                                }
                            ]
                        },
                        {
                            name: "LLAILLAY",
                            code: 5703,
                            districts: [
                                {
                                    name: "LLAILLAY",
                                    code: "LLY"
                                },
                                {
                                    name: "MONTENEGRO",
                                    code: "SMQ"
                                },
                                {
                                    name: "RUNGUE",
                                    code: "SER"
                                }
                            ]
                        },
                        {
                            name: "LOS ANDES",
                            code: 5301,
                            districts: [
                                {
                                    name: "LOS ANDES",
                                    code: "LOB"
                                },
                                {
                                    name: "GUARDIA VIEJA",
                                    code: "SGZ"
                                },
                                {
                                    name: "RIO BLANCO",
                                    code: "SRB"
                                },
                                {
                                    name: "SALTO DEL SOLDADO",
                                    code: "SSD"
                                },
                                {
                                    name: "JUNCAL",
                                    code: "SJH"
                                },
                                {
                                    name: "SALADILLO",
                                    code: "SDO"
                                },
                                {
                                    name: "PORTILLO",
                                    code: "PRT"
                                }
                            ]
                        },
                        {
                            name: "NOGALES",
                            code: 5506,
                            districts: [
                                {
                                    name: "EL MELON",
                                    code: "EML"
                                },
                                {
                                    name: "EL COBRE - CALERA",
                                    code: "ZEC"
                                },
                                {
                                    name: "NOGALES",
                                    code: "NOG"
                                }
                            ]
                        },
                        {
                            name: "OLMUE",
                            code: 5507,
                            districts: [
                                {
                                    name: "OLMUE",
                                    code: "OLM"
                                },
                                {
                                    name: "GRANIZO",
                                    code: "ZGR"
                                },
                                {
                                    name: "SAN PEDRO - CALERA",
                                    code: "ZDD"
                                }
                            ]
                        },
                        {
                            name: "PANQUEHUE",
                            code: 5704,
                            districts: [
                                {
                                    name: "PANQUEHUE",
                                    code: "PNQ"
                                },
                                {
                                    name: "LO  ERRAZURIZ",
                                    code: "SLZ"
                                }
                            ]
                        },
                        {
                            name: "PAPUDO",
                            code: 5403,
                            districts: [
                                {
                                    name: "PAPUDO",
                                    code: "PPO"
                                }
                            ]
                        },
                        {
                            name: "PETORCA",
                            code: 5404,
                            districts: [
                                {
                                    name: "CHALACO",
                                    code: "LOA"
                                },
                                {
                                    name: "PETORCA",
                                    code: "PTK"
                                },
                                {
                                    name: "PEDERNAL",
                                    code: "ZDR"
                                },
                                {
                                    name: "CHINCOLCO",
                                    code: "OLC"
                                },
                                {
                                    name: "MINA EL ROSARIO",
                                    code: "ZMS"
                                },
                                {
                                    name: "MANUEL MONTT",
                                    code: "ZMU"
                                },
                                {
                                    name: "HIERRO VIEJO",
                                    code: "ZHR"
                                }
                            ]
                        },
                        {
                            name: "PUCHUNCAVI",
                            code: 5105,
                            districts: [
                                {
                                    name: "CANELA BAJA",
                                    code: "CNB"
                                },
                                {
                                    name: "LA LAGUNA",
                                    code: "LGN"
                                },
                                {
                                    name: "MAITENCILLO - CALERA",
                                    code: "MTC"
                                },
                                {
                                    name: "CALETA HORCON",
                                    code: "LNO"
                                },
                                {
                                    name: "CANELA ALTA",
                                    code: "ZZC"
                                },
                                {
                                    name: "EL RINCON",
                                    code: "ZQD"
                                },
                                {
                                    name: "VENTANAS",
                                    code: "VTA"
                                },
                                {
                                    name: "LA QUEBRADA - CALERA",
                                    code: "QZC"
                                },
                                {
                                    name: "PUCHUNCAVI",
                                    code: "PCV"
                                }
                            ]
                        },
                        {
                            name: "PUTAENDO",
                            code: 5705,
                            districts: [
                                {
                                    name: "PUTAENDO",
                                    code: "PUT"
                                },
                                {
                                    name: "GRANALLA",
                                    code: "SGY"
                                },
                                {
                                    name: "RINCONADA DE GUZMAN",
                                    code: "SRG"
                                },
                                {
                                    name: "RINCONADA DE SILVA",
                                    code: "SRS"
                                },
                                {
                                    name: "TRES FUERTES",
                                    code: "TRE"
                                },
                                {
                                    name: "EL TARTARO",
                                    code: "STY"
                                },
                                {
                                    name: "RESGUARDO LOS PATOS",
                                    code: "SRP"
                                }
                            ]
                        },
                        {
                            name: "QUILLOTA",
                            code: 5501,
                            districts: [
                                {
                                    name: "QUILLOTA",
                                    code: "QTA"
                                }
                            ]
                        },
                        {
                            name: "QUILPUE",
                            code: 5106,
                            districts: [
                                {
                                    name: "EL BELLOTO",
                                    code: "EBT"
                                },
                                {
                                    name: "LA RETUCA",
                                    code: "KLR"
                                },
                                {
                                    name: "QUILPUE",
                                    code: "QPE"
                                }
                            ]
                        },
                        {
                            name: "QUINTERO",
                            code: 5107,
                            districts: [
                                {
                                    name: "VALLE ALEGRE",
                                    code: "BAG"
                                },
                                {
                                    name: "RITOQUE",
                                    code: "ZRV"
                                },
                                {
                                    name: "QUINTERO",
                                    code: "QTO"
                                }
                            ]
                        },
                        {
                            name: "RINCONADA",
                            code: 5303,
                            districts: [
                                {
                                    name: "RINCONADA - LOS ANDE",
                                    code: "RDA"
                                }
                            ]
                        },
                        {
                            name: "SAN ANTONIO",
                            code: 5601,
                            districts: [
                                {
                                    name: "LLO-LLEO",
                                    code: "LLO"
                                },
                                {
                                    name: "SAN ANTONIO - MELIP.",
                                    code: "SNT"
                                }
                            ]
                        },
                        {
                            name: "SAN ESTEBAN",
                            code: 5304,
                            districts: [
                                {
                                    name: "SAN ESTEBAN",
                                    code: "SEN"
                                }
                            ]
                        },
                        {
                            name: "SAN FELIPE",
                            code: 5701,
                            districts: [
                                {
                                    name: "CURIMON",
                                    code: "CRM"
                                },
                                {
                                    name: "CERRILLOS DE SAN FEL",
                                    code: "CSP"
                                },
                                {
                                    name: "SAN FELIPE",
                                    code: "SFP"
                                }
                            ]
                        },
                        {
                            name: "SANTA MARIA",
                            code: 5706,
                            districts: [
                                {
                                    name: "JAHUEL",
                                    code: "JHL"
                                },
                                {
                                    name: "SANTA MARIA",
                                    code: "SRI"
                                },
                                {
                                    name: "TERMAS DE JAHUEL",
                                    code: "TJL"
                                }
                            ]
                        },
                        {
                            name: "SANTO DOMINGO",
                            code: 5606,
                            districts: [
                                {
                                    name: "LO GALLARDO",
                                    code: "KLG"
                                },
                                {
                                    name: "SANTO DOMINGO",
                                    code: "SDC"
                                },
                                {
                                    name: "LEYDA",
                                    code: "KLY"
                                }
                            ]
                        },
                        {
                            name: "VALPARAISO",
                            code: 5101,
                            districts: [
                                {
                                    name: "CALETAS LAS DOCAS",
                                    code: "KCD"
                                },
                                {
                                    name: "PUNTA CURAUMILLA",
                                    code: "KPC"
                                },
                                {
                                    name: "LAS TABLAS",
                                    code: "KLT"
                                },
                                {
                                    name: "VALPARAISO",
                                    code: "VAP"
                                },
                                {
                                    name: "PLACILLA - V DEL MAR",
                                    code: "PLP"
                                },
                                {
                                    name: "PENUELAS - V DEL MAR",
                                    code: "KPE"
                                },
                                {
                                    name: "LAGUNA VERDE",
                                    code: "LAV"
                                }
                            ]
                        },
                        {
                            name: "VILLA ALEMANA",
                            code: 5108,
                            districts: [
                                {
                                    name: "PENABLANCA",
                                    code: "PBA"
                                },
                                {
                                    name: "VILLA ALEMANA",
                                    code: "VIA"
                                }
                            ]
                        },
                        {
                            name: "VINA DEL MAR",
                            code: 5109,
                            districts: [
                                {
                                    name: "VINA DEL MAR",
                                    code: "KNA"
                                },
                                {
                                    name: "RENACA",
                                    code: "RCA"
                                }
                            ]
                        },
                        {
                            name: "ZAPALLAR",
                            code: 5405,
                            districts: [
                                {
                                    name: "CACHAGUA",
                                    code: "CGU"
                                },
                                {
                                    name: "ZAPALLAR - CALERA",
                                    code: "ZAR"
                                },
                                {
                                    name: "CATAPILCO",
                                    code: "PIL"
                                }
                            ]
                        }
                    ]
                },
                {
                    name: "Metropolitana de Santiago",
                    code: 13,
                    cities: [
                        {
                            name: "ALHUE",
                            code: 13502,
                            districts: [
                                {
                                    name: "ALHUE",
                                    code: "ALH"
                                },
                                {
                                    name: "VILLA ALHUE",
                                    code: "SVH"
                                },
                                {
                                    name: "LONCHA",
                                    code: "ZKU"
                                },
                                {
                                    name: "LO CHACON",
                                    code: "HAC"
                                },
                                {
                                    name: "EL MEMBRILLO",
                                    code: "MMZ"
                                },
                                {
                                    name: "LA CRUZ MELIPILLA",
                                    code: "LZU"
                                }
                            ]
                        },
                        {
                            name: "BUIN",
                            code: 13402,
                            districts: [
                                {
                                    name: "MAIPO",
                                    code: "MIP"
                                },
                                {
                                    name: "BUIN",
                                    code: "ZBU"
                                }
                            ]
                        },
                        {
                            name: "CALERA DE TANGO",
                            code: 13403,
                            districts: [
                                {
                                    name: "CALERA DE TANGO",
                                    code: "CDT"
                                },
                                {
                                    name: "ALTO JAHUEL",
                                    code: "SAJ"
                                }
                            ]
                        },
                        {
                            name: "CERRILLOS",
                            code: 13102,
                            districts: [
                                {
                                    name: "CERRILLOS",
                                    code: "RRI"
                                }
                            ]
                        },
                        {
                            name: "CERRO NAVIA",
                            code: 13103,
                            districts: [
                                {
                                    name: "CERRO NAVIA",
                                    code: "CNV"
                                }
                            ]
                        },
                        {
                            name: "COLINA",
                            code: 13301,
                            districts: [
                                {
                                    name: "CHICUREO",
                                    code: "CHW"
                                },
                                {
                                    name: "COLINA",
                                    code: "COL"
                                },
                                {
                                    name: "LAS CANTERAS",
                                    code: "SLN"
                                },
                                {
                                    name: "ESMERALDA",
                                    code: "ESB"
                                }
                            ]
                        },
                        {
                            name: "CONCHALI",
                            code: 13104,
                            districts: [
                                {
                                    name: "CONCHALI",
                                    code: "CNH"
                                }
                            ]
                        },
                        {
                            name: "CURACAVI",
                            code: 13503,
                            districts: [
                                {
                                    name: "COLLIGUAY",
                                    code: "CLY"
                                },
                                {
                                    name: "EL TREBOL",
                                    code: "TRB"
                                },
                                {
                                    name: "LOS ARRAYANES",
                                    code: "LYR"
                                },
                                {
                                    name: "CURACAVI",
                                    code: "CVI"
                                }
                            ]
                        },
                        {
                            name: "EL BOSQUE",
                            code: 13105,
                            districts: [
                                {
                                    name: "EL BOSQUE",
                                    code: "EBO"
                                }
                            ]
                        },
                        {
                            name: "EL MONTE",
                            code: 13602,
                            districts: [
                                {
                                    name: "EL PAICO",
                                    code: "EPC"
                                },
                                {
                                    name: "EL MONTE",
                                    code: "ZTE"
                                }
                            ]
                        },
                        {
                            name: "ESTACION CENTRAL",
                            code: 13106,
                            districts: [
                                {
                                    name: "ESTACION CENTRAL",
                                    code: "ECE"
                                }
                            ]
                        },
                        {
                            name: "HUECHURABA",
                            code: 13107,
                            districts: [
                                {
                                    name: "HUECHURABA",
                                    code: "HRB"
                                }
                            ]
                        },
                        {
                            name: "INDEPENDENCIA",
                            code: 13108,
                            districts: [
                                {
                                    name: "INDEPENDENCIA",
                                    code: "IDP"
                                }
                            ]
                        },
                        {
                            name: "ISLA DE MAIPO",
                            code: 13603,
                            districts: [
                                {
                                    name: "ISLA DE MAIPO",
                                    code: "IDM"
                                }
                            ]
                        },
                        {
                            name: "LA CISTERNA",
                            code: 13109,
                            districts: [
                                {
                                    name: "LA CISTERNA",
                                    code: "LCN"
                                }
                            ]
                        },
                        {
                            name: "LA FLORIDA",
                            code: 13110,
                            districts: [
                                {
                                    name: "LA FLORIDA",
                                    code: "LFD"
                                }
                            ]
                        },
                        {
                            name: "LA GRANJA",
                            code: 13111,
                            districts: [
                                {
                                    name: "LA GRANJA",
                                    code: "LGJ"
                                }
                            ]
                        },
                        {
                            name: "LA PINTANA",
                            code: 13112,
                            districts: [
                                {
                                    name: "LA PINTANA",
                                    code: "LPT"
                                }
                            ]
                        },
                        {
                            name: "LA REINA",
                            code: 13113,
                            districts: [
                                {
                                    name: "LA REINA",
                                    code: "LRN"
                                }
                            ]
                        },
                        {
                            name: "LAMPA",
                            code: 13302,
                            districts: [
                                {
                                    name: "LAMPA",
                                    code: "LSG"
                                },
                                {
                                    name: "BATUCO",
                                    code: "SBX"
                                }
                            ]
                        },
                        {
                            name: "LAS CONDES",
                            code: 13114,
                            districts: [
                                {
                                    name: "LAS CONDES",
                                    code: "LCD"
                                }
                            ]
                        },
                        {
                            name: "LO BARNECHEA",
                            code: 13115,
                            districts: [
                                {
                                    name: "FARELLONES",
                                    code: "FAR"
                                },
                                {
                                    name: "LO BARNECHEA",
                                    code: "LBR"
                                }
                            ]
                        },
                        {
                            name: "LO ESPEJO",
                            code: 13116,
                            districts: [
                                {
                                    name: "LO ESPEJO",
                                    code: "LEP"
                                }
                            ]
                        },
                        {
                            name: "LO PRADO",
                            code: 13117,
                            districts: [
                                {
                                    name: "LO PRADO",
                                    code: "LPR"
                                }
                            ]
                        },
                        {
                            name: "MACUL",
                            code: 13118,
                            districts: [
                                {
                                    name: "MACUL",
                                    code: "MAC"
                                }
                            ]
                        },
                        {
                            name: "MAIPU",
                            code: 13119,
                            districts: [
                                {
                                    name: "MAIPU",
                                    code: "MAI"
                                }
                            ]
                        },
                        {
                            name: "MARIA PINTO",
                            code: 13504,
                            districts: [
                                {
                                    name: "LOLENCO - MELIPILLA",
                                    code: "KET"
                                },
                                {
                                    name: "LOS RULOS",
                                    code: "SLR"
                                },
                                {
                                    name: "SANTA INES",
                                    code: "SIS"
                                },
                                {
                                    name: "MARIA PINTO",
                                    code: "MPO"
                                },
                                {
                                    name: "CHOROMBO",
                                    code: "SCM"
                                },
                                {
                                    name: "BOLLENAR",
                                    code: "SBR"
                                }
                            ]
                        },
                        {
                            name: "MELIPILLA",
                            code: 13501,
                            districts: [
                                {
                                    name: "CHOCALAN ",
                                    code: "AAN"
                                },
                                {
                                    name: "MELIPILLA",
                                    code: "ZMP"
                                },
                                {
                                    name: "CODIGUA",
                                    code: "ZCD"
                                },
                                {
                                    name: "PABELLON - MELIPILLA",
                                    code: "SPB"
                                },
                                {
                                    name: "SAN MANUEL",
                                    code: "SMN"
                                },
                                {
                                    name: "MANDINGA",
                                    code: "SMD"
                                },
                                {
                                    name: "LAS MARIPOSAS - MELI",
                                    code: "SLM"
                                },
                                {
                                    name: "POMAIRE",
                                    code: "IRE"
                                },
                                {
                                    name: "CULIPRAN",
                                    code: "CLP"
                                }
                            ]
                        },
                        {
                            name: "NUNOA",
                            code: 13120,
                            districts: [
                                {
                                    name: "NUNOA",
                                    code: "NNA"
                                }
                            ]
                        },
                        {
                            name: "PADRE HURTADO",
                            code: 13604,
                            districts: [
                                {
                                    name: "LONQUEN",
                                    code: "LQN"
                                },
                                {
                                    name: "PADRE HURTADO",
                                    code: "PHT"
                                }
                            ]
                        },
                        {
                            name: "PAINE",
                            code: 13404,
                            districts: [
                                {
                                    name: "CHAMPA",
                                    code: "CHP"
                                },
                                {
                                    name: "HUELQUEN",
                                    code: "SHU"
                                },
                                {
                                    name: "PINTUE",
                                    code: "SPT"
                                },
                                {
                                    name: "PAINE",
                                    code: "ZPN"
                                },
                                {
                                    name: "RANGUE",
                                    code: "ZUU"
                                },
                                {
                                    name: "EL TRANSITO - SANTIA",
                                    code: "TTO"
                                },
                                {
                                    name: "SANTA MARTA",
                                    code: "SMP"
                                },
                                {
                                    name: "LINDEROS",
                                    code: "SLD"
                                },
                                {
                                    name: "HOSPITAL",
                                    code: "HOS"
                                }
                            ]
                        },
                        {
                            name: "PEDRO AGUIRRE CERDA",
                            code: 13121,
                            districts: [
                                {
                                    name: "PEDRO AGUIRRE CERDA",
                                    code: "PAC"
                                }
                            ]
                        },
                        {
                            name: "PENAFLOR",
                            code: 13605,
                            districts: [
                                {
                                    name: "MALLOCO",
                                    code: "MLL"
                                },
                                {
                                    name: "PENAFLOR",
                                    code: "PFL"
                                }
                            ]
                        },
                        {
                            name: "PENALOLEN",
                            code: 13122,
                            districts: [
                                {
                                    name: "PENALOLEN",
                                    code: "PNL"
                                }
                            ]
                        },
                        {
                            name: "PIRQUE",
                            code: 13202,
                            districts: [
                                {
                                    name: "ISLA DE PIRQUE",
                                    code: "IPQ"
                                },
                                {
                                    name: "PIRQUE",
                                    code: "PIR"
                                }
                            ]
                        },
                        {
                            name: "PROVIDENCIA",
                            code: 13123,
                            districts: [
                                {
                                    name: "PROVIDENCIA",
                                    code: "PRO"
                                }
                            ]
                        },
                        {
                            name: "PUDAHUEL",
                            code: 13124,
                            districts: [
                                {
                                    name: "AEROPUERTO ARTURO ME",
                                    code: "AMB"
                                },
                                {
                                    name: "PUDAHUEL",
                                    code: "PUD"
                                }
                            ]
                        },
                        {
                            name: "PUENTE ALTO",
                            code: 13201,
                            districts: [
                                {
                                    name: "PUENTE ALTO",
                                    code: "PAL"
                                },
                                {
                                    name: "LA OBRA",
                                    code: "SLO"
                                },
                                {
                                    name: "LAS VERTIENTES",
                                    code: "SLV"
                                },
                                {
                                    name: "EL CANELO",
                                    code: "SEC"
                                }
                            ]
                        },
                        {
                            name: "QUILICURA",
                            code: 13125,
                            districts: [
                                {
                                    name: "QUILICURA",
                                    code: "QLC"
                                }
                            ]
                        },
                        {
                            name: "QUINTA NORMAL",
                            code: 13126,
                            districts: [
                                {
                                    name: "QUINTA NORMAL",
                                    code: "QTN"
                                }
                            ]
                        },
                        {
                            name: "RECOLETA",
                            code: 13127,
                            districts: [
                                {
                                    name: "RECOLETA",
                                    code: "RLT"
                                }
                            ]
                        },
                        {
                            name: "RENCA",
                            code: 13128,
                            districts: [
                                {
                                    name: "RENCA",
                                    code: "REN"
                                }
                            ]
                        },
                        {
                            name: "SAN BERNARDO",
                            code: 13401,
                            districts: [
                                {
                                    name: "SAN BERNARDO",
                                    code: "SBD"
                                }
                            ]
                        },
                        {
                            name: "SAN JOAQUIN",
                            code: 13129,
                            districts: [
                                {
                                    name: "SAN JOAQUIN",
                                    code: "SJQ"
                                }
                            ]
                        },
                        {
                            name: "SAN JOSE DE MAIPO",
                            code: 13203,
                            districts: [
                                {
                                    name: "EL MELOCOTON",
                                    code: "EME"
                                },
                                {
                                    name: "SAN GABRIEL",
                                    code: "SGB"
                                },
                                {
                                    name: "LAS MELOSAS",
                                    code: "SLS"
                                },
                                {
                                    name: "EL MANZANILLO",
                                    code: "SMI"
                                },
                                {
                                    name: "VILLA DEL VALLE",
                                    code: "SRV"
                                },
                                {
                                    name: "EL VOLCAN",
                                    code: "SVV"
                                },
                                {
                                    name: "LOS QUELTEHUES",
                                    code: "XQT"
                                },
                                {
                                    name: "GUAYACAN",
                                    code: "SYY"
                                },
                                {
                                    name: "LO VALDES",
                                    code: "STQ"
                                },
                                {
                                    name: "LOS MAITENES",
                                    code: "SMT"
                                },
                                {
                                    name: "SAN JOSE DE MAIPO",
                                    code: "SJS"
                                },
                                {
                                    name: "CHACAY",
                                    code: "SCY"
                                },
                                {
                                    name: "SAN ALFONSO",
                                    code: "SAF"
                                }
                            ]
                        },
                        {
                            name: "SAN MIGUEL",
                            code: 13130,
                            districts: [
                                {
                                    name: "SAN MIGUEL",
                                    code: "SMG"
                                }
                            ]
                        },
                        {
                            name: "SAN PEDRO",
                            code: 13505,
                            districts: [
                                {
                                    name: "BUCALEMU",
                                    code: "BUU"
                                },
                                {
                                    name: "LOYCA",
                                    code: "ZXX"
                                },
                                {
                                    name: "QUIMCAHUE",
                                    code: "SQM"
                                },
                                {
                                    name: "SAN PEDRO - MELIPILL",
                                    code: "SPO"
                                },
                                {
                                    name: "PUEBLO HUNDIDO",
                                    code: "SPH"
                                },
                                {
                                    name: "PUNTA TORO",
                                    code: "KPR"
                                },
                                {
                                    name: "CRUCE LAS ARANAS",
                                    code: "CZA"
                                }
                            ]
                        },
                        {
                            name: "SAN RAMON",
                            code: 13131,
                            districts: [
                                {
                                    name: "SAN RAMON",
                                    code: "SRN"
                                }
                            ]
                        },
                        {
                            name: "SANTIAGO",
                            code: 13101,
                            districts: [
                                {
                                    name: "SANTIAGO",
                                    code: "SCL"
                                }
                            ]
                        },
                        {
                            name: "TALAGANTE",
                            code: 13601,
                            districts: [
                                {
                                    name: "TALAGANTE",
                                    code: "TNT"
                                }
                            ]
                        },
                        {
                            name: "TILTIL",
                            code: 13303,
                            districts: [
                                {
                                    name: "CERRO BLANCO",
                                    code: "CBO"
                                },
                                {
                                    name: "TIL TIL",
                                    code: "TIL"
                                },
                                {
                                    name: "POLPAICO",
                                    code: "POL"
                                }
                            ]
                        },
                        {
                            name: "VITACURA",
                            code: 13132,
                            districts: [
                                {
                                    name: "VITACURA",
                                    code: "VTC"
                                }
                            ]
                        }
                    ]
                },
                {
                    name: "Libertador General Bernardo O`Higgins",
                    code: 6,
                    cities: [
                        {
                            name: "CHEPICA",
                            code: 6302,
                            districts: [
                                {
                                    name: "AUQUINCO",
                                    code: "AQQ"
                                },
                                {
                                    name: "CHEPICA",
                                    code: "CHE"
                                },
                                {
                                    name: "QUINAHUE - CURICO",
                                    code: "QQW"
                                }
                            ]
                        },
                        {
                            name: "CHIMBARONGO",
                            code: 6303,
                            districts: [
                                {
                                    name: "CHIMBARONGO",
                                    code: "CHB"
                                },
                                {
                                    name: "HUEMUL",
                                    code: "HXO"
                                },
                                {
                                    name: "CONVENTO VIEJO",
                                    code: "CVQ"
                                },
                                {
                                    name: "QUINTA",
                                    code: "QPX"
                                },
                                {
                                    name: "MORZA",
                                    code: "MOX"
                                }
                            ]
                        },
                        {
                            name: "CODEGUA",
                            code: 6102,
                            districts: [
                                {
                                    name: "LA LEONERA - RANCAGU",
                                    code: "LRW"
                                },
                                {
                                    name: "LA PUNTA",
                                    code: "RLP"
                                },
                                {
                                    name: "CODEGUA",
                                    code: "ZDE"
                                },
                                {
                                    name: "LA COMPANÍA - RANCAG",
                                    code: "RLC"
                                }
                            ]
                        },
                        {
                            name: "COINCO",
                            code: 6103,
                            districts: [
                                {
                                    name: "COINCO - RANCAGUA",
                                    code: "CNO"
                                }
                            ]
                        },
                        {
                            name: "COLTAUCO",
                            code: 6104,
                            districts: [
                                {
                                    name: "COLTAUCO",
                                    code: "CTO"
                                },
                                {
                                    name: "ZUNIGA",
                                    code: "RZU"
                                }
                            ]
                        },
                        {
                            name: "DONIHUE",
                            code: 6105,
                            districts: [
                                {
                                    name: "DONIHUE",
                                    code: "DNE"
                                },
                                {
                                    name: "PUREN VI - RANCAGUA",
                                    code: "PRN"
                                }
                            ]
                        },
                        {
                            name: "GRANEROS",
                            code: 6106,
                            districts: [
                                {
                                    name: "GRANEROS",
                                    code: "GRA"
                                }
                            ]
                        },
                        {
                            name: "LA ESTRELLA",
                            code: 6202,
                            districts: [
                                {
                                    name: "LAS DAMAS",
                                    code: "KLD"
                                },
                                {
                                    name: "LA ESTRELLA",
                                    code: "LAE"
                                }
                            ]
                        },
                        {
                            name: "LAS CABRAS",
                            code: 6107,
                            districts: [
                                {
                                    name: "EL CARMEN",
                                    code: "CAR"
                                },
                                {
                                    name: "COCALAN",
                                    code: "CCN"
                                },
                                {
                                    name: "PUNTA VERDE",
                                    code: "RPN"
                                },
                                {
                                    name: "LA CEBADA",
                                    code: "RLA"
                                },
                                {
                                    name: "EL MANZANO - RANCAGU",
                                    code: "MZN"
                                },
                                {
                                    name: "LAS CABRAS",
                                    code: "LCB"
                                }
                            ]
                        },
                        {
                            name: "LITUECHE",
                            code: 6203,
                            districts: [
                                {
                                    name: "TOPOCALMA",
                                    code: "KTL"
                                },
                                {
                                    name: "LITUECHE",
                                    code: "LTU"
                                }
                            ]
                        },
                        {
                            name: "LOLOL",
                            code: 6304,
                            districts: [
                                {
                                    name: "EL GUAICO",
                                    code: "EGQ"
                                },
                                {
                                    name: "LOLOL",
                                    code: "LOL"
                                },
                                {
                                    name: "SAN PEDRO - RANCAGUA",
                                    code: "SPW"
                                },
                                {
                                    name: "RANGUIL",
                                    code: "RQQ"
                                }
                            ]
                        },
                        {
                            name: "MACHALI",
                            code: 6108,
                            districts: [
                                {
                                    name: "CHAPA VERDE",
                                    code: "CHV"
                                },
                                {
                                    name: "COLON",
                                    code: "CLN"
                                },
                                {
                                    name: "SEWELL",
                                    code: "SEW"
                                },
                                {
                                    name: "COYA",
                                    code: "OCY"
                                },
                                {
                                    name: "MINA LA JUANITA",
                                    code: "MJT"
                                },
                                {
                                    name: "MACHALI",
                                    code: "MCH"
                                },
                                {
                                    name: "CALETONES",
                                    code: "CTS"
                                }
                            ]
                        },
                        {
                            name: "MALLOA",
                            code: 6109,
                            districts: [
                                {
                                    name: "MALLOA",
                                    code: "ZML"
                                }
                            ]
                        },
                        {
                            name: "MARCHIHUE",
                            code: 6204,
                            districts: [
                                {
                                    name: "ALCONES",
                                    code: "LNE"
                                },
                                {
                                    name: "LAS PATAGUAS",
                                    code: "LPG"
                                },
                                {
                                    name: "MARCHIHUE",
                                    code: "MRH"
                                },
                                {
                                    name: "MARCHANT",
                                    code: "RMT"
                                },
                                {
                                    name: "ESPERANZA",
                                    code: "RZE"
                                },
                                {
                                    name: "SAN JOSE MARCHIHUE",
                                    code: "RSA"
                                },
                                {
                                    name: "LA QUEBRADA - RANCAG",
                                    code: "RQE"
                                }
                            ]
                        },
                        {
                            name: "MOSTAZAL",
                            code: 6110,
                            districts: [
                                {
                                    name: "SAN FCO DE MOSTAZAL",
                                    code: "SFM"
                                }
                            ]
                        },
                        {
                            name: "NANCAGUA",
                            code: 6305,
                            districts: [
                                {
                                    name: "NANCAGUA",
                                    code: "NGA"
                                }
                            ]
                        },
                        {
                            name: "NAVIDAD",
                            code: 6205,
                            districts: [
                                {
                                    name: "MATANZAS",
                                    code: "KMT"
                                },
                                {
                                    name: "PUNTA PERRO",
                                    code: "KPP"
                                },
                                {
                                    name: "SAN ENRIQUE",
                                    code: "KSE"
                                },
                                {
                                    name: "CORNECHE",
                                    code: "KRC"
                                },
                                {
                                    name: "PUERTECILLO",
                                    code: "KPU"
                                },
                                {
                                    name: "PUNTA BARRANCA",
                                    code: "KPB"
                                },
                                {
                                    name: "SAN VICENTE DE PUCAL",
                                    code: "KVP"
                                },
                                {
                                    name: "LA BOCA - MELIPILLA",
                                    code: "ZRW"
                                },
                                {
                                    name: "RAPEL - MELIPILLA",
                                    code: "RPL"
                                },
                                {
                                    name: "NAVIDAD",
                                    code: "NAV"
                                }
                            ]
                        },
                        {
                            name: "OLIVAR",
                            code: 6111,
                            districts: [
                                {
                                    name: "LO MIRANDA",
                                    code: "LMI"
                                },
                                {
                                    name: "OLIVAR BAJO",
                                    code: "ROL"
                                },
                                {
                                    name: "OLIVAR ALTO",
                                    code: "OAL"
                                }
                            ]
                        },
                        {
                            name: "PALMILLA",
                            code: 6306,
                            districts: [
                                {
                                    name: "PALMILLA - RANCAGUA",
                                    code: "PLA"
                                }
                            ]
                        },
                        {
                            name: "PAREDONES",
                            code: 6206,
                            districts: [
                                {
                                    name: "BOYERUCA",
                                    code: "BYY"
                                },
                                {
                                    name: "PAREDONES",
                                    code: "PDS"
                                },
                                {
                                    name: "BUCALEMU - RANCAGUA",
                                    code: "PRM"
                                },
                                {
                                    name: "LO VALDIVIA",
                                    code: "LVQ"
                                }
                            ]
                        },
                        {
                            name: "PERALILLO",
                            code: 6307,
                            districts: [
                                {
                                    name: "LIHUEIMO",
                                    code: "LHX"
                                },
                                {
                                    name: "PERALILLO - RANCAGUA",
                                    code: "ZPE"
                                },
                                {
                                    name: "POBLACION",
                                    code: "RQT"
                                }
                            ]
                        },
                        {
                            name: "PEUMO",
                            code: 6112,
                            districts: [
                                {
                                    name: "PEUMO",
                                    code: "PEO"
                                },
                                {
                                    name: "TUNCA ARRIBA",
                                    code: "TZV"
                                }
                            ]
                        },
                        {
                            name: "PICHIDEGUA",
                            code: 6113,
                            districts: [
                                {
                                    name: "LARMAHUE",
                                    code: "LQX"
                                },
                                {
                                    name: "EL TOCO",
                                    code: "RET"
                                },
                                {
                                    name: "PICHIDEGUA",
                                    code: "PHA"
                                }
                            ]
                        },
                        {
                            name: "PICHILEMU",
                            code: 6201,
                            districts: [
                                {
                                    name: "PICHILEMU",
                                    code: "PMU"
                                },
                                {
                                    name: "ALTO COLORADO",
                                    code: "RAC"
                                },
                                {
                                    name: "CAHUIL",
                                    code: "RHL"
                                },
                                {
                                    name: "CIRUELOS",
                                    code: "RJC"
                                },
                                {
                                    name: "EL PUESTO",
                                    code: "RPS"
                                },
                                {
                                    name: "SANTA GRACIELA ALCON",
                                    code: "RGQ"
                                }
                            ]
                        },
                        {
                            name: "PLACILLA",
                            code: 6308,
                            districts: [
                                {
                                    name: "PLACILLA - RANCAGUA",
                                    code: "PLL"
                                }
                            ]
                        },
                        {
                            name: "PUMANQUE",
                            code: 6309,
                            districts: [
                                {
                                    name: "PUMANQUE",
                                    code: "PMQ"
                                },
                                {
                                    name: "NILAHUE",
                                    code: "RNI"
                                }
                            ]
                        },
                        {
                            name: "QUINTA DE TILCOCO",
                            code: 6114,
                            districts: [
                                {
                                    name: "QUINTA DE TILCOCO",
                                    code: "QCC"
                                }
                            ]
                        },
                        {
                            name: "RANCAGUA",
                            code: 6101,
                            districts: [
                                {
                                    name: "RANCAGUA",
                                    code: "RCG"
                                },
                                {
                                    name: "PUNTA DE CORTES",
                                    code: "TPC"
                                }
                            ]
                        },
                        {
                            name: "RENGO",
                            code: 6115,
                            districts: [
                                {
                                    name: "CERRILLOS",
                                    code: "CRE"
                                },
                                {
                                    name: "HACIENDA LOS LINGUES",
                                    code: "HRQ"
                                },
                                {
                                    name: "RENGO",
                                    code: "ZRG"
                                },
                                {
                                    name: "POPETA",
                                    code: "RPO"
                                },
                                {
                                    name: "LAS NIEVES - RANCAGU",
                                    code: "RLS"
                                },
                                {
                                    name: "LOS MAQUIS",
                                    code: "RLM"
                                },
                                {
                                    name: "PELEQUEN",
                                    code: "PEQ"
                                }
                            ]
                        },
                        {
                            name: "REQUINOA",
                            code: 6116,
                            districts: [
                                {
                                    name: "REQUINOA",
                                    code: "REQ"
                                },
                                {
                                    name: "PIMPINELA",
                                    code: "RMC"
                                },
                                {
                                    name: "ROSARIO",
                                    code: "RSS"
                                }
                            ]
                        },
                        {
                            name: "SAN FERNANDO",
                            code: 6301,
                            districts: [
                                {
                                    name: "AGUA BUENA",
                                    code: "AQB"
                                },
                                {
                                    name: "LA RUFINA",
                                    code: "LRF"
                                },
                                {
                                    name: "ROMA",
                                    code: "RMY"
                                },
                                {
                                    name: "SIERRA BELLAVISTA",
                                    code: "ZSB"
                                },
                                {
                                    name: "TROMPETILLA",
                                    code: "TZT"
                                },
                                {
                                    name: "TERMAS DEL FLACO",
                                    code: "TZF"
                                },
                                {
                                    name: "TINGUIRIRICA",
                                    code: "TGR"
                                },
                                {
                                    name: "SAN FERNANDO",
                                    code: "SFR"
                                },
                                {
                                    name: "TERMAS DE CAUQUENES",
                                    code: "RTE"
                                },
                                {
                                    name: "PUENTE NEGRO",
                                    code: "RPT"
                                }
                            ]
                        },
                        {
                            name: "SAN VICENTE",
                            code: 6117,
                            districts: [
                                {
                                    name: "MILLAHUE",
                                    code: "MLX"
                                },
                                {
                                    name: "SAN VICENTE DE TAGUA TAGUA",
                                    code: "SVT"
                                }
                            ]
                        },
                        {
                            name: "SANTA CRUZ",
                            code: 6310,
                            districts: [
                                {
                                    name: "LA LAJUELA",
                                    code: "LLW"
                                },
                                {
                                    name: "PANIAHUE",
                                    code: "PHU"
                                },
                                {
                                    name: "RINCONADA DE YAQUIL",
                                    code: "RQY"
                                },
                                {
                                    name: "SANTA CRUZ",
                                    code: "ZSC"
                                },
                                {
                                    name: "CUNACO",
                                    code: "ZCU"
                                },
                                {
                                    name: "NERQUIHUE",
                                    code: "RNH"
                                }
                            ]
                        }
                    ]
                },
                {
                    name: "Maule",
                    code: 7,
                    cities: [
                        {
                            name: "CAUQUENES",
                            code: 7201,
                            districts: [
                                {
                                    name: "CAUQUENES",
                                    code: "CQE"
                                },
                                {
                                    name: "UNICAVEN",
                                    code: "ZUV"
                                },
                                {
                                    name: "LOS PERALES",
                                    code: "ZHH"
                                },
                                {
                                    name: "QUELLA",
                                    code: "XQL"
                                },
                                {
                                    name: "LOS NABOS",
                                    code: "XNY"
                                },
                                {
                                    name: "PASO HONDO - TALCA",
                                    code: "XEI"
                                },
                                {
                                    name: "HUALVE",
                                    code: "XHR"
                                }
                            ]
                        },
                        {
                            name: "CHANCO",
                            code: 7202,
                            districts: [
                                {
                                    name: "CHANCO",
                                    code: "CNC"
                                },
                                {
                                    name: "CURANIPE",
                                    code: "CNP"
                                }
                            ]
                        },
                        {
                            name: "COLBUN",
                            code: 7402,
                            districts: [
                                {
                                    name: "BARRERA",
                                    code: "BYQ"
                                },
                                {
                                    name: "SANTA ANA",
                                    code: "ZSN"
                                },
                                {
                                    name: "COLBUN",
                                    code: "CLB"
                                }
                            ]
                        },
                        {
                            name: "CONSTITUCION",
                            code: 7102,
                            districts: [
                                {
                                    name: "CONSTITUCION",
                                    code: "CTT"
                                },
                                {
                                    name: "JUNQUILLAR",
                                    code: "ZJU"
                                },
                                {
                                    name: "QUIVOLGO",
                                    code: "ZQI"
                                },
                                {
                                    name: "PUTU",
                                    code: "PUU"
                                }
                            ]
                        },
                        {
                            name: "CUREPTO",
                            code: 7103,
                            districts: [
                                {
                                    name: "CUREPTO",
                                    code: "CUR"
                                },
                                {
                                    name: "LA LORA",
                                    code: "XLA"
                                }
                            ]
                        },
                        {
                            name: "CURICO",
                            code: 7301,
                            districts: [
                                {
                                    name: "CURICO",
                                    code: "CCO"
                                },
                                {
                                    name: "LOS NICHES",
                                    code: "LNH"
                                },
                                {
                                    name: "QUEBRADA HONDA",
                                    code: "ZQH"
                                },
                                {
                                    name: "UPEO",
                                    code: "ZUO"
                                },
                                {
                                    name: "MONTE OSCURO",
                                    code: "ZPA"
                                },
                                {
                                    name: "POTRERO GRANDE",
                                    code: "PTG"
                                }
                            ]
                        },
                        {
                            name: "EMPEDRADO",
                            code: 7104,
                            districts: [
                                {
                                    name: "EMPEDRADO",
                                    code: "EMP"
                                },
                                {
                                    name: "NIRIVILO",
                                    code: "ZNI"
                                }
                            ]
                        },
                        {
                            name: "HUALANE",
                            code: 7302,
                            districts: [
                                {
                                    name: "HUALANE",
                                    code: "HNE"
                                }
                            ]
                        },
                        {
                            name: "LICANTEN",
                            code: 7303,
                            districts: [
                                {
                                    name: "LICANTEN",
                                    code: "LCT"
                                }
                            ]
                        },
                        {
                            name: "LINARES",
                            code: 7401,
                            districts: [
                                {
                                    name: "ADUANA PEJERREY",
                                    code: "ADJ"
                                },
                                {
                                    name: "LINARES",
                                    code: "LNR"
                                },
                                {
                                    name: "LLEPO",
                                    code: "XBL"
                                },
                                {
                                    name: "MELADO",
                                    code: "XMK"
                                },
                                {
                                    name: "ROBLERIA",
                                    code: "XRI"
                                },
                                {
                                    name: "EL SALTO",
                                    code: "XSE"
                                },
                                {
                                    name: "MIRAFLORES - TALCA",
                                    code: "ZMI"
                                },
                                {
                                    name: "LOS RABONES",
                                    code: "XRX"
                                },
                                {
                                    name: "PALMILLA",
                                    code: "XPE"
                                },
                                {
                                    name: "CAMPAMENTO ANCOA",
                                    code: "CPQ"
                                }
                            ]
                        },
                        {
                            name: "LONGAVI",
                            code: 7403,
                            districts: [
                                {
                                    name: "LONGAVI",
                                    code: "LGV"
                                },
                                {
                                    name: "EL TRANSITO - TALCA",
                                    code: "XTD"
                                },
                                {
                                    name: "VILLA SECA",
                                    code: "ZVS"
                                },
                                {
                                    name: "MELAO",
                                    code: "ZMG"
                                },
                                {
                                    name: "LOS CRISTALES",
                                    code: "XLY"
                                },
                                {
                                    name: "MESAMAVIDA",
                                    code: "XME"
                                }
                            ]
                        },
                        {
                            name: "MAULE",
                            code: 7105,
                            districts: [
                                {
                                    name: "DUAO",
                                    code: "UAO"
                                },
                                {
                                    name: "MAULE",
                                    code: "ZMA"
                                }
                            ]
                        },
                        {
                            name: "MOLINA",
                            code: 7304,
                            districts: [
                                {
                                    name: "ADUANA  ",
                                    code: "ADW"
                                },
                                {
                                    name: "MOLINA",
                                    code: "ZMO"
                                },
                                {
                                    name: "YACEL",
                                    code: "XYY"
                                },
                                {
                                    name: "RADAL - CURICO",
                                    code: "XXR"
                                },
                                {
                                    name: "LONTUE",
                                    code: "LTE"
                                }
                            ]
                        },
                        {
                            name: "PARRAL",
                            code: 7404,
                            districts: [
                                {
                                    name: "AJIAL",
                                    code: "JJZ"
                                },
                                {
                                    name: "BULLILLEO",
                                    code: "LLZ"
                                },
                                {
                                    name: "PERQUILAUQUEN",
                                    code: "PQQ"
                                },
                                {
                                    name: "EL PENON - TALCA",
                                    code: "PXQ"
                                },
                                {
                                    name: "QUINCHIMAVIDA",
                                    code: "QQH"
                                },
                                {
                                    name: "PARRAL",
                                    code: "PRR"
                                },
                                {
                                    name: "TERMAS DE CATILLO",
                                    code: "TQC"
                                },
                                {
                                    name: "VILLA ROSAS",
                                    code: "VZQ"
                                },
                                {
                                    name: "SAN PABLO",
                                    code: "SZS"
                                }
                            ]
                        },
                        {
                            name: "PELARCO",
                            code: 7106,
                            districts: [
                                {
                                    name: "PELARCO",
                                    code: "PLC"
                                },
                                {
                                    name: "ASTILLERO",
                                    code: "ZAS"
                                }
                            ]
                        },
                        {
                            name: "PELLUHUE",
                            code: 7203,
                            districts: [
                                {
                                    name: "PELLUHUE",
                                    code: "PEL"
                                }
                            ]
                        },
                        {
                            name: "PENCAHUE",
                            code: 7107,
                            districts: [
                                {
                                    name: "PENCAHUE",
                                    code: "PEH"
                                },
                                {
                                    name: "GUALLECO",
                                    code: "ZGU"
                                },
                                {
                                    name: "PICHAMAN",
                                    code: "ZKQ"
                                },
                                {
                                    name: "COIPUE",
                                    code: "ZCP"
                                },
                                {
                                    name: "BATUCO - TALCA",
                                    code: "ZBA"
                                }
                            ]
                        },
                        {
                            name: "RAUCO",
                            code: 7305,
                            districts: [
                                {
                                    name: "RAUCO",
                                    code: "RAU"
                                },
                                {
                                    name: "TRINCAO - CURICO",
                                    code: "SAR"
                                },
                                {
                                    name: "PALQUIBUDA",
                                    code: "XQB"
                                }
                            ]
                        },
                        {
                            name: "RETIRO",
                            code: 7405,
                            districts: [
                                {
                                    name: "RETIRO - TALCA",
                                    code: "RTR"
                                }
                            ]
                        },
                        {
                            name: "RIO CLARO",
                            code: 7108,
                            districts: [
                                {
                                    name: "CUMPEO",
                                    code: "CUM"
                                },
                                {
                                    name: "ITAHUE",
                                    code: "ZIK"
                                },
                                {
                                    name: "RIO CLARO - TALCA",
                                    code: "RCL"
                                }
                            ]
                        },
                        {
                            name: "ROMERAL",
                            code: 7306,
                            districts: [
                                {
                                    name: "ROMERAL",
                                    code: "RML"
                                },
                                {
                                    name: "EL PLANCHON",
                                    code: "XPO"
                                },
                                {
                                    name: "LOS QUENES",
                                    code: "ZQX"
                                },
                                {
                                    name: "POTRERO GRANDE CHICO",
                                    code: "XPC"
                                }
                            ]
                        },
                        {
                            name: "SAGRADA FAMILIA",
                            code: 7307,
                            districts: [
                                {
                                    name: "SAGRADA FAMILIA",
                                    code: "SFA"
                                },
                                {
                                    name: "VILLA PRAT",
                                    code: "ZVO"
                                }
                            ]
                        },
                        {
                            name: "SAN CLEMENTE",
                            code: 7109,
                            districts: [
                                {
                                    name: "ARMERILLO",
                                    code: "BWP"
                                },
                                {
                                    name: "ENDESA ",
                                    code: "ZED"
                                },
                                {
                                    name: "LA MINA",
                                    code: "ZLM"
                                },
                                {
                                    name: "LAS GARZAS",
                                    code: "ZLG"
                                },
                                {
                                    name: "EL COLORADO",
                                    code: "ZEL"
                                },
                                {
                                    name: "CORRALONES",
                                    code: "ZCS"
                                },
                                {
                                    name: "SAN CLEMENTE",
                                    code: "STE"
                                },
                                {
                                    name: "PASO NEVADO",
                                    code: "XPS"
                                },
                                {
                                    name: "AURORA ",
                                    code: "ZAU"
                                }
                            ]
                        },
                        {
                            name: "SAN JAVIER",
                            code: 7406,
                            districts: [
                                {
                                    name: "SAN JAVIER",
                                    code: "SJA"
                                }
                            ]
                        },
                        {
                            name: "SAN RAFAEL",
                            code: 7110,
                            districts: [
                                {
                                    name: "LITU",
                                    code: "CUT"
                                },
                                {
                                    name: "SAN RAFAEL",
                                    code: "SRF"
                                }
                            ]
                        },
                        {
                            name: "TALCA",
                            code: 7101,
                            districts: [
                                {
                                    name: "BOTALCURA",
                                    code: "ZBO"
                                },
                                {
                                    name: "CURTIDURIA",
                                    code: "ZCE"
                                },
                                {
                                    name: "COLIN",
                                    code: "ZCL"
                                },
                                {
                                    name: "TALCA",
                                    code: "ZCA"
                                },
                                {
                                    name: "CORINTO",
                                    code: "ZNM"
                                }
                            ]
                        },
                        {
                            name: "TENO",
                            code: 7308,
                            districts: [
                                {
                                    name: "LA MONTANA",
                                    code: "LMN"
                                },
                                {
                                    name: "TENO",
                                    code: "TEN"
                                },
                                {
                                    name: "CULENAR",
                                    code: "XQP"
                                },
                                {
                                    name: "REBECA",
                                    code: "ZRB"
                                },
                                {
                                    name: "EL MANZANO - CURICO",
                                    code: "XMM"
                                }
                            ]
                        },
                        {
                            name: "VICHUQUEN",
                            code: 7309,
                            districts: [
                                {
                                    name: "PICHIBUDI",
                                    code: "BCH"
                                },
                                {
                                    name: "LLICO - CURICO",
                                    code: "ZYC"
                                },
                                {
                                    name: "LIPIMAVIDA",
                                    code: "ZVU"
                                },
                                {
                                    name: "LA TRINCHERA",
                                    code: "ZLT"
                                },
                                {
                                    name: "VICHUQUEN",
                                    code: "VCH"
                                },
                                {
                                    name: "ILOCA",
                                    code: "ILO"
                                }
                            ]
                        },
                        {
                            name: "VILLA ALEGRE",
                            code: 7407,
                            districts: [
                                {
                                    name: "ARBOLILLO",
                                    code: "ARB"
                                },
                                {
                                    name: "VILLA ALEGRE - TALCA",
                                    code: "VGE"
                                },
                                {
                                    name: "LAS CAMPANAS",
                                    code: "ZLJ"
                                },
                                {
                                    name: "MELOZAL",
                                    code: "XMD"
                                }
                            ]
                        },
                        {
                            name: "YERBAS BUENAS",
                            code: 7408,
                            districts: [
                                {
                                    name: "PANIMAVIDA",
                                    code: "PNV"
                                },
                                {
                                    name: "QUINMAVIDA",
                                    code: "ZQV"
                                },
                                {
                                    name: "YERBAS BUENAS",
                                    code: "YBB"
                                }
                            ]
                        }
                    ]
                },
                {
                    name: "Ñuble",
                    code: 16,
                    cities: [
                        {
                            name: "BULNES",
                            code: 16102,
                            districts: [
                                {
                                    name: "BULNES",
                                    code: "BLN"
                                },
                                {
                                    name: "QUINCHAMALI",
                                    code: "QML"
                                }
                            ]
                        },
                        {
                            name: "CHILLAN",
                            code: 16101,
                            districts: [
                                {
                                    name: "CHILLAN",
                                    code: "YAI"
                                }
                            ]
                        },
                        {
                            name: "CHILLAN VIEJO",
                            code: 16103,
                            districts: [
                                {
                                    name: "CHILLAN VIEJO",
                                    code: "YAV"
                                },
                                {
                                    name: "RUCAPEQUEN",
                                    code: "YRU"
                                }
                            ]
                        },
                        {
                            name: "COBQUECURA",
                            code: 16202,
                            districts: [
                                {
                                    name: "COBQUECURA",
                                    code: "CQU"
                                },
                                {
                                    name: "PULLAY",
                                    code: "YPL"
                                },
                                {
                                    name: "BUCHUPUREO",
                                    code: "YBU"
                                }
                            ]
                        },
                        {
                            name: "COELEMU",
                            code: 16203,
                            districts: [
                                {
                                    name: "SAN IGNACIO - CONCEP",
                                    code: "ZCG"
                                },
                                {
                                    name: "RAQUIL",
                                    code: "ZER"
                                },
                                {
                                    name: "CONAIR",
                                    code: "ZIR"
                                },
                                {
                                    name: "NUEVA ALDEA",
                                    code: "ZNA"
                                },
                                {
                                    name: "COELEMU",
                                    code: "ZOU"
                                },
                                {
                                    name: "NIPAS",
                                    code: "ZNY"
                                }
                            ]
                        },
                        {
                            name: "COIHUECO",
                            code: 16302,
                            districts: [
                                {
                                    name: "COIHUECO",
                                    code: "CUH"
                                },
                                {
                                    name: "MINAS DEL PRADO",
                                    code: "MPD"
                                },
                                {
                                    name: "LA CAPILLA - CHILLAN",
                                    code: "LQP"
                                },
                                {
                                    name: "TANILVORO",
                                    code: "YTA"
                                },
                                {
                                    name: "FUNDO LOS ROBLES",
                                    code: "FRQ"
                                }
                            ]
                        },
                        {
                            name: "EL CARMEN",
                            code: 16104,
                            districts: [
                                {
                                    name: "QUIRIQUINA",
                                    code: "QNA"
                                },
                                {
                                    name: "LOS CASTANOS",
                                    code: "YLO"
                                },
                                {
                                    name: "TREGUALEMU",
                                    code: "YTU"
                                },
                                {
                                    name: "PUEBLO SECO",
                                    code: "YPU"
                                },
                                {
                                    name: "EL CARMEN - CHILLAN",
                                    code: "YCX"
                                }
                            ]
                        },
                        {
                            name: "NINHUE",
                            code: 16204,
                            districts: [
                                {
                                    name: "NINHUE",
                                    code: "NIN"
                                },
                                {
                                    name: "TORRECILLA",
                                    code: "YTR"
                                },
                                {
                                    name: "EL PARRON",
                                    code: "YEL"
                                },
                                {
                                    name: "POCILLAS",
                                    code: "YPO"
                                },
                                {
                                    name: "COIPIN",
                                    code: "YCO"
                                },
                                {
                                    name: "CANCHA ALEGRE",
                                    code: "YCN"
                                }
                            ]
                        },
                        {
                            name: "NIQUEN",
                            code: 16303,
                            districts: [
                                {
                                    name: "NIQUEN",
                                    code: "NYY"
                                }
                            ]
                        },
                        {
                            name: "PEMUCO",
                            code: 16105,
                            districts: [
                                {
                                    name: "SAN PEDRO - CHILLAN",
                                    code: "SQZ"
                                },
                                {
                                    name: "PEMUCO",
                                    code: "ZPC"
                                },
                                {
                                    name: "GENERAL CRUZ",
                                    code: "YGE"
                                }
                            ]
                        },
                        {
                            name: "PINTO",
                            code: 16106,
                            districts: [
                                {
                                    name: "PINTO",
                                    code: "PNO"
                                }
                            ]
                        },
                        {
                            name: "PORTEZUELO",
                            code: 16205,
                            districts: [
                                {
                                    name: "PORTEZUELO",
                                    code: "49H"
                                },
                                {
                                    name: "CONFLUENCIA",
                                    code: "YCF"
                                }
                            ]
                        },
                        {
                            name: "QUILLON",
                            code: 16107,
                            districts: [
                                {
                                    name: "QUILLON",
                                    code: "QLL"
                                }
                            ]
                        },
                        {
                            name: "QUIRIHUE",
                            code: 16201,
                            districts: [
                                {
                                    name: "QUIRIHUE",
                                    code: "QIH"
                                }
                            ]
                        },
                        {
                            name: "RANQUIL",
                            code: 16206,
                            districts: [
                                {
                                    name: "RANQUIL",
                                    code: "RNQ"
                                }
                            ]
                        },
                        {
                            name: "SAN CARLOS",
                            code: 16301,
                            districts: [
                                {
                                    name: "SAN CARLOS - CHILLAN",
                                    code: "SCS"
                                },
                                {
                                    name: "NAHUELTORO",
                                    code: "YNA"
                                },
                                {
                                    name: "ZEMITA",
                                    code: "YZE"
                                },
                                {
                                    name: "SAN GREGORIO NIQUEN ",
                                    code: "YSN"
                                },
                                {
                                    name: "CACHAPOAL - CHILLAN",
                                    code: "YCA"
                                },
                                {
                                    name: "EL SAUCE",
                                    code: "YES"
                                }
                            ]
                        },
                        {
                            name: "SAN FABIAN",
                            code: 16304,
                            districts: [
                                {
                                    name: "SAN FABIAN DE ALICO",
                                    code: "SFB"
                                },
                                {
                                    name: "LOS PUQUIOS",
                                    code: "YLP"
                                },
                                {
                                    name: "LA PUNTILLA",
                                    code: "YLA"
                                }
                            ]
                        },
                        {
                            name: "SAN IGNACIO",
                            code: 16108,
                            districts: [
                                {
                                    name: "SAN IGNACIO - CHILLA",
                                    code: "SIG"
                                },
                                {
                                    name: "SAN MIGUEL",
                                    code: "SMW"
                                },
                                {
                                    name: "ZAPALLAR - CHILLAN",
                                    code: "ZAY"
                                },
                                {
                                    name: "TERMAS DE CHILLAN",
                                    code: "YTE"
                                },
                                {
                                    name: "RECINTO",
                                    code: "YRE"
                                },
                                {
                                    name: "LAS TRANCAS",
                                    code: "YLS"
                                }
                            ]
                        },
                        {
                            name: "SAN NICOLAS",
                            code: 16305,
                            districts: [
                                {
                                    name: "SAN NICOLAS",
                                    code: "SNL"
                                }
                            ]
                        },
                        {
                            name: "TREHUACO",
                            code: 16207,
                            districts: [
                                {
                                    name: "TREHUACO",
                                    code: "TRH"
                                },
                                {
                                    name: "TREGUACO",
                                    code: "YTG"
                                },
                                {
                                    name: "MELA",
                                    code: "YME"
                                },
                                {
                                    name: "COLMUYAO",
                                    code: "YCL"
                                }
                            ]
                        },
                        {
                            name: "YUNGAY",
                            code: 16109,
                            districts: [
                                {
                                    name: "CHOLGUAN",
                                    code: "YCH"
                                },
                                {
                                    name: "CAMPANARIO",
                                    code: "YCM"
                                },
                                {
                                    name: "EL SALTILLO",
                                    code: "YEY"
                                },
                                {
                                    name: "PANGAL - LOS ANGELES",
                                    code: "ZPP"
                                },
                                {
                                    name: "YUNGAY",
                                    code: "YGY"
                                }
                            ]
                        }
                    ]
                },
                {
                    name: "Bío - Bío",
                    code: 8,
                    cities: [
                        {
                            name: "ALTO BIOBIO",
                            code: 8314,
                            districts: [
                                {
                                    name: "ALTO BIO BIO",
                                    code: "AOO"
                                },
                                {
                                    name: "PANGUE",
                                    code: "ZLL"
                                },
                                {
                                    name: "RALCO",
                                    code: "RCO"
                                },
                                {
                                    name: "RALCO LEPOY",
                                    code: "ZLN"
                                },
                                {
                                    name: "TERMAS DEL AVELLANO",
                                    code: "ZTV"
                                },
                                {
                                    name: "COMUNIDAD CANICU",
                                    code: "ZRI"
                                },
                                {
                                    name: "CASA LOLCO",
                                    code: "ZOT"
                                }
                            ]
                        },
                        {
                            name: "ANTUCO",
                            code: 8302,
                            districts: [
                                {
                                    name: "ANTUCO",
                                    code: "ANT"
                                },
                                {
                                    name: "LOS BARROS",
                                    code: "LLB"
                                },
                                {
                                    name: "EL TORO",
                                    code: "LTW"
                                },
                                {
                                    name: "EL ABANICO",
                                    code: "LEA"
                                }
                            ]
                        },
                        {
                            name: "ARAUCO",
                            code: 8202,
                            districts: [
                                {
                                    name: "ARAUCO",
                                    code: "ARA"
                                },
                                {
                                    name: "RAQUI",
                                    code: "ZUQ"
                                },
                                {
                                    name: "QUIDICO - CONCEPCION",
                                    code: "ZQR"
                                },
                                {
                                    name: "RAMADILLA",
                                    code: "ZMD"
                                },
                                {
                                    name: "LLICO - CONCEPCION",
                                    code: "ZCF"
                                },
                                {
                                    name: "EL BOLDO",
                                    code: "ZBC"
                                },
                                {
                                    name: "VILLA ALEGRE - CONCE",
                                    code: "VAG"
                                },
                                {
                                    name: "CARAMPANGUE",
                                    code: "PAN"
                                },
                                {
                                    name: "LARAQUETE",
                                    code: "LAQ"
                                }
                            ]
                        },
                        {
                            name: "CABRERO",
                            code: 8303,
                            districts: [
                                {
                                    name: "SANTA CLARA",
                                    code: "CLA"
                                },
                                {
                                    name: "PASO HONDO",
                                    code: "PHY"
                                },
                                {
                                    name: "LIUCURA",
                                    code: "YCU"
                                },
                                {
                                    name: "PASO HONDO - CHILLAN",
                                    code: "YCR"
                                },
                                {
                                    name: "TOMECO",
                                    code: "TMK"
                                },
                                {
                                    name: "CABRERO",
                                    code: "CRO"
                                }
                            ]
                        },
                        {
                            name: "CANETE",
                            code: 8203,
                            districts: [
                                {
                                    name: "SAN ALFONSO - CONCEP",
                                    code: "CFF"
                                },
                                {
                                    name: "CAYUCUPIL",
                                    code: "ZZW"
                                },
                                {
                                    name: "CANETE",
                                    code: "CTE"
                                }
                            ]
                        },
                        {
                            name: "CHIGUAYANTE",
                            code: 8103,
                            districts: [
                                {
                                    name: "CHIGUAYANTE",
                                    code: "CYE"
                                }
                            ]
                        },
                        {
                            name: "CONCEPCION",
                            code: 8101,
                            districts: [
                                {
                                    name: "CONCEPCION",
                                    code: "CCP"
                                },
                                {
                                    name: "RANGUELMO",
                                    code: "GUO"
                                }
                            ]
                        },
                        {
                            name: "CONTULMO",
                            code: 8204,
                            districts: [
                                {
                                    name: "CONTULMO",
                                    code: "CTU"
                                },
                                {
                                    name: "RINCONADA - CONCEPCI",
                                    code: "ZWE"
                                },
                                {
                                    name: "ANTIQUINA",
                                    code: "ZQG"
                                }
                            ]
                        },
                        {
                            name: "CORONEL",
                            code: 8102,
                            districts: [
                                {
                                    name: "CORONEL",
                                    code: "CRN"
                                }
                            ]
                        },
                        {
                            name: "CURANILAHUE",
                            code: 8205,
                            districts: [
                                {
                                    name: "SAN JOSE DE COLICO",
                                    code: "SJL"
                                },
                                {
                                    name: "CURANILAHUE",
                                    code: "ZHE"
                                }
                            ]
                        },
                        {
                            name: "FLORIDA",
                            code: 8104,
                            districts: [
                                {
                                    name: "FLORIDA",
                                    code: "FLO"
                                },
                                {
                                    name: "AGUAS DE LA GLORIA",
                                    code: "ZGG"
                                },
                                {
                                    name: "COPIULEMU",
                                    code: "ZUM"
                                }
                            ]
                        },
                        {
                            name: "HUALPEN",
                            code: 8112,
                            districts: [
                                {
                                    name: "HUALPEN",
                                    code: "HLP"
                                },
                                {
                                    name: "LA BOCA - CONCEPCION",
                                    code: "ZBB"
                                }
                            ]
                        },
                        {
                            name: "HUALQUI",
                            code: 8105,
                            districts: [
                                {
                                    name: "TALCAMAVIDA",
                                    code: "CTV"
                                },
                                {
                                    name: "QUILACOYA",
                                    code: "ZIQ"
                                },
                                {
                                    name: "HUALQUI",
                                    code: "HLQ"
                                }
                            ]
                        },
                        {
                            name: "LAJA",
                            code: 8304,
                            districts: [
                                {
                                    name: "COLONIA",
                                    code: "LCI"
                                },
                                {
                                    name: "LAJA",
                                    code: "LLJ"
                                }
                            ]
                        },
                        {
                            name: "LEBU",
                            code: 8201,
                            districts: [
                                {
                                    name: "CURACO - CONCEPCION",
                                    code: "MLC"
                                },
                                {
                                    name: "SARA DE LEBU",
                                    code: "ZBL"
                                },
                                {
                                    name: "LEBU",
                                    code: "ZLB"
                                },
                                {
                                    name: "MILLONHUE",
                                    code: "ZNG"
                                },
                                {
                                    name: "YENECO",
                                    code: "ZYY"
                                },
                                {
                                    name: "RUCARAQUIL",
                                    code: "ZYR"
                                },
                                {
                                    name: "RANQUILCO",
                                    code: "ZRQ"
                                },
                                {
                                    name: "QUINAHUE - CONCEPCIO",
                                    code: "ZQA"
                                },
                                {
                                    name: "PEHUEN",
                                    code: "ZPQ"
                                }
                            ]
                        },
                        {
                            name: "LOS ALAMOS",
                            code: 8206,
                            districts: [
                                {
                                    name: "LOS ALAMOS",
                                    code: "LAL"
                                },
                                {
                                    name: "ANTIGUALA",
                                    code: "ZNT"
                                },
                                {
                                    name: "TRES PINOS",
                                    code: "ZTP"
                                },
                                {
                                    name: "PILPILCO",
                                    code: "ZWP"
                                }
                            ]
                        },
                        {
                            name: "LOS ANGELES",
                            code: 8301,
                            districts: [
                                {
                                    name: "EL ALAMO",
                                    code: "LEL"
                                },
                                {
                                    name: "SANTA CLARA",
                                    code: "LST"
                                },
                                {
                                    name: "SAN CARLOS PUREN - L",
                                    code: "SCP"
                                },
                                {
                                    name: "SAN CARLOS PUREN - L",
                                    code: "SCW"
                                },
                                {
                                    name: "LOS ANGELES",
                                    code: "LSQ"
                                }
                            ]
                        },
                        {
                            name: "LOTA",
                            code: 8106,
                            districts: [
                                {
                                    name: "LOTA",
                                    code: "LOT"
                                }
                            ]
                        },
                        {
                            name: "MULCHEN",
                            code: 8305,
                            districts: [
                                {
                                    name: "EL AVELLANO",
                                    code: "LEV"
                                },
                                {
                                    name: "MAITENES",
                                    code: "LLM"
                                },
                                {
                                    name: "EL MORRO",
                                    code: "LMR"
                                },
                                {
                                    name: "LOS MAICAS",
                                    code: "LZX"
                                },
                                {
                                    name: "MULCHEN",
                                    code: "MUL"
                                },
                                {
                                    name: "MELICA",
                                    code: "MEA"
                                },
                                {
                                    name: "SAN MIGUEL",
                                    code: "LSM"
                                }
                            ]
                        },
                        {
                            name: "NACIMIENTO",
                            code: 8306,
                            districts: [
                                {
                                    name: "CHOROICO",
                                    code: "CIO"
                                },
                                {
                                    name: "COIHUE",
                                    code: "LCE"
                                },
                                {
                                    name: "DIUQUIN",
                                    code: "LDQ"
                                },
                                {
                                    name: "PROGRESO",
                                    code: "PGS"
                                },
                                {
                                    name: "NACIMIENTO",
                                    code: "NAC"
                                },
                                {
                                    name: "SANTA FE",
                                    code: "LSF"
                                }
                            ]
                        },
                        {
                            name: "NEGRETE",
                            code: 8307,
                            districts: [
                                {
                                    name: "NEGRETE",
                                    code: "NRE"
                                },
                                {
                                    name: "RIHUE",
                                    code: "RIE"
                                }
                            ]
                        },
                        {
                            name: "PENCO",
                            code: 8107,
                            districts: [
                                {
                                    name: "LIRQUEN",
                                    code: "LIR"
                                },
                                {
                                    name: "PENCO",
                                    code: "PCO"
                                },
                                {
                                    name: "ROA",
                                    code: "ZRO"
                                }
                            ]
                        },
                        {
                            name: "QUILACO",
                            code: 8308,
                            districts: [
                                {
                                    name: "ALTO CALEDONIA",
                                    code: "ANI"
                                },
                                {
                                    name: "QUILACO",
                                    code: "QCO"
                                },
                                {
                                    name: "LONCOPANGUE",
                                    code: "LGE"
                                },
                                {
                                    name: "CERRO DEL PADRE",
                                    code: "LCP"
                                },
                                {
                                    name: "RUCALHUE",
                                    code: "LRH"
                                }
                            ]
                        },
                        {
                            name: "QUILLECO",
                            code: 8309,
                            districts: [
                                {
                                    name: "CANTERAS",
                                    code: "CNT"
                                },
                                {
                                    name: "QUILLECO",
                                    code: "QLO"
                                },
                                {
                                    name: "CANICURA",
                                    code: "CRA"
                                },
                                {
                                    name: "VILLA MERCEDES",
                                    code: "LVM"
                                }
                            ]
                        },
                        {
                            name: "SAN PEDRO DE LA PAZ",
                            code: 8108,
                            districts: [
                                {
                                    name: "SAN PEDRO DE LA PAZ",
                                    code: "SPP"
                                }
                            ]
                        },
                        {
                            name: "SAN ROSENDO",
                            code: 8310,
                            districts: [
                                {
                                    name: "BUENURAQUI",
                                    code: "LBQ"
                                },
                                {
                                    name: "SAN ROSENDO",
                                    code: "SRO"
                                }
                            ]
                        },
                        {
                            name: "SANTA BARBARA",
                            code: 8311,
                            districts: [
                                {
                                    name: "LOLCO",
                                    code: "COC"
                                },
                                {
                                    name: "SANTA BARBARA",
                                    code: "SBB"
                                },
                                {
                                    name: "VILLUCURA",
                                    code: "LVU"
                                },
                                {
                                    name: "LAS NIEVES",
                                    code: "LNV"
                                },
                                {
                                    name: "EL GUACHI",
                                    code: "LGH"
                                },
                                {
                                    name: "LOS BRUJOS",
                                    code: "LBS"
                                },
                                {
                                    name: "LOS PLACERES",
                                    code: "LBO"
                                }
                            ]
                        },
                        {
                            name: "SANTA JUANA",
                            code: 8109,
                            districts: [
                                {
                                    name: "SANTA JUANA",
                                    code: "SJN"
                                }
                            ]
                        },
                        {
                            name: "TALCAHUANO",
                            code: 8110,
                            districts: [
                                {
                                    name: "ISLA QUIRIQUINA",
                                    code: "IQR"
                                },
                                {
                                    name: "TALCAHUANO",
                                    code: "ZTO"
                                },
                                {
                                    name: "SAN VICENTE",
                                    code: "SVC"
                                },
                                {
                                    name: "RANCHO TALCAHUANO",
                                    code: "RTH"
                                }
                            ]
                        },
                        {
                            name: "TIRUA",
                            code: 8207,
                            districts: [
                                {
                                    name: "PAILACO",
                                    code: "PLO"
                                },
                                {
                                    name: "TIRUA",
                                    code: "TUA"
                                },
                                {
                                    name: "QUIDICO - TEMUCO",
                                    code: "ZDQ"
                                }
                            ]
                        },
                        {
                            name: "TOME",
                            code: 8111,
                            districts: [
                                {
                                    name: "DICHATO",
                                    code: "DTO"
                                },
                                {
                                    name: "TOME",
                                    code: "TMC"
                                },
                                {
                                    name: "MENQUE",
                                    code: "ZQW"
                                },
                                {
                                    name: "VEGAS DE ITATA",
                                    code: "ZTT"
                                },
                                {
                                    name: "RAFAEL",
                                    code: "ZRA"
                                }
                            ]
                        },
                        {
                            name: "TUCAPEL",
                            code: 8312,
                            districts: [
                                {
                                    name: "HUEPIL",
                                    code: "HUP"
                                },
                                {
                                    name: "TRUPAN",
                                    code: "LTP"
                                },
                                {
                                    name: "TUCAPEL",
                                    code: "TCP"
                                },
                                {
                                    name: "POLCURA",
                                    code: "LPO"
                                }
                            ]
                        },
                        {
                            name: "YUMBEL",
                            code: 8313,
                            districts: [
                                {
                                    name: "ESTACION YUMBEL",
                                    code: "EYY"
                                },
                                {
                                    name: "MONTE AGUILA",
                                    code: "ZLA"
                                },
                                {
                                    name: "YUMBEL",
                                    code: "ZYU"
                                },
                                {
                                    name: "RIO CLARO - LOS ANGE",
                                    code: "LRC"
                                }
                            ]
                        }
                    ]
                },
                {
                    name: "Araucanía",
                    code: 9,
                    cities: [
                        {
                            name: "ANGOL",
                            code: 9201,
                            districts: [
                                {
                                    name: "MAITENREHUE",
                                    code: "LLH"
                                },
                                {
                                    name: "PIEDRA DEL AGUILA",
                                    code: "PDA"
                                },
                                {
                                    name: "ANGOL",
                                    code: "ZOL"
                                },
                                {
                                    name: "VEGAS BLANCAS",
                                    code: "LVB"
                                }
                            ]
                        },
                        {
                            name: "CARAHUE",
                            code: 9102,
                            districts: [
                                {
                                    name: "CARAHUE",
                                    code: "CRH"
                                },
                                {
                                    name: "VILLA ARAUCANIA",
                                    code: "VZA"
                                },
                                {
                                    name: "LOBERIA",
                                    code: "ZLE"
                                },
                                {
                                    name: "TROVOLHUE",
                                    code: "ZTK"
                                },
                                {
                                    name: "NEHUENTUE",
                                    code: "ZNH"
                                },
                                {
                                    name: "CAMARONES - TEMUCO",
                                    code: "VAC"
                                }
                            ]
                        },
                        {
                            name: "CHOL CHOL",
                            code: 9121,
                            districts: [
                                {
                                    name: "CHOLCHOL",
                                    code: "CHL"
                                }
                            ]
                        },
                        {
                            name: "COLLIPULLI",
                            code: 9202,
                            districts: [
                                {
                                    name: "CANADA",
                                    code: "CDA"
                                },
                                {
                                    name: "PORVENIR",
                                    code: "ZPB"
                                },
                                {
                                    name: "TRINTRE",
                                    code: "TTE"
                                },
                                {
                                    name: "TERMAS DE PEMEHUE",
                                    code: "MTW"
                                },
                                {
                                    name: "NANCO",
                                    code: "LYY"
                                },
                                {
                                    name: "LOLENCO",
                                    code: "LLE"
                                },
                                {
                                    name: "CURACO ",
                                    code: "HDF"
                                },
                                {
                                    name: "EL AMARGO",
                                    code: "EAG"
                                },
                                {
                                    name: "COLLIPULLI",
                                    code: "CPI"
                                }
                            ]
                        },
                        {
                            name: "CUNCO",
                            code: 9103,
                            districts: [
                                {
                                    name: "PLAYA NEGRA",
                                    code: "MFN"
                                },
                                {
                                    name: "TERMAS DE SAN SEBAST",
                                    code: "ZTB"
                                },
                                {
                                    name: "LOS LAURELES",
                                    code: "ZSO"
                                },
                                {
                                    name: "CUNCO",
                                    code: "NCO"
                                },
                                {
                                    name: "PUERTO PUMA",
                                    code: "PPM"
                                },
                                {
                                    name: "HELO SUR",
                                    code: "ZHL"
                                },
                                {
                                    name: "LAS HORTENCIAS",
                                    code: "ZLH"
                                }
                            ]
                        },
                        {
                            name: "CURACAUTIN",
                            code: 9203,
                            districts: [
                                {
                                    name: "CURACAUTIN",
                                    code: "CCC"
                                },
                                {
                                    name: "MANZANAR",
                                    code: "ZMN"
                                },
                                {
                                    name: "TERMAS DE RIO BLANCO",
                                    code: "ZTS"
                                },
                                {
                                    name: "MALALCAHUELO ",
                                    code: "ZOH"
                                },
                                {
                                    name: "LA SOMBRA",
                                    code: "ZBN"
                                }
                            ]
                        },
                        {
                            name: "CURARREHUE",
                            code: 9104,
                            districts: [
                                {
                                    name: "TERMAS DE PANGUI",
                                    code: "GAR"
                                },
                                {
                                    name: "REIGOLIL",
                                    code: "ZGO"
                                },
                                {
                                    name: "TERMAS DE SAN LUIS",
                                    code: "TSL"
                                },
                                {
                                    name: "CURARREHUE",
                                    code: "RRH"
                                },
                                {
                                    name: "PUESCO",
                                    code: "PUE"
                                }
                            ]
                        },
                        {
                            name: "ERCILLA",
                            code: 9204,
                            districts: [
                                {
                                    name: "ERCILLA",
                                    code: "ERL"
                                },
                                {
                                    name: "PAILAHUENQUE",
                                    code: "ZPH"
                                }
                            ]
                        },
                        {
                            name: "FREIRE",
                            code: 9105,
                            districts: [
                                {
                                    name: "FREIRE",
                                    code: "FIE"
                                },
                                {
                                    name: "MISION BOROA",
                                    code: "MBA"
                                },
                                {
                                    name: "QUEPE",
                                    code: "QUP"
                                }
                            ]
                        },
                        {
                            name: "GALVARINO",
                            code: 9106,
                            districts: [
                                {
                                    name: "GALVARINO",
                                    code: "GAL"
                                },
                                {
                                    name: "RUCATRARO",
                                    code: "ZXR"
                                }
                            ]
                        },
                        {
                            name: "GORBEA",
                            code: 9107,
                            districts: [
                                {
                                    name: "GORBEA",
                                    code: "GEA"
                                },
                                {
                                    name: "QUITRATUE",
                                    code: "ZQT"
                                },
                                {
                                    name: "LASTARRIA",
                                    code: "ZLR"
                                }
                            ]
                        },
                        {
                            name: "LAUTARO",
                            code: 9108,
                            districts: [
                                {
                                    name: "AGUA SANTA",
                                    code: "AST"
                                },
                                {
                                    name: "COLONIA LAUTARO",
                                    code: "CLU"
                                },
                                {
                                    name: "PUA",
                                    code: "CAU"
                                },
                                {
                                    name: "RETEN DOLLINCO",
                                    code: "ZRT"
                                },
                                {
                                    name: "LOS PRADOS",
                                    code: "ZLD"
                                },
                                {
                                    name: "LAUTARO",
                                    code: "LTR"
                                }
                            ]
                        },
                        {
                            name: "LONCOCHE",
                            code: 9109,
                            districts: [
                                {
                                    name: "CRUCES",
                                    code: "CRS"
                                },
                                {
                                    name: "LONCOCHE",
                                    code: "LOC"
                                }
                            ]
                        },
                        {
                            name: "LONQUIMAY",
                            code: 9205,
                            districts: [
                                {
                                    name: "LIUCURA - TEMUCO",
                                    code: "IRA"
                                },
                                {
                                    name: "LONQUIMAY",
                                    code: "LQY"
                                },
                                {
                                    name: "TROYO",
                                    code: "ZTY"
                                },
                                {
                                    name: "SIERRA NEVADA",
                                    code: "ZSI"
                                },
                                {
                                    name: "QUINQUEN",
                                    code: "ZQN"
                                },
                                {
                                    name: "LOLEN",
                                    code: "LLN"
                                }
                            ]
                        },
                        {
                            name: "LOS SAUCES",
                            code: 9206,
                            districts: [
                                {
                                    name: "NAHUELVE",
                                    code: "NNH"
                                },
                                {
                                    name: "SANTA ROSA - TEMUCO",
                                    code: "SZZ"
                                },
                                {
                                    name: "CENTENARIO",
                                    code: "ZCT"
                                },
                                {
                                    name: "LOS SAUCES",
                                    code: "SUS"
                                }
                            ]
                        },
                        {
                            name: "LUMACO",
                            code: 9207,
                            districts: [
                                {
                                    name: "CAPITAN PASTENE",
                                    code: "CPP"
                                },
                                {
                                    name: "RELUN",
                                    code: "ZRL"
                                },
                                {
                                    name: "PICHIPELLAHUEN",
                                    code: "ZNN"
                                },
                                {
                                    name: "LUMACO",
                                    code: "LUM"
                                }
                            ]
                        },
                        {
                            name: "MELIPEUCO",
                            code: 9110,
                            districts: [
                                {
                                    name: "MELIPEUCO",
                                    code: "MLP"
                                },
                                {
                                    name: "TERMAS DE MOLULCO",
                                    code: "ZMY"
                                },
                                {
                                    name: "LOMACURA",
                                    code: "ZLV"
                                },
                                {
                                    name: "ICALMA",
                                    code: "ZIC"
                                }
                            ]
                        },
                        {
                            name: "NUEVA IMPERIAL",
                            code: 9111,
                            districts: [
                                {
                                    name: "BOROA",
                                    code: "BOA"
                                },
                                {
                                    name: "NUEVA IMPERIAL",
                                    code: "NIP"
                                },
                                {
                                    name: "ALMAGRO",
                                    code: "ZGV"
                                }
                            ]
                        },
                        {
                            name: "PADRE LAS CASAS",
                            code: 9112,
                            districts: [
                                {
                                    name: "PADRE LAS CASAS",
                                    code: "PCS"
                                },
                                {
                                    name: "EL ALAMBRADO",
                                    code: "ZEA"
                                }
                            ]
                        },
                        {
                            name: "PERQUENCO",
                            code: 9113,
                            districts: [
                                {
                                    name: "PERQUENCO",
                                    code: "PQO"
                                },
                                {
                                    name: "SELVA OSCURA",
                                    code: "SSO"
                                }
                            ]
                        },
                        {
                            name: "PITRUFQUEN",
                            code: 9114,
                            districts: [
                                {
                                    name: "PITRUFQUEN",
                                    code: "PQN"
                                },
                                {
                                    name: "RADAL - TEMUCO",
                                    code: "ZLK"
                                }
                            ]
                        },
                        {
                            name: "PUCON",
                            code: 9115,
                            districts: [
                                {
                                    name: "SAN PEDRO - TEMUCO",
                                    code: "SSP"
                                },
                                {
                                    name: "CABURGUA",
                                    code: "ZCB"
                                },
                                {
                                    name: "TERMAS DE MENETUE",
                                    code: "ZMT"
                                },
                                {
                                    name: "PUCON",
                                    code: "ZPU"
                                },
                                {
                                    name: "TERMAS DE PALGUIN",
                                    code: "ZTM"
                                },
                                {
                                    name: "HUIFE",
                                    code: "ZTZ"
                                },
                                {
                                    name: "TERMAS DE HUIFE",
                                    code: "ZTR"
                                },
                                {
                                    name: "REFUGIO",
                                    code: "ZRE"
                                },
                                {
                                    name: "PEMUCO",
                                    code: "ZPC"
                                }
                            ]
                        },
                        {
                            name: "PUREN",
                            code: 9208,
                            districts: [
                                {
                                    name: "PUREN - TEMUCO",
                                    code: "PUR"
                                }
                            ]
                        },
                        {
                            name: "RENAICO",
                            code: 9209,
                            districts: [
                                {
                                    name: "TIJERAL",
                                    code: "LTJ"
                                },
                                {
                                    name: "MININCO",
                                    code: "MNC"
                                },
                                {
                                    name: "RENAICO",
                                    code: "RNA"
                                }
                            ]
                        },
                        {
                            name: "SAAVEDRA",
                            code: 9116,
                            districts: [
                                {
                                    name: "BOCA BUDI",
                                    code: "BBD"
                                },
                                {
                                    name: "PUERTO SAAVEDRA",
                                    code: "ZPS"
                                }
                            ]
                        },
                        {
                            name: "TEMUCO",
                            code: 9101,
                            districts: [
                                {
                                    name: "CAJON",
                                    code: "CJN"
                                },
                                {
                                    name: "MAQUEHUE",
                                    code: "MQH"
                                },
                                {
                                    name: "LABRANZA",
                                    code: "LZA"
                                },
                                {
                                    name: "METRENCO",
                                    code: "MTR"
                                },
                                {
                                    name: "TEMUCO",
                                    code: "ZCO"
                                },
                                {
                                    name: "PILLANLELBUN",
                                    code: "ZUN"
                                },
                                {
                                    name: "GENERAL LOPEZ",
                                    code: "ZGE"
                                }
                            ]
                        },
                        {
                            name: "TEODORO SCHMIDT",
                            code: 9117,
                            districts: [
                                {
                                    name: "PELECO",
                                    code: "PEC"
                                },
                                {
                                    name: "TEODORO SCHMIDT",
                                    code: "TEO"
                                },
                                {
                                    name: "PUERTO DOMINGUEZ",
                                    code: "ZPM"
                                },
                                {
                                    name: "BARROS ARANA",
                                    code: "ZBR"
                                }
                            ]
                        },
                        {
                            name: "TOLTEN",
                            code: 9118,
                            districts: [
                                {
                                    name: "TOLTEN",
                                    code: "TOL"
                                },
                                {
                                    name: "HUALPIN",
                                    code: "UAL"
                                },
                                {
                                    name: "QUILQUE",
                                    code: "ZQL"
                                },
                                {
                                    name: "COMUY",
                                    code: "ZCM"
                                }
                            ]
                        },
                        {
                            name: "TRAIGUEN",
                            code: 9210,
                            districts: [
                                {
                                    name: "TRAIGUEN",
                                    code: "ZEN"
                                },
                                {
                                    name: "QUECHEREGUAS",
                                    code: "ZQE"
                                }
                            ]
                        },
                        {
                            name: "VICTORIA",
                            code: 9211,
                            districts: [
                                {
                                    name: "VICTORIA",
                                    code: "VIC"
                                },
                                {
                                    name: "TERMAS DE TOLHUACA",
                                    code: "ZTD"
                                },
                                {
                                    name: "TRES ESQUINAS",
                                    code: "ZTQ"
                                },
                                {
                                    name: "LAS MARIPOSAS - TEMU",
                                    code: "ZJM"
                                }
                            ]
                        },
                        {
                            name: "VILCUN",
                            code: 9119,
                            districts: [
                                {
                                    name: "VILCUN",
                                    code: "VIL"
                                },
                                {
                                    name: "REFUGIO LLAIMA",
                                    code: "ZRF"
                                },
                                {
                                    name: "SAN PATRICIO",
                                    code: "ZSP"
                                },
                                {
                                    name: "CHERQUENCO",
                                    code: "ZCC"
                                }
                            ]
                        },
                        {
                            name: "VILLARRICA",
                            code: 9120,
                            districts: [
                                {
                                    name: "VILLARRICA",
                                    code: "VRR"
                                },
                                {
                                    name: "PEDREGOSO",
                                    code: "ZPD"
                                },
                                {
                                    name: "HUISCAPI",
                                    code: "ZHS"
                                }
                            ]
                        }
                    ]
                },
                {
                    name: "Los Ríos",
                    code: 14,
                    cities: [
                        {
                            name: "CORRAL",
                            code: 14102,
                            districts: [
                                {
                                    name: "PUNTA CHAIHUIN",
                                    code: "PTC"
                                },
                                {
                                    name: "CORRAL",
                                    code: "ZCR"
                                }
                            ]
                        },
                        {
                            name: "FUTRONO",
                            code: 14202,
                            districts: [
                                {
                                    name: "COIQUE",
                                    code: "COI"
                                },
                                {
                                    name: "FUTRONO",
                                    code: "FTR"
                                },
                                {
                                    name: "LLIFEN",
                                    code: "LLI"
                                },
                                {
                                    name: "EL MUNDIAL",
                                    code: "EMN"
                                },
                                {
                                    name: "CHABRANCO",
                                    code: "ZXB"
                                },
                                {
                                    name: "MONTUELA",
                                    code: "ZMB"
                                },
                                {
                                    name: "LOS LLOLLES",
                                    code: "ZLO"
                                },
                                {
                                    name: "HUEQUECURA",
                                    code: "ZHU"
                                },
                                {
                                    name: "DOLLINCO",
                                    code: "ZDO"
                                },
                                {
                                    name: "BANOS DE CHIHUIO",
                                    code: "ZBZ"
                                },
                                {
                                    name: "MAIHUE",
                                    code: "MLE"
                                }
                            ]
                        },
                        {
                            name: "LA UNION",
                            code: 14201,
                            districts: [
                                {
                                    name: "LAS VENTANAS",
                                    code: "LVS"
                                },
                                {
                                    name: "EL MIRADOR",
                                    code: "ORO"
                                },
                                {
                                    name: "TRUMAO",
                                    code: "TMA"
                                },
                                {
                                    name: "TRINIDAD",
                                    code: "ODA"
                                },
                                {
                                    name: "LA BARRA",
                                    code: "OAA"
                                },
                                {
                                    name: "SANTA ELISA",
                                    code: "ZSS"
                                },
                                {
                                    name: "RAPACO",
                                    code: "ZRP"
                                },
                                {
                                    name: "LA UNION",
                                    code: "ZLU"
                                },
                                {
                                    name: "HUEICOLLA",
                                    code: "ZHC"
                                },
                                {
                                    name: "LOS CONALES - OSORNO",
                                    code: "ZAQ"
                                }
                            ]
                        },
                        {
                            name: "LAGO RANCO",
                            code: 14203,
                            districts: [
                                {
                                    name: "LAGO RANCO",
                                    code: "LRO"
                                },
                                {
                                    name: "TRAPI",
                                    code: "OIP"
                                },
                                {
                                    name: "LLIHUE",
                                    code: "OEU"
                                },
                                {
                                    name: "IGNAO",
                                    code: "ZIG"
                                },
                                {
                                    name: "RININAHUE",
                                    code: "ZZR"
                                },
                                {
                                    name: "VIVANCO",
                                    code: "ZVV"
                                },
                                {
                                    name: "ILIHUE",
                                    code: "ZEQ"
                                }
                            ]
                        },
                        {
                            name: "LANCO",
                            code: 14103,
                            districts: [
                                {
                                    name: "LANCO",
                                    code: "LNC"
                                },
                                {
                                    name: "LA CAPILLA - VALDIV",
                                    code: "ZLW"
                                },
                                {
                                    name: "SALTO DEL AGUA",
                                    code: "ZSA"
                                },
                                {
                                    name: "PURULON",
                                    code: "ZPO"
                                },
                                {
                                    name: "LA LEONERA - VALDIVI",
                                    code: "LRA"
                                },
                                {
                                    name: "MALALHUE",
                                    code: "MAL"
                                }
                            ]
                        },
                        {
                            name: "LOS LAGOS",
                            code: 14104,
                            districts: [
                                {
                                    name: "ANTILHUE",
                                    code: "ANH"
                                },
                                {
                                    name: "RUNCA",
                                    code: "ZRU"
                                },
                                {
                                    name: "PUCARA",
                                    code: "ZPX"
                                },
                                {
                                    name: "HUITE",
                                    code: "ZHI"
                                },
                                {
                                    name: "FOLILCO",
                                    code: "ZFO"
                                },
                                {
                                    name: "RINIHUE",
                                    code: "XGR"
                                },
                                {
                                    name: "PUCONO",
                                    code: "PUC"
                                },
                                {
                                    name: "LIPINGUE",
                                    code: "OSN"
                                },
                                {
                                    name: "LOS LAGOS",
                                    code: "LAG"
                                },
                                {
                                    name: "HUICHACO",
                                    code: "HIC"
                                },
                                {
                                    name: "COLEGUAL - VALDIVIA",
                                    code: "COA"
                                }
                            ]
                        },
                        {
                            name: "MAFIL",
                            code: 14105,
                            districts: [
                                {
                                    name: "FUNDO ALTUE",
                                    code: "FAE"
                                },
                                {
                                    name: "PUERTO PAICO",
                                    code: "ZAE"
                                },
                                {
                                    name: "CHANCOYAN",
                                    code: "ZAX"
                                },
                                {
                                    name: "MAFIL",
                                    code: "MFL"
                                }
                            ]
                        },
                        {
                            name: "MARIQUINA",
                            code: 14106,
                            districts: [
                                {
                                    name: "PICHOY",
                                    code: "CHY"
                                },
                                {
                                    name: "QUEULE - VALDIVIA",
                                    code: "ZQU"
                                },
                                {
                                    name: "PURINGUE",
                                    code: "ZPG"
                                },
                                {
                                    name: "FUERTE SAN LUIS",
                                    code: "ZFF"
                                },
                                {
                                    name: "CIRUELOS - VALDIVIA",
                                    code: "ZCI"
                                },
                                {
                                    name: "MEHUIN",
                                    code: "MEH"
                                },
                                {
                                    name: "S.J. DE LA MARIQUINA",
                                    code: "MQA"
                                }
                            ]
                        },
                        {
                            name: "PAILLACO",
                            code: 14107,
                            districts: [
                                {
                                    name: "MANAO - VALDIVIA",
                                    code: "MMO"
                                },
                                {
                                    name: "LA PENA",
                                    code: "ZLP"
                                },
                                {
                                    name: "PICHIRROPULLI",
                                    code: "ZPI"
                                },
                                {
                                    name: "LOS ULMOS",
                                    code: "ZLX"
                                },
                                {
                                    name: "REUMEN",
                                    code: "RMN"
                                },
                                {
                                    name: "TRAITRACO",
                                    code: "TRR"
                                },
                                {
                                    name: "PAILLACO",
                                    code: "PAI"
                                },
                                {
                                    name: "LOS CONALES - OSORNO",
                                    code: "OSE"
                                }
                            ]
                        },
                        {
                            name: "PANGUIPULLI",
                            code: 14108,
                            districts: [
                                {
                                    name: "CHOSHUENCO",
                                    code: "CHS"
                                },
                                {
                                    name: "LICAN RAY",
                                    code: "LCY"
                                },
                                {
                                    name: "CARIRRINGUE",
                                    code: "ZZQ"
                                },
                                {
                                    name: "LIQUINE",
                                    code: "ZVC"
                                },
                                {
                                    name: "PUERTO PIRIHUEICO",
                                    code: "ZPT"
                                },
                                {
                                    name: "ENCO",
                                    code: "ZNC"
                                },
                                {
                                    name: "LOS TALLOS",
                                    code: "ZLS"
                                },
                                {
                                    name: "PUERTO FUY",
                                    code: "ZFQ"
                                },
                                {
                                    name: "CALAFQUEN",
                                    code: "ZCZ"
                                },
                                {
                                    name: "TERMAS DE CONARIPE",
                                    code: "TRC"
                                },
                                {
                                    name: "PANGUIPULLI",
                                    code: "PGP"
                                },
                                {
                                    name: "PULLINGUE",
                                    code: "PGE"
                                },
                                {
                                    name: "NELTUME",
                                    code: "NTM"
                                },
                                {
                                    name: "NANCUL",
                                    code: "NCU"
                                },
                                {
                                    name: "CONARIPE",
                                    code: "CPE"
                                }
                            ]
                        },
                        {
                            name: "RIO BUENO",
                            code: 14204,
                            districts: [
                                {
                                    name: "LOS CHILCOS",
                                    code: "LCL"
                                },
                                {
                                    name: "RIO BUENO",
                                    code: "RBN"
                                },
                                {
                                    name: "PUERTO NUEVO",
                                    code: "ZXN"
                                },
                                {
                                    name: "SANTA ROSA - OSORNO",
                                    code: "ZSR"
                                },
                                {
                                    name: "CHANCHAN",
                                    code: "ZNX"
                                },
                                {
                                    name: "CAYURRUCA",
                                    code: "ZCY"
                                }
                            ]
                        },
                        {
                            name: "VALDIVIA",
                            code: 14101,
                            districts: [
                                {
                                    name: "CURINANCO",
                                    code: "CNA"
                                },
                                {
                                    name: "PUNUCAPA",
                                    code: "NUC"
                                },
                                {
                                    name: "LOS MOLINOS",
                                    code: "LML"
                                },
                                {
                                    name: "HUEYELHUE",
                                    code: "ZYH"
                                },
                                {
                                    name: "NIEBLA",
                                    code: "NBL"
                                },
                                {
                                    name: "TRALCAO",
                                    code: "ZAP"
                                },
                                {
                                    name: "VALDIVIA",
                                    code: "ZAL"
                                },
                                {
                                    name: "LOS PELLINES - VALDI",
                                    code: "LPS"
                                }
                            ]
                        }
                    ]
                },
                {
                    name: "Los Lagos",
                    code: 10,
                    cities: [
                        {
                            name: "ANCUD",
                            code: 10202,
                            districts: [
                                {
                                    name: "ANCUD",
                                    code: "ACD"
                                },
                                {
                                    name: "CHACAO",
                                    code: "CAO"
                                },
                                {
                                    name: "CAULIN",
                                    code: "CHG"
                                },
                                {
                                    name: "QUETELMAHUE",
                                    code: "PQT"
                                },
                                {
                                    name: "GUALBUN",
                                    code: "GBN"
                                }
                            ]
                        },
                        {
                            name: "CALBUCO",
                            code: 10102,
                            districts: [
                                {
                                    name: "CALBUCO",
                                    code: "CBU"
                                },
                                {
                                    name: "HUELMO",
                                    code: "PXY"
                                }
                            ]
                        },
                        {
                            name: "CASTRO",
                            code: 10201,
                            districts: [
                                {
                                    name: "CURAHUE",
                                    code: "CGE"
                                },
                                {
                                    name: "ABTAO",
                                    code: "PAB"
                                },
                                {
                                    name: "CASTRO",
                                    code: "CTR"
                                }
                            ]
                        },
                        {
                            name: "CHAITEN",
                            code: 10401,
                            districts: [
                                {
                                    name: "CALETA SANTA BARBARA",
                                    code: "PBB"
                                },
                                {
                                    name: "CHAITEN",
                                    code: "ZCN"
                                },
                                {
                                    name: "BUILL",
                                    code: "PBX"
                                },
                                {
                                    name: "CALETA GONZALO",
                                    code: "PCG"
                                },
                                {
                                    name: "TERMAS EL AMARILLO",
                                    code: "PTM"
                                },
                                {
                                    name: "PUERTO CARDENAS",
                                    code: "TCD"
                                }
                            ]
                        },
                        {
                            name: "CHONCHI",
                            code: 10203,
                            districts: [
                                {
                                    name: "CUCAO",
                                    code: "CUO"
                                },
                                {
                                    name: "CHADMO CENTRAL",
                                    code: "PDN"
                                },
                                {
                                    name: "CHONCHI",
                                    code: "ZCH"
                                },
                                {
                                    name: "TEUPA",
                                    code: "PTU"
                                }
                            ]
                        },
                        {
                            name: "COCHAMO",
                            code: 10103,
                            districts: [
                                {
                                    name: "COCHAMO",
                                    code: "CMO"
                                },
                                {
                                    name: "ROLLIZO",
                                    code: "PWZ"
                                },
                                {
                                    name: "CANUTILLAR",
                                    code: "PWC"
                                },
                                {
                                    name: "EL BARRACO",
                                    code: "PWA"
                                },
                                {
                                    name: "RALUN",
                                    code: "PRA"
                                },
                                {
                                    name: "PUELO",
                                    code: "PPL"
                                },
                                {
                                    name: "BANOS DE SOTOMO",
                                    code: "PBS"
                                }
                            ]
                        },
                        {
                            name: "CURACO DE VELEZ",
                            code: 10204,
                            districts: [
                                {
                                    name: "CURACO DE VELEZ",
                                    code: "CVL"
                                },
                                {
                                    name: "QUINCHAO",
                                    code: "QCH"
                                }
                            ]
                        },
                        {
                            name: "DALCAHUE",
                            code: 10205,
                            districts: [
                                {
                                    name: "DALCAHUE",
                                    code: "DLE"
                                },
                                {
                                    name: "MECHUQUE",
                                    code: "PME"
                                },
                                {
                                    name: "ALTO BUTALCURA",
                                    code: "PAT"
                                },
                                {
                                    name: "SAN JUAN",
                                    code: "JJN"
                                },
                                {
                                    name: "QUICAVI",
                                    code: "QIA"
                                }
                            ]
                        },
                        {
                            name: "FRESIA",
                            code: 10104,
                            districts: [
                                {
                                    name: "FRESIA",
                                    code: "FSA"
                                },
                                {
                                    name: "MAICHIHUE",
                                    code: "PMA"
                                },
                                {
                                    name: "PARGA",
                                    code: "PPD"
                                }
                            ]
                        },
                        {
                            name: "FRUTILLAR",
                            code: 10105,
                            districts: [
                                {
                                    name: "FRUTILLAR",
                                    code: "FRT"
                                },
                                {
                                    name: "PARAGUAY",
                                    code: "ZPY"
                                },
                                {
                                    name: "TEGUALDA",
                                    code: "ZTG"
                                }
                            ]
                        },
                        {
                            name: "FUTALEUFU",
                            code: 10402,
                            districts: [
                                {
                                    name: "FUTALEUFU",
                                    code: "FTF"
                                },
                                {
                                    name: "LAGO YELCHO",
                                    code: "PLG"
                                },
                                {
                                    name: "PUERTO RAMIREZ",
                                    code: "PRZ"
                                },
                                {
                                    name: "VILLA SANTA LUCIA",
                                    code: "PVI"
                                },
                                {
                                    name: "PUERTO PIEDRA",
                                    code: "PIE"
                                }
                            ]
                        },
                        {
                            name: "HUALAIHUE",
                            code: 10403,
                            districts: [
                                {
                                    name: "AULEN",
                                    code: "ALN"
                                },
                                {
                                    name: "TERMAS DE LLANCATUE",
                                    code: "TLE"
                                },
                                {
                                    name: "POYO",
                                    code: "PWY"
                                },
                                {
                                    name: "CALETA HUALAIHUE",
                                    code: "PWX"
                                },
                                {
                                    name: "CHOLGO",
                                    code: "PWS"
                                },
                                {
                                    name: "CHAPARANO",
                                    code: "PWQ"
                                },
                                {
                                    name: "SEGUNDO CORRAL",
                                    code: "PSE"
                                },
                                {
                                    name: "PRIMER CORRAL",
                                    code: "PPR"
                                },
                                {
                                    name: "PICHANCO",
                                    code: "PPI"
                                },
                                {
                                    name: "HUINAY",
                                    code: "PHN"
                                },
                                {
                                    name: "LLANADA GRANDE",
                                    code: "PGX"
                                },
                                {
                                    name: "CONTAO",
                                    code: "PCM"
                                },
                                {
                                    name: "AYACARA",
                                    code: "PAY"
                                },
                                {
                                    name: "HORNOPIREN",
                                    code: "HRP"
                                },
                                {
                                    name: "HUALAIHUE",
                                    code: "HLH"
                                }
                            ]
                        },
                        {
                            name: "LLANQUIHUE",
                            code: 10107,
                            districts: [
                                {
                                    name: "LLANQUIHUE",
                                    code: "LLQ"
                                },
                                {
                                    name: "LONCOTORO",
                                    code: "LNT"
                                },
                                {
                                    name: "LOS PELLINES - PUERT",
                                    code: "PWT"
                                },
                                {
                                    name: "COLEGUAL - PUERTO MO",
                                    code: "PCL"
                                }
                            ]
                        },
                        {
                            name: "LOS MUERMOS",
                            code: 10106,
                            districts: [
                                {
                                    name: "LOLCURA",
                                    code: "LLA"
                                },
                                {
                                    name: "RIO FRIO",
                                    code: "PWF"
                                },
                                {
                                    name: "LAS QUEMAS",
                                    code: "PLS"
                                },
                                {
                                    name: "LOS MUERMOS",
                                    code: "LMU"
                                }
                            ]
                        },
                        {
                            name: "MAULLIN",
                            code: 10108,
                            districts: [
                                {
                                    name: "CARELMAPU",
                                    code: "CRU"
                                },
                                {
                                    name: "MISQUIHUE",
                                    code: "MSQ"
                                },
                                {
                                    name: "MAULLIN",
                                    code: "MAU"
                                },
                                {
                                    name: "PUELPUN",
                                    code: "PPP"
                                },
                                {
                                    name: "PARGUA",
                                    code: "ZGA"
                                },
                                {
                                    name: "PANGAL - PUERTO MONT",
                                    code: "PPA"
                                }
                            ]
                        },
                        {
                            name: "OSORNO",
                            code: 10301,
                            districts: [
                                {
                                    name: "CANCURA",
                                    code: "OAR"
                                },
                                {
                                    name: "PICHI DAMAS",
                                    code: "OSD"
                                },
                                {
                                    name: "MONTE VERDE",
                                    code: "ZMV"
                                },
                                {
                                    name: "REMEHUE",
                                    code: "ZRM"
                                },
                                {
                                    name: "OSORNO",
                                    code: "ZOS"
                                },
                                {
                                    name: "LAS LUMAS",
                                    code: "OSM"
                                }
                            ]
                        },
                        {
                            name: "PALENA",
                            code: 10404,
                            districts: [
                                {
                                    name: "PALENA",
                                    code: "PLE"
                                },
                                {
                                    name: "VILLA VANGUARDIA",
                                    code: "PVL"
                                }
                            ]
                        },
                        {
                            name: "PUERTO MONTT",
                            code: 10101,
                            districts: [
                                {
                                    name: "ALERCE",
                                    code: "ALE"
                                },
                                {
                                    name: "RANCHO PUERTO MONTT",
                                    code: "RPM"
                                },
                                {
                                    name: "CHAMIZA",
                                    code: "PZH"
                                },
                                {
                                    name: "QUILLAIPE",
                                    code: "PTE"
                                },
                                {
                                    name: "PUERTO MONTT",
                                    code: "PMC"
                                },
                                {
                                    name: "LENCA",
                                    code: "PLW"
                                },
                                {
                                    name: "CORRENTOSO",
                                    code: "PCT"
                                },
                                {
                                    name: "CALETA LA ARENA",
                                    code: "PCA"
                                },
                                {
                                    name: "EL TEPUAL",
                                    code: "ETL"
                                }
                            ]
                        },
                        {
                            name: "PUERTO OCTAY",
                            code: 10302,
                            districts: [
                                {
                                    name: "LAS CASCADAS",
                                    code: "LDS"
                                },
                                {
                                    name: "PIEDRAS NEGRAS",
                                    code: "PNG"
                                },
                                {
                                    name: "REFUGIO LA PICADA",
                                    code: "ZRD"
                                },
                                {
                                    name: "PUERTO CLOCKER",
                                    code: "ZPK"
                                },
                                {
                                    name: "LOS BAJOS",
                                    code: "ZEP"
                                },
                                {
                                    name: "PUERTO FONCK",
                                    code: "XPF"
                                },
                                {
                                    name: "CENTRAL RUPANCO",
                                    code: "OOC"
                                },
                                {
                                    name: "PUERTO OCTAY",
                                    code: "PCY"
                                }
                            ]
                        },
                        {
                            name: "PUERTO VARAS",
                            code: 10109,
                            districts: [
                                {
                                    name: "ENSENADA",
                                    code: "ENS"
                                },
                                {
                                    name: "NUEVA BRAUNAU",
                                    code: "NBR"
                                },
                                {
                                    name: "PUERTO VARAS",
                                    code: "ZPV"
                                },
                                {
                                    name: "LA POSA",
                                    code: "PWP"
                                },
                                {
                                    name: "RIO SUR",
                                    code: "RIS"
                                },
                                {
                                    name: "PETROHUE",
                                    code: "PTH"
                                },
                                {
                                    name: "LOS RISCOS",
                                    code: "OSC"
                                }
                            ]
                        },
                        {
                            name: "PUQUELDON",
                            code: 10206,
                            districts: [
                                {
                                    name: "ALDACHILDO",
                                    code: "ADO"
                                },
                                {
                                    name: "PUQUELDON",
                                    code: "PQE"
                                }
                            ]
                        },
                        {
                            name: "PURRANQUE",
                            code: 10303,
                            districts: [
                                {
                                    name: "CASMA",
                                    code: "OAM"
                                },
                                {
                                    name: "CONCORDIA",
                                    code: "ZIX"
                                },
                                {
                                    name: "HUEYUSCA",
                                    code: "ZHY"
                                },
                                {
                                    name: "LOS CORRALES",
                                    code: "ZEU"
                                },
                                {
                                    name: "PURRANQUE",
                                    code: "PRE"
                                },
                                {
                                    name: "CRUCERO",
                                    code: "OOR"
                                },
                                {
                                    name: "CORTE ALTO",
                                    code: "OOT"
                                }
                            ]
                        },
                        {
                            name: "PUYEHUE",
                            code: 10304,
                            districts: [
                                {
                                    name: "ENTRE LAGOS",
                                    code: "ENL"
                                },
                                {
                                    name: "AGUAS CALIENTES - OS",
                                    code: "ZAA"
                                },
                                {
                                    name: "TERMAS DE PUYEHUE",
                                    code: "ZTH"
                                },
                                {
                                    name: "REFUGIO ANTILLANCA",
                                    code: "ZRN"
                                },
                                {
                                    name: "PAJARITOS",
                                    code: "ZPJ"
                                },
                                {
                                    name: "NILQUE",
                                    code: "ZLY"
                                },
                                {
                                    name: "EL ENCANTO ",
                                    code: "ZEE"
                                },
                                {
                                    name: "EL ISLOTE",
                                    code: "ZEO"
                                },
                                {
                                    name: "ANTICURA",
                                    code: "ZAC"
                                },
                                {
                                    name: "PUYEHUE",
                                    code: "PYH"
                                },
                                {
                                    name: "PUERTO RICO",
                                    code: "OOI"
                                }
                            ]
                        },
                        {
                            name: "QUEILEN",
                            code: 10207,
                            districts: [
                                {
                                    name: "AHONI",
                                    code: "AHI"
                                },
                                {
                                    name: "PAILDAD",
                                    code: "PPU"
                                },
                                {
                                    name: "CONTAY",
                                    code: "PCN"
                                },
                                {
                                    name: "QUEILEN",
                                    code: "QLE"
                                },
                                {
                                    name: "AITUI",
                                    code: "PWU"
                                }
                            ]
                        },
                        {
                            name: "QUELLON",
                            code: 10208,
                            districts: [
                                {
                                    name: "HUILDAD",
                                    code: "PHI"
                                },
                                {
                                    name: "QUELLON",
                                    code: "QLN"
                                },
                                {
                                    name: "RANCHO QUELLON",
                                    code: "RQL"
                                },
                                {
                                    name: "QUELLON VIEJO",
                                    code: "QUV"
                                },
                                {
                                    name: "YALDAD",
                                    code: "PYA"
                                },
                                {
                                    name: "TRINCAO - CASTRO",
                                    code: "PWD"
                                },
                                {
                                    name: "COINCO - CASTRO",
                                    code: "PWI"
                                }
                            ]
                        },
                        {
                            name: "QUEMCHI",
                            code: 10209,
                            districts: [
                                {
                                    name: "HUILLINCO",
                                    code: "HNO"
                                },
                                {
                                    name: "QUEMCHI",
                                    code: "QUE"
                                },
                                {
                                    name: "AGUAS  BUENAS",
                                    code: "PZG"
                                },
                                {
                                    name: "BELBEN",
                                    code: "PZB"
                                },
                                {
                                    name: "LINAO",
                                    code: "PLI"
                                },
                                {
                                    name: "LLIUCO",
                                    code: "PLU"
                                },
                                {
                                    name: "MANAO - CASTRO",
                                    code: "PMN"
                                },
                                {
                                    name: "DEGAN",
                                    code: "PDG"
                                },
                                {
                                    name: "AUCAR",
                                    code: "PAU"
                                }
                            ]
                        },
                        {
                            name: "QUINCHAO",
                            code: 10210,
                            districts: [
                                {
                                    name: "APIAO",
                                    code: "APO"
                                },
                                {
                                    name: "ACHAO",
                                    code: "ZAO"
                                },
                                {
                                    name: "CHAULINEC",
                                    code: "PHC"
                                }
                            ]
                        },
                        {
                            name: "RIO NEGRO",
                            code: 10305,
                            districts: [
                                {
                                    name: "MILLANTUE",
                                    code: "OET"
                                },
                                {
                                    name: "CHAHUILCO",
                                    code: "OOL"
                                },
                                {
                                    name: "HUILMA",
                                    code: "ZHM"
                                },
                                {
                                    name: "EL BOLSON",
                                    code: "ZEB"
                                },
                                {
                                    name: "RIO NEGRO",
                                    code: "RNC"
                                }
                            ]
                        },
                        {
                            name: "SAN JUAN DE LA COSTA",
                            code: 10306,
                            districts: [
                                {
                                    name: "PUCATRIHUE",
                                    code: "OEH"
                                },
                                {
                                    name: "PUAUCHO",
                                    code: "OOH"
                                },
                                {
                                    name: "S.JUAN DE LA COSTA",
                                    code: "SJD"
                                },
                                {
                                    name: "MAICOLPUE",
                                    code: "ZWQ"
                                },
                                {
                                    name: "BAHIA MANSA",
                                    code: "ZBH"
                                },
                                {
                                    name: "CONTACO",
                                    code: "OOA"
                                }
                            ]
                        },
                        {
                            name: "SAN PABLO",
                            code: 10307,
                            districts: [
                                {
                                    name: "CHIRRE",
                                    code: "OER"
                                },
                                {
                                    name: "CARACOL",
                                    code: "OLO"
                                },
                                {
                                    name: "FILUCO",
                                    code: "ZFI"
                                },
                                {
                                    name: "PURRAPEL",
                                    code: "OLE"
                                },
                                {
                                    name: "SAN PABLO - OSORNO",
                                    code: "SPL"
                                }
                            ]
                        }
                    ]
                },
                {
                    name: "Aysén",
                    code: 11,
                    cities: [
                        {
                            name: "AYSEN",
                            code: 11201,
                            districts: [
                                {
                                    name: "PUERTO AGUIRRE",
                                    code: "AGR"
                                },
                                {
                                    name: "MANIHUALES",
                                    code: "GMH"
                                },
                                {
                                    name: "MINA EL TOQUI",
                                    code: "GMT"
                                },
                                {
                                    name: "PUERTO AYSEN",
                                    code: "WPA"
                                },
                                {
                                    name: "PUERTO CHACABUCO",
                                    code: "PCC"
                                },
                                {
                                    name: "SANTA MARIA DEL MAR",
                                    code: "GSM"
                                },
                                {
                                    name: "EL GATO",
                                    code: "IAU"
                                },
                                {
                                    name: "VILLA MANIHUALES",
                                    code: "MHS"
                                },
                                {
                                    name: "PUERTO GAVIOTA",
                                    code: "GVH"
                                }
                            ]
                        },
                        {
                            name: "CHILE CHICO",
                            code: 11401,
                            districts: [
                                {
                                    name: "CHILE CHICO",
                                    code: "CCH"
                                },
                                {
                                    name: "PUERTO FACHINAL",
                                    code: "GPF"
                                },
                                {
                                    name: "PUERTO MURTA",
                                    code: "GPX"
                                },
                                {
                                    name: "PUERTO SANCHEZ",
                                    code: "GPZ"
                                },
                                {
                                    name: "PUERTO TRANQUILO",
                                    code: "TGL"
                                },
                                {
                                    name: "PUERTO GUADAL",
                                    code: "PGU"
                                },
                                {
                                    name: "PUERTO BERTRAND",
                                    code: "PBI"
                                }
                            ]
                        },
                        {
                            name: "CISNES",
                            code: 11202,
                            districts: [
                                {
                                    name: "CISNES",
                                    code: "CNS"
                                },
                                {
                                    name: "LA TAPERA",
                                    code: "GLT"
                                },
                                {
                                    name: "PUERTO CISNES",
                                    code: "PCI"
                                },
                                {
                                    name: "TERMAS DE PUYUHUAPI",
                                    code: "GTP"
                                },
                                {
                                    name: "VILLA AMENGUAL",
                                    code: "VGL"
                                },
                                {
                                    name: "PUYUHUAPI",
                                    code: "YUH"
                                },
                                {
                                    name: "RIO CISNES",
                                    code: "GRC"
                                }
                            ]
                        },
                        {
                            name: "COCHRANE",
                            code: 11301,
                            districts: [
                                {
                                    name: "LAGO COCHRANE",
                                    code: "ANE"
                                },
                                {
                                    name: "PUERTO HERRADURA",
                                    code: "GXP"
                                },
                                {
                                    name: "COCHRANE",
                                    code: "CCL"
                                },
                                {
                                    name: "VILLA CHACABUCO",
                                    code: "GVC"
                                }
                            ]
                        },
                        {
                            name: "COYHAIQUE",
                            code: 11101,
                            districts: [
                                {
                                    name: "BALMACEDA",
                                    code: "BBA"
                                },
                                {
                                    name: "COYHAIQUE ALTO",
                                    code: "GAX"
                                },
                                {
                                    name: "BANO NUEVO",
                                    code: "BNO"
                                },
                                {
                                    name: "COYHAIQUE",
                                    code: "GXQ"
                                },
                                {
                                    name: "VILLA ORTEGA",
                                    code: "GVZ"
                                },
                                {
                                    name: "NIREGUAO",
                                    code: "GNG"
                                },
                                {
                                    name: "LAGUNA SAN RAFAEL",
                                    code: "GLS"
                                }
                            ]
                        },
                        {
                            name: "GUAITECAS",
                            code: 11203,
                            districts: [
                                {
                                    name: "GUAITECAS",
                                    code: "GCA"
                                },
                                {
                                    name: "ISLA ANGAMOS",
                                    code: "GIA"
                                },
                                {
                                    name: "ISLA IPUN",
                                    code: "GII"
                                },
                                {
                                    name: "ISLA MELCHOR",
                                    code: "GIM"
                                },
                                {
                                    name: "ISLA CUPTANA",
                                    code: "GIP"
                                },
                                {
                                    name: "ISLA VICTORIA",
                                    code: "GIV"
                                },
                                {
                                    name: "ISLA IZAZO",
                                    code: "GIZ"
                                },
                                {
                                    name: "MELINKA",
                                    code: "NKA"
                                },
                                {
                                    name: "ISLA NALCAYEC",
                                    code: "GIN"
                                },
                                {
                                    name: "ISLA LEVEL",
                                    code: "GIL"
                                },
                                {
                                    name: "ISLA BENJAMIN",
                                    code: "GIB"
                                }
                            ]
                        },
                        {
                            name: "LAGO VERDE",
                            code: 11102,
                            districts: [
                                {
                                    name: "LA JUNTA - COYHAIQUE",
                                    code: "JUN"
                                },
                                {
                                    name: "LAGO VERDE",
                                    code: "LVE"
                                }
                            ]
                        },
                        {
                            name: "OHIGGINS",
                            code: 11302,
                            districts: [
                                {
                                    name: "O HIGGINS",
                                    code: "OHG"
                                },
                                {
                                    name: "VILLA OHIGGINS",
                                    code: "VOH"
                                }
                            ]
                        },
                        {
                            name: "RIO IBANEZ",
                            code: 11402,
                            districts: [
                                {
                                    name: "PUERTO ING.IBANEZ",
                                    code: "GIQ"
                                },
                                {
                                    name: "VILLA CERRO CASTILLO",
                                    code: "VCC"
                                },
                                {
                                    name: "LEVICAN",
                                    code: "GLV"
                                },
                                {
                                    name: "RIO IBANEZ",
                                    code: "RIB"
                                }
                            ]
                        },
                        {
                            name: "TORTEL",
                            code: 11303,
                            districts: [
                                {
                                    name: "ISLA CAMPANA",
                                    code: "GIC"
                                },
                                {
                                    name: "CALETA TORTEL",
                                    code: "TOR"
                                },
                                {
                                    name: "TORTEL",
                                    code: "TRT"
                                },
                                {
                                    name: "ISLA MERINO JARPA",
                                    code: "GMJ"
                                },
                                {
                                    name: "PUERTO YUNGAY",
                                    code: "GPY"
                                },
                                {
                                    name: "ISLA PATRICIO LYNCH",
                                    code: "GIY"
                                }
                            ]
                        }
                    ]
                },
                {
                    name: "Magallanes y la Antartica Chilena",
                    code: 12,
                    cities: [
                        {
                            name: "ANTARTICA",
                            code: 12202,
                            districts: [
                                {
                                    name: "ANTARTICA",
                                    code: "ATC"
                                }
                            ]
                        },
                        {
                            name: "CABO DE HORNOS",
                            code: 12201,
                            districts: [
                                {
                                    name: "CALETA EUGENIA",
                                    code: "CEU"
                                },
                                {
                                    name: "CABO DE HORNOS",
                                    code: "HOR"
                                },
                                {
                                    name: "PUERTO TORO",
                                    code: "PTO"
                                },
                                {
                                    name: "YENDEGAIA",
                                    code: "YEN"
                                },
                                {
                                    name: "PUERTO WILLIAMS",
                                    code: "PWL"
                                },
                                {
                                    name: "ISLA NAVARINO",
                                    code: "INV"
                                },
                                {
                                    name: "LAPATAIA",
                                    code: "LTA"
                                }
                            ]
                        },
                        {
                            name: "LAGUNA BLANCA",
                            code: 12102,
                            districts: [
                                {
                                    name: "LAGUNA BLANCA",
                                    code: "LBL"
                                }
                            ]
                        },
                        {
                            name: "NATALES",
                            code: 12401,
                            districts: [
                                {
                                    name: "CERRO CASTILLO ",
                                    code: "CEC"
                                },
                                {
                                    name: "LA JUNTA  ",
                                    code: "PLJ"
                                },
                                {
                                    name: "PUERTO NATALES",
                                    code: "PNT"
                                },
                                {
                                    name: "RUBENS",
                                    code: "RBS"
                                },
                                {
                                    name: "RIO TURBIO",
                                    code: "RTT"
                                },
                                {
                                    name: "PUERTO BORIES",
                                    code: "PPB"
                                }
                            ]
                        },
                        {
                            name: "PORVENIR",
                            code: 12301,
                            districts: [
                                {
                                    name: "ARMONIA",
                                    code: "ARM"
                                },
                                {
                                    name: "ISLA DAWSON",
                                    code: "IDW"
                                },
                                {
                                    name: "PORVENIR - PUNTA ARE",
                                    code: "ZPR"
                                },
                                {
                                    name: "SECCION RUSSFIN",
                                    code: "SRU"
                                },
                                {
                                    name: "PUERTO PERCY",
                                    code: "PZP"
                                },
                                {
                                    name: "PAMPA GUANACOS",
                                    code: "PPG"
                                }
                            ]
                        },
                        {
                            name: "PRIMAVERA",
                            code: 12302,
                            districts: [
                                {
                                    name: "CERRO SOMBRERO",
                                    code: "CSM"
                                },
                                {
                                    name: "ESTANCIA CHINA CR.",
                                    code: "ECH"
                                },
                                {
                                    name: "SAN SEBASTIAN",
                                    code: "KSB"
                                },
                                {
                                    name: "CULLEN",
                                    code: "QEN"
                                },
                                {
                                    name: "PRIMAVERA",
                                    code: "PRI"
                                },
                                {
                                    name: "ONAISIN",
                                    code: "ONA"
                                },
                                {
                                    name: "MANANTIALES",
                                    code: "MTS"
                                },
                                {
                                    name: "ESTANCIA LOS OLIVOS",
                                    code: "ELO"
                                }
                            ]
                        },
                        {
                            name: "PUNTA ARENAS",
                            code: 12101,
                            districts: [
                                {
                                    name: "CABEZA DE MAR",
                                    code: "CAB"
                                },
                                {
                                    name: "ESTANCIA SAN JUAN",
                                    code: "ESJ"
                                },
                                {
                                    name: "TERMINAL CABO NEGRO",
                                    code: "TCN"
                                },
                                {
                                    name: "RANCHO PUNTA ARENAS",
                                    code: "RPU"
                                },
                                {
                                    name: "PUNTA ARENAS",
                                    code: "PUQ"
                                },
                                {
                                    name: "MINA PECKET",
                                    code: "MPK"
                                },
                                {
                                    name: "FUERTE BULNES",
                                    code: "FBL"
                                }
                            ]
                        },
                        {
                            name: "RIO VERDE",
                            code: 12103,
                            districts: [
                                {
                                    name: "ENTRE VIENTOS",
                                    code: "EVT"
                                },
                                {
                                    name: "VILLA TEHUELCHES",
                                    code: "VTT"
                                },
                                {
                                    name: "RIO VERDE",
                                    code: "RVE"
                                },
                                {
                                    name: "RIO GRANDE",
                                    code: "RGG"
                                },
                                {
                                    name: "PUERTO ALTAMIRANO",
                                    code: "PPN"
                                },
                                {
                                    name: "MORRO CHICO",
                                    code: "MRC"
                                }
                            ]
                        },
                        {
                            name: "SAN GREGORIO",
                            code: 12104,
                            districts: [
                                {
                                    name: "GAIKE",
                                    code: "GKE"
                                },
                                {
                                    name: "GALLEGOS CHICOS",
                                    code: "PGL"
                                },
                                {
                                    name: "ESTANCIA SN GREGORIO",
                                    code: "PES"
                                },
                                {
                                    name: "SAN GREGORIO - PUNTA",
                                    code: "SGG"
                                },
                                {
                                    name: "TERMINAL SAN GREGORI",
                                    code: "PZA"
                                },
                                {
                                    name: "MONTE AYMOND",
                                    code: "MAY"
                                },
                                {
                                    name: "PUNTA DELGADA",
                                    code: "PDU"
                                }
                            ]
                        },
                        {
                            name: "TIMAUKEL",
                            code: 12303,
                            districts: [
                                {
                                    name: "CAMERON",
                                    code: "PCX"
                                },
                                {
                                    name: "PUERTO YARTAU",
                                    code: "PPY"
                                },
                                {
                                    name: "PUERTO ARTURO",
                                    code: "UOR"
                                },
                                {
                                    name: "TIMAUKEL",
                                    code: "PTI"
                                },
                                {
                                    name: "PUERTO CONDOR",
                                    code: "PPC"
                                }
                            ]
                        },
                        {
                            name: "TORRES DEL PAINE",
                            code: 12402,
                            districts: [
                                {
                                    name: "ESTANCIA VICTORINA",
                                    code: "ESV"
                                },
                                {
                                    name: "PEHOE",
                                    code: "PHE"
                                },
                                {
                                    name: "TORRES DEL PAINE",
                                    code: "TRP"
                                }
                            ]
                        }
                    ]
                }
            ]
        }
    ]
};
export default geoResponse;