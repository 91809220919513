import React from 'react';
import './../../css/bx.integration.css';
import './../../css/code.css';

const Welcome = () => (
  <div className="desktop col-9 col-sm-8">
    <code>
      <div className="code-container">
      <div dangerouslySetInnerHTML={{__html: '{'}} />
        <p className="whiteSpace-pre">"Title":       "Welcome to API Rest Bluexpress web site.",</p>
        <p className="whiteSpace-pre">"Description": "Here you can find docs that you will need to understand each integration</p>
        <p className="whiteSpace-pre">                point of Bluex B2B platform.",</p>
        <p className="whiteSpace-pre">"Overview":    "To the left you will find a list of integration-point. Click each one</p>
        <p className="whiteSpace-pre">               "to get more details., </p>
        <div dangerouslySetInnerHTML={{__html: '}'}} />
      </div>
    </code>
  </div>
);

export default Welcome;
