const hideDocs = () => {
    let elements = document.getElementsByClassName('bx-modal-windows-locker');

    for (let index = 0; index < elements.length; index++) {
        const element = elements[index];
        element.style.display = 'none';
    }
    elements = document.getElementsByClassName('doc-detail');
    for (let index = 0; index < elements.length; index++) {
        const element = elements[index];
        element.style.display = 'none';
    }

    elements = document.getElementsByClassName('doc-detail-container');
    for (let index = 0; index < elements.length; index++) {
        const element = elements[index];
        element.style.display = 'none';
    }
}


const addEvents = (action) => {
    let elements = document.getElementsByClassName('doc-detail-link');
    for (let index = 0; index < elements.length; index++) {
        const element = elements[index];
        element.addEventListener('click', (event) => {
            action = event.currentTarget.dataset.action;
            hideAllDocDetail();
            showDocDetail(action);
        });
    }
    elements = document.getElementsByClassName('btn-close-doc-details');
    for (let index = 0; index < elements.length; index++) {
        const element = elements[index];
        element.addEventListener('click', (event) => {
            hideDocs();
        });
    }

}
const hideAllDocDetail = () => {
    const actions = ['bx-geo', 'bx-pricing'];
    for (let index = 0; index < actions.length; index++) {
        hideDocDetail(actions[index]);
    }
}

const hideDocDetail = (action) => {
    elements = document.getElementsByClassName(`doc-detail-${action}`);
    for (let index = 0; index < elements.length; index++) {
        const element = elements[index];
        element.style.display = 'none';
    }
}

const showDocDetail = (action) => {
    let elements = document.getElementsByClassName('bx-modal-windows-locker');

    for (let index = 0; index < elements.length; index++) {
        const element = elements[index];
        element.style.display = '';
    }
    elements = document.getElementsByClassName('doc-detail');
    for (let index = 0; index < elements.length; index++) {
        const element = elements[index];
        element.style.display = '';
    }

    elements = document.getElementsByClassName('doc-detail-container');
    for (let index = 0; index < elements.length; index++) {
        const element = elements[index];
        element.style.display = '';
    }

    elements = document.getElementsByClassName('doc-detail-container');
    for (let index = 0; index < elements.length; index++) {
        const element = elements[index];
        element.style.display = '';
    }

    elements = document.getElementsByClassName(`doc-detail-${action}`);
    for (let index = 0; index < elements.length; index++) {
        const element = elements[index];
        element.style.display = '';
    }
}
let elements = document.getElementsByClassName('doc-detail-container');
for (let index = 0; index < elements.length; index++) {
    const element = elements[index];
    element.classList.remove('display-none');
}
elements = document.getElementsByClassName('bx-modal-windows-locker');
for (let index = 0; index < elements.length; index++) {
    const element = elements[index];
    element.classList.remove('display-none');
}

addEvents();
hideDocs();
hideAllDocDetail();